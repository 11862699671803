/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Label,
  FormGroup,
  Row,
  Input,
  Container,
  CardHeader,
  CardFooter,
  Button,
  Form, FormFeedback
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import axios from 'axios';
import SimpleHeader from "components/Headers/SimpleHeader.js";

export default function CreateTerminalParameter(props) {
  const [isLoading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  const [listAcquirer, setListAcquirer] = useState([])
  const [listTranstype, setListTranstype] = useState([]);
  const terminalPk = props.match.params.id;
  const [acquirer, setAcquirer] = useState("");
  const [acquirerError, setAcquirerError] = useState(null);
  const [transtype, setTranstype] = useState("");
  const [transtypeError, setTranstypeError] = useState(null);
  const [tid, setTid] = useState("");
  const [tidError, setTidError] = useState(null);
  const [mid, setMid] = useState("");
  const [midError, setMidError] = useState(null);
  const [stan, setStan] = useState("");
  const [stanError, setStanError] = useState(null);
  const [batchNo, setBatchNo] = useState("");
  const [batchNoError, setBatchNoError] = useState(null);
  const [invNo, setInvNo] = useState("");
  const [invNoError, setInvNoError] = useState(null);
  const [errorHostTypeDuplicate, setErrorHostTypeDuplicate] = useState(null);
  const [errorTidDuplicate, setErrorTidDuplicate] = useState(null);
  const [errorMidDuplicate, setErrorMidDuplicate] = useState(null);
  const [errorAcquirerDuplicate, setErrorAcquirerDuplicate] = useState(null);

  const validateForm = () => {
    let error = false;
    if (acquirer === "") {
      setAcquirerError("invalid");
      error = true;
    }
    if (transtype === "") {
      setTranstypeError("invalid");
      error = true;
    }
    if (tid === "") {
      setTidError("invalid");
      error = true;
    }
    if (mid === "") {
      setMidError("invalid");
      error = true;
    }
    if (stan === "") {
      setStanError("invalid");
      error = true;
    }
    if (batchNo === "") {
      setBatchNoError("invalid");
      error = true;
    }
    if (invNo === "") {
      setInvNoError("invalid");
      error = true;
    }
    return error;
  };

  let history = useHistory();

  useEffect(() => {
    getAcquirer();
    getTranstype();
  }, []);

  const getAcquirer = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/acquirers/list`, {
      method: 'GET', // or 'PUT'
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(),
    })
      .then(response => response.json())
      .then(data => {
        setListAcquirer(data.response_data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const getTranstype = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/transtypes`, {
      method: 'GET', // or 'PUT'
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(),
    })
      .then(response => response.json())
      .then(data => {
        setListTranstype(data.response_data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  function CreateTerminalParameter() {
    setLoading(true);
    let body = {
      terminal_pk: parseInt(terminalPk),
      acquirer_id: parseInt(acquirer),
      transtype_name: transtype,
      tid: tid,
      mid: mid,
      stan: parseInt(stan),
      batch_no: parseInt(batchNo),
      inv_no: parseInt(invNo),
      term_pars_status: 1
    }
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/param/save`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then(function (response) {
        if (response.data.errors) {
          showErrors(response.data.errors);
          return;
        }
        history.push("/admin/terminal");
      })
      .then(json => {
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const showErrors = (error) => {
    let errors = false;
    if (error.acquirer) {
      setAcquirerError("invalid");
      setErrorAcquirerDuplicate(error.acquirer);
      errors = true;
    }
    if (error.host_type) {
      setTranstypeError("invalid");
      setErrorHostTypeDuplicate(error.host_type);
      errors = true;
    }
    if (error.tid) {
      setTidError("invalid");
      setErrorTidDuplicate(error.tid);
      errors = true;
    }
    if (error.mid) {
      setMidError("invalid");
      setErrorMidDuplicate(error.mid);
      errors = true;
    }
    return errors;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      CreateTerminalParameter();
    }
  }
  return (
    <>
      <SimpleHeader name="Daftar Terminal Parameter" parentName="Admin" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardBody>
                <Form onSubmit={handleSubmit} >
                  <CardHeader>
                    <h3>Daftar Terminal Parameter</h3>
                  </CardHeader>
                  <CardBody>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="acquirer_host_type"
                      >
                        Acquirer
                      </Label>
                      <Input
                        id="exampleFormControlSelect3"
                        name="acquirer_id"
                        type="select"
                        value={acquirer}
                        invalid={acquirerError === "invalid"}
                        onChange={(e) => {
                          setAcquirer(e.target.value);
                          if (e.target.value !== "") {
                            setAcquirerError("");
                          }
                        }}
                      >
                        <option value={0}>Pilih Acquirer</option>
                        {
                          listAcquirer.map((acq, key) => {
                            return <option key={key} value={acq.acquirer_id}>{acq.acquirer_name}</option>
                          })
                        }
                      </Input>
                      <FormFeedback>
                        {
                          acquirer === "" ? "Acquirer tidak boleh kosong" : errorAcquirerDuplicate
                        }
                      </FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="acquirer_host_type"
                      >
                        Host Type
                      </Label>
                      <Input
                        id="exampleFormControlSelect3"
                        name="transtype_name"
                        type="select"
                        value={transtype}
                        invalid={transtypeError === "invalid"}
                        onChange={(e) => {
                          setTranstype(e.target.value);
                          if (e.target.value !== "") {
                            setTranstypeError("");
                          }
                        }}
                      >
                        <option value="0">Pilih Host Type</option>
                        {
                          listTranstype.map((transtype, key) => {
                            return <option key={key} value={transtype.transtype_name}>{transtype.transtype_name}</option>
                          })
                        }
                      </Input>
                      <FormFeedback>
                        {
                          transtype === "" ? "Host Type tidak boleh kosong" : errorHostTypeDuplicate
                        }
                      </FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="encryption_type"
                      >
                        TID
                      </Label>
                      <Input
                        maxLength={8}
                        type="text"
                        name="tid"
                        placeholder="Masukan TID"
                        value={tid}
                        invalid={tidError === "invalid"}
                        onChange={(e) => {
                          setTid(e.target.value);
                          if (e.target.value !== "") {
                            setTidError("");
                          }
                        }}
                      />
                      <FormFeedback>
                        {
                          tid === "" ? "TID tidak boleh kosong" : errorTidDuplicate
                        }
                      </FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        MID
                      </Label>
                      <Input
                        type="text"
                        name="mid"
                        maxLength={15}
                        placeholder="Masukan MID"
                        value={mid}
                        invalid={midError === "invalid"}
                        onChange={(e) => {
                          setMid(e.target.value);
                          if (e.target.value !== "") {
                            setMidError("");
                          }
                        }}
                      />
                      <FormFeedback>
                        {
                          mid === "" ? "MID tidak boleh kosong" : errorMidDuplicate
                        }
                      </FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        Stan
                      </Label>
                      <Input
                        type="text"
                        name="stan"
                        maxLength={6}
                        placeholder="Masukan Stan"
                        value={stan}
                        invalid={stanError === "invalid"}
                        onChange={(e) => {
                          setStan(e.target.value);
                          if (e.target.value !== "") {
                            setStanError("");
                          }
                        }}
                      />
                      <FormFeedback>Stan tidak boleh kosong</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        Batch No
                      </Label>
                      <Input
                        type="text"
                        name="batch_no"
                        maxLength={6}
                        placeholder="Masukan Batch No"
                        value={batchNo}
                        invalid={batchNoError === "invalid"}
                        onChange={(e) => {
                          setBatchNo(e.target.value);
                          if (e.target.value !== "") {
                            setBatchNoError("");
                          }
                        }}
                      />
                      <FormFeedback>Batch No tidak boleh kosong</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        Inv No
                      </Label>
                      <Input
                        type="text"
                        name="inv_no"
                        maxLength={6}
                        placeholder="Masukan Inv No"
                        value={invNo}
                        invalid={invNoError === "invalid"}
                        onChange={(e) => {
                          setInvNo(e.target.value);
                          if (e.target.value !== "") {
                            setInvNoError("");
                          }
                        }}
                      />
                      <FormFeedback>Inv No tidak boleh kosong</FormFeedback>
                    </FormGroup>
                  </CardBody>
                  <CardFooter>
                    {!isLoading && (<Button color="primary" type="submit">
                      Simpan
                    </Button>)}
                    {isLoading && (<Button color="primary" disabled>
                      <i className="fas fa-spinner fa-spin"></i>{""}
                      Loading...
                    </Button>)}
                    <Link className="btn btn-info" to="/admin/terminal">
                      Kembali
                    </Link>
                  </CardFooter>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
