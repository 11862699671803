/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Label,
  FormGroup,
  Row,
  Input,
  Container,
  CardHeader,
  CardFooter,
  Button,
  Form, FormFeedback
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import axios from 'axios';
import SimpleHeader from "components/Headers/SimpleHeader.js";

export default function CreateAcquirerMdr(props) {
  const token = localStorage.getItem("tooken");
  const id = props.match.params.id;
  const [hostTypes, setHostTypes] = useState([]);
  const [types, setTypes] = useState([]);
  let history = useHistory();
  const [isLoading, setLoading] = useState(false);

  const [hostType, setHostType] = useState("");
  const [hostTypeError, setHostTypeError] = useState(null);
  const [cardType, setCardType] = useState("");
  const [cardTypeError, setCardTypeError] = useState(null);
  const [MdrValue, setMdrValue] = useState("");
  const [MdrValueError, setMdrValueError] = useState(null);

  const validateForm = () => {
    let error = false;
    if (hostType === "") {
      setHostTypeError("invalid");
      error = true;
    }
    if (cardType === "") {
      setCardTypeError("invalid");
      error = true;
    }
    if (MdrValue === "") {
      setMdrValueError("invalid");
      error = true;
    }
    return error;
  };

  useEffect(() => {
    getHostType();
  }, []);

  const getHostType = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/transtypes`, {
      method: 'GET', // or 'PUT'
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(),
    })
      .then(response => response.json())
      .then(data => {
        setHostTypes(data.response_data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const getTypesList = (hostType) => {
    const data = { transtype_name: hostType }
    fetch(`${process.env.REACT_APP_API_BASE_URL}/transtypes/list`, {
      method: 'POST', // or 'PUT'
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        setTypes(data.response_data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  function CreateAcquirerMdr() {
    setLoading(true);
    let data = {
      acquirer_id: parseInt(id),
      transtype_id: parseInt(cardType),
      acq_transtype_mdr: parseFloat(MdrValue),
      acq_transtype_status: 1
    }
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/acqtranstype/save`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then(function (response) {
        history.push("/admin/acquirer");
      })
      .then(json => {
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      CreateAcquirerMdr();
    }
  }
  return (
    <>
      <SimpleHeader name="Daftar Acquirer Mdr" parentName="Admin" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardBody>
                <Form onSubmit={handleSubmit} >
                  <CardHeader>
                    <h3>Daftar Acquirer MDR</h3>
                  </CardHeader>
                  <CardBody>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="acquirer_host_type"
                      >
                        Host Type
                                            </Label>
                      <Input
                        id="exampleFormControlSelect3"
                        name="transaction_type"
                        type="select"
                        value={hostType}
                        invalid={hostTypeError === "invalid"}
                        onChange={(e) => {
                          setHostType(e.target.value);
                          if (e.target.value !== "") {
                            setHostTypeError("");
                          }
                          getTypesList(e.target.value);
                        }}
                      >
                        <option value="">Pilih Host Type</option>
                        {
                          hostTypes.map((hostType, key) => {
                            return <option key={key} value={hostType.transtype_name}>{hostType.transtype_name}</option>
                          })
                        }
                      </Input>
                      <FormFeedback>Host Type tidak boleh kosong</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="encryption_type"
                      >
                        Card Type
                                            </Label>
                      <Input
                        id="exampleFormControlSelect3"
                        name="transtype_id"
                        type="select"
                        value={cardType}
                        invalid={cardTypeError === "invalid"}
                        onChange={(e) => {
                          setCardType(e.target.value);
                          if (e.target.value !== "") {
                            setCardTypeError("");
                          }
                        }}
                      >
                        <option value="">Pilih Card Type</option>
                        {
                          types.map((type, key) => {
                            return <option key={key} value={type.transtype_id}>{type.transtype_clsf}</option>
                          })
                        }
                      </Input>
                      <FormFeedback>Card Type tidak boleh kosong</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        MDR Value
                                            </Label>
                      <Input
                        type="number"
                        name="acq_transtype_mdr"
                        placeholder="Masukan MDR Value"
                        value={MdrValue}
                        invalid={MdrValueError === "invalid"}
                        onChange={(e) => {
                          setMdrValue(e.target.value);
                          if (e.target.value !== "") {
                            setMdrValueError("");
                          }
                        }}
                      />
                      <FormFeedback>MDR Value tidak boleh kosong</FormFeedback>
                    </FormGroup>
                  </CardBody>
                  <CardFooter>
                    {!isLoading && (<Button color="primary" type="submit">
                      Simpan
                    </Button>)}
                    {isLoading && (<Button color="primary" disabled>
                      <i className="fas fa-spinner fa-spin"></i>{""}
                      loading...
                    </Button>)}
                    <Link className="btn btn-info" to="/admin/acquirer">
                      Kembali
                                        </Link>
                  </CardFooter>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
