/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Label,
  FormGroup,
  Row,
  Input,
  Container,
  CardHeader,
  CardFooter,
  Button,
  Form,
  Col,
  FormFeedback
} from "reactstrap";
import { Link } from "react-router-dom";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import axios from 'axios';
import { useHistory } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";

export default function MyProfil(props) {
  const token = localStorage.getItem("token");
  let history = useHistory();
  const [userId, setUserId] = useState(null);
  const [name, setName] = useState("");
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [jenisKelamin, setJenisKelamin] = useState(1);
  const [noTlp, setNoTelp] = useState("");
  const [alert, setAlert] = React.useState(null);
  const [isLoading, setLoading] = useState(false);

  const [nameState, setNameState] = useState(null);
  const [emailState, setEmailState] = useState(null);

  const validateForm = () => {
    let error = false;
    if (name === "") {
      setNameState("invalid");
      error = true;
    }
    if (email === "") {
      setEmailState("invalid");
      error = true;
    }
    return error;
  };

  useEffect(() => {
    getMyProfil();
  }, []);

  const getMyProfil = async () => {
    const token = localStorage.token;
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/info`, {
        method: 'GET', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(),
      })
      const data = await response.json();
      setName(data.name);
      setEmail(data.email);
      setUserName(data.username);
      setAddress(data.address);
      setJenisKelamin(data.gender);
      setNoTelp(data.phoneNumber);
      setUserId(data.userId);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const hideAlert = () => {
    setAlert(null);
  }

  const setSuccessAlert = () => {
    setAlert(
      <SweetAlert
        success
        showConfirm
        confirmBtnText="Ok"
        title="Profil berhasil disimpan"
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    )
  }

  function updateMyProfil() {
    setLoading(true);
    let body = {
      name: name,
      email: email,
      username: userName,
      address: address,
      gender: parseInt(jenisKelamin),
      phone_number: noTlp
    }
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/user/update/${userId}`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then(function (response) {
        setSuccessAlert();
        history.push("/admin/my-profil");
      })
      .then(json => {
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      updateMyProfil();
    }
  }

  return (
    <>
      {alert}
      <SimpleHeader name="Profil" parentName="Admin" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardBody>
                <Form onSubmit={handleSubmit}>
                  <CardHeader>
                    <h3>Profil</h3>
                  </CardHeader>
                  <CardBody>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="name"
                      >
                        Nama
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        name="name"
                        placeholder="Masukan Name"
                        value={name}
                        invalid={nameState === "invalid"}
                        onChange={(e) => {
                          setName(e.target.value);
                          if (e.target.value !== "") {
                            setNameState("");
                          }
                        }}
                      />
                      <FormFeedback>Nama tidak boleh kosong</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        Email
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        name="email"
                        placeholder="Masukan Email"
                        value={email}
                        invalid={emailState === "invalid"}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          if (e.target.value !== "") {
                            setEmailState("");
                          }
                        }}
                      />
                      <FormFeedback>Email tidak boleh kosong</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        User Name
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        disabled="true"
                        type="text"
                        name="user_name"
                        placeholder="Masukan User Name"
                        value={userName}
                        onChange={e => setUserName(e.target.value)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        Address
                      </Label>
                      <Input
                        type="text"
                        name="address"
                        placeholder="Masukan Address"
                        value={address}
                        onChange={e => setAddress(e.target.value)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlSelect3"
                      >
                        Jenis Kelamin
                                    </Label>
                      <Row>
                        <Col>
                          <div style={{ display: "flex" }}>
                            <div className="custom-control custom-radio mb-3">
                              <Input
                                className="custom-control-input"
                                defaultChecked
                                id="customRadio5"
                                name="custom-radio-2"
                                type="radio"
                                value={1}
                                checked={jenisKelamin === 1}
                                onClick={() => setJenisKelamin(1)}
                              />
                              <Label
                                className="custom-control-label"
                                htmlFor="customRadio5"
                              >
                                Pria
                                </Label>
                            </div>
                            <div className="custom-control custom-radio mb-3" style={{ marginLeft: "20px" }}>
                              <Input
                                className="custom-control-input"
                                id="customRadio6"
                                name="custom-radio-2"
                                type="radio"
                                value={2}
                                checked={jenisKelamin === 2}
                                onClick={() => setJenisKelamin(2)}
                              />
                              <Label
                                className="custom-control-label"
                                htmlFor="customRadio6"
                              >
                                Wanita
                                </Label>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        Nomor Telp/ Handphone
                      </Label>
                      <Input
                        type="text"
                        name="No Telphone"
                        placeholder="Masukan Nomor Telp"
                        value={noTlp}
                        onChange={e => setNoTelp(e.target.value)}
                      />
                    </FormGroup>
                  </CardBody>
                  <CardFooter>
                    {!isLoading && (<Button color="primary" type="submit">
                      Simpan
                    </Button>)}
                    {isLoading && (<Button color="primary" disabled>
                      <i className="fas fa-spinner fa-spin"></i>{""}
                      loading...
                    </Button>)}
                    <Link class="btn btn-info" to="/admin/dasboard">
                      Kembali
                    </Link>
                  </CardFooter>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
