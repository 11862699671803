/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Label,
  FormGroup,
  Row,
  Input,
  Container,
  CardHeader,
  CardFooter,
  Button,
  Form, FormFeedback
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import axios from 'axios';
import SimpleHeader from "components/Headers/SimpleHeader.js";

export default function EditAcquirer(props) {
  const token = localStorage.token;
  const [hostTypes, setHostTypes] = useState([]);
  const [types, setTypes] = useState([]);
  let history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [acquirerId, setAcquirerId] = useState("");
  const [hostType, setHostType] = useState("");
  const [hostTypeError, setHostTypeError] = useState(null);
  const [cardType, setCardType] = useState("");
  const [cardTypeError, setCardTypeError] = useState(null);
  const [encryptionType, setEncryptionType] = useState("");
  const [encyptionTypeError, setEncyptionTypeError] = useState(null);
  const [primaryIp, setPrimaryIp] = useState("");
  const [primaryIpError, setPrimaryIpError] = useState(null);
  const [secondaryIp, setSecondaryIp] = useState("");
  const [secondaryIpError, setSecondaryIpError] = useState(null);
  const [primaryPort, setPrimaryPort] = useState("");
  const [primartPortError, setPrimaryPortError] = useState(null);
  const [secondaryPort, setSecondaryPort] = useState("");
  const [secondaryPortError, setSecondaryPortError] = useState(null);
  const [primaryNii, setPrimaryNii] = useState("");
  const [primaryNiiError, setPrimaryNiiError] = useState(null);
  const [secondaryNii, setSecondaryNii] = useState("");
  const [secondaryNiiError, setSecondaryNiiError] = useState(null);

  const validateForm = () => {
    let error = false;
    if (hostType === "") {
      setHostTypeError("invalid");
      error = true;
    }
    if (cardType === "") {
      setCardTypeError("invalid");
      error = true;
    }
    if (encryptionType === "") {
      setEncyptionTypeError("invalid");
      error = true;
    }
    if (primaryIp === "") {
      setPrimaryIpError("invalid");
      error = true;
    }
    if (secondaryIp === "") {
      setSecondaryIpError("invalid");
      error = true;
    }
    if (primaryPort === "") {
      setPrimaryPortError("invalid");
      error = true;
    }
    if (secondaryPort === "") {
      setSecondaryPortError("invalid");
      error = true;
    }
    if (primaryNii === "") {
      setPrimaryNiiError("invalid");
      error = true;
    }
    if (secondaryNii === "") {
      setSecondaryNiiError("invalid");
      error = true;
    }
    return error;
  };

  useEffect(() => {
    getAcquirerById();
  }, []);

  const getAcquirerById = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/host/${props.match.params.id}`, {
      method: 'GET', // or 'PUT'
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(),
    })
      .then(response => response.json())
      .then(data => {
        const responseData = data.response_data;
        setAcquirerId(responseData.acquirer_id);
        setEncryptionType(responseData.encryption_type);
        setPrimaryIp(responseData.primary_ip);
        setSecondaryIp(responseData.secondary_ip);
        setPrimaryPort(responseData.primary_port);
        setSecondaryPort(responseData.secondary_port);
        setPrimaryNii(responseData.primary_nii);
        setSecondaryNii(responseData.secondary_nii);
        getTransTypeById(responseData.transtype_id);
        getHostTypeList();
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const getTransTypeById = async (transtype_id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/transtype/${transtype_id}`, {
        method: 'GET', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(),
      })
      let data = await response.json();
      setHostType(data.response_data.transtype_name);
      getTypesList(data.response_data.transtype_name, transtype_id);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const getHostTypeList = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/transtypes`, {
      method: 'GET', // or 'PUT'
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(),
    })
      .then(response => response.json())
      .then(data => {
        const responseData = data.response_data;
        setHostTypes(responseData);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const getTypesList = (hostType, transTypeId) => {
    const data = { transtype_name: hostType }
    fetch(`${process.env.REACT_APP_API_BASE_URL}/transtypes/list`, {
      method: 'POST', // or 'PUT'
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        setTypes(data.response_data);
        setCardType(transTypeId);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  function UpdateAcquirerHost() {
    setLoading(true);
    const data = {
      acquirer_id: parseInt(acquirerId),
      acquirer_host_type: hostType,
      transtype_id: parseInt(cardType),
      encryption_type: encryptionType,
      primary_ip: primaryIp,
      secondary_ip: secondaryIp,
      primary_port: parseInt(primaryPort),
      secondary_port: parseInt(secondaryPort),
      primary_nii: primaryNii,
      secondary_nii: secondaryNii
    }
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/host/update/${props.match.params.id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then(function (response) {
        history.push("/admin/acquirer");
      })
      .then(json => {
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      UpdateAcquirerHost();
    }
  }
  return (
    <>
      <SimpleHeader name="Edit Acquirer Host" parentName="Admin" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardBody>
                <Form onSubmit={handleSubmit} >
                  <CardHeader>
                    <h3>Edit Acquirer Host</h3>
                  </CardHeader>
                  <CardBody>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="acquirer_host_type"
                      >
                        Host Type
                      </Label>
                      <Input
                        id="exampleFormControlSelect3"
                        name="transaction_type"
                        type="select"
                        value={hostType}
                        invalid={hostTypeError === "invalid"}
                        onChange={(e) => {
                          setHostType(e.target.value);
                          if (e.target.value !== "") {
                            setHostTypeError("");
                          }
                          getTypesList(e.target.value);
                        }}
                      >
                        <option value="">Pilih Host Type</option>
                        {
                          hostTypes.map((hostType, key) => {
                            return <option key={key} value={hostType.transtype_name}>{hostType.transtype_name}</option>
                          })
                        }
                      </Input>
                      <FormFeedback>Host type tidak boleh kosong</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="encryption_type"
                      >
                        Card Type
                      </Label>
                      <Input
                        id="exampleFormControlSelect3"
                        name="transtype_id"
                        type="select"
                        value={cardType}
                        invalid={cardTypeError === "invalid"}
                        onChange={(e) => {
                          setCardType(e.target.value);
                          if (e.target.value !== "") {
                            setCardTypeError("");
                          }
                        }}
                      >
                        <option value="">Pilih Card Type</option>
                        {
                          types.map((type, key) => {
                            return <option key={key} value={type.transtype_id}>{type.transtype_clsf}</option>
                          })
                        }
                      </Input>
                      <FormFeedback>Card type tidak boleh kosong</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="encryption_type"
                      >
                        Encyption Type
                      </Label>
                      <Input
                        type="text"
                        name="encryption_type"
                        placeholder="Masukan Encyption Type"
                        value={encryptionType}
                        invalid={encyptionTypeError === "invalid"}
                        onChange={(e) => {
                          setEncryptionType(e.target.value);
                          if (e.target.value !== "") {
                            setEncyptionTypeError("");
                          }
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        IP Primary
                                            </Label>
                      <Input
                        type="text"
                        name="primary_ip"
                        placeholder="Masukan Primary IP"
                        value={primaryIp}
                        invalid={primaryIpError === "invalid"}
                        onChange={(e) => {
                          setPrimaryIp(e.target.value);
                          if (e.target.value !== "") {
                            setPrimaryIpError("");
                          }
                        }}
                      />
                      <FormFeedback>IP Primary tidak boleh kosong</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        IP Secondary
                                            </Label>
                      <Input
                        type="text"
                        name="secondary_ip"
                        placeholder="Masukan Secondary IP"
                        value={secondaryIp}
                        invalid={secondaryIpError === "invalid"}
                        onChange={(e) => {
                          setSecondaryIp(e.target.value);
                          if (e.target.value !== "") {
                            setSecondaryIpError("");
                          }
                        }}
                      />
                      <FormFeedback>IP Secondary tidak boleh kosong</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        Port Primary
                                            </Label>
                      <Input
                        type="text"
                        name="primary_port"
                        placeholder="Masukan Primary Port"
                        value={primaryPort}
                        invalid={primartPortError === "invalid"}
                        onChange={(e) => {
                          setPrimaryPort(e.target.value);
                          if (e.target.value !== "") {
                            setPrimaryPortError("");
                          }
                        }}
                      />
                      <FormFeedback>Port Primary tidak boleh kosong</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        Port Secondary
                                            </Label>
                      <Input
                        type="text"
                        name="secondary_port"
                        placeholder="Masukan Secondary Port"
                        value={secondaryPort}
                        invalid={secondaryPortError === "invalid"}
                        onChange={(e) => {
                          setSecondaryPort(e.target.value);
                          if (e.target.value !== "") {
                            setSecondaryPortError("");
                          }
                        }}
                      />
                      <FormFeedback>Port Secondary tidak boleh kosong</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        NII Primary
                                            </Label>
                      <Input
                        type="text"
                        name="primary_nii"
                        placeholder="Masukan Primary NII"
                        value={primaryNii}
                        invalid={primaryNiiError === "invalid"}
                        onChange={(e) => {
                          setPrimaryNii(e.target.value);
                          if (e.target.value !== "") {
                            setPrimaryNiiError("");
                          }
                        }}
                      />
                      <FormFeedback>NII Primary tidak boleh kosong</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        NII Secondary
                                            </Label>
                      <Input
                        type="text"
                        name="secondary_nii"
                        placeholder="Masukan Secondary NII"
                        value={secondaryNii}
                        invalid={secondaryNiiError === "invalid"}
                        onChange={(e) => {
                          setSecondaryNii(e.target.value);
                          if (e.target.value !== "") {
                            setSecondaryNiiError("");
                          }
                        }}
                      />
                      <FormFeedback>NII Secondary tidak boleh kosong</FormFeedback>
                    </FormGroup>
                  </CardBody>
                  <CardFooter>
                    {!isLoading && (<Button color="primary" type="submit">
                      Simpan
                    </Button>)}
                    {isLoading && (<Button color="primary" disabled>
                      <i className="fas fa-spinner fa-spin"></i>{""}
                      loading...
                    </Button>)}
                    <Link className="btn btn-info" to="/admin/acquirer">
                      Kembali
                    </Link>
                  </CardFooter>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
