/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Label,
  FormGroup,
  Row,
  Input,
  Container,
  CardHeader,
  CardFooter,
  Button,
  Form, FormFeedback
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import axios from 'axios';
import SimpleHeader from "components/Headers/SimpleHeader.js";

export default function EditUnitKerja(props) {
  const token = localStorage.getItem("token");
  const id = props.match.params.id;
  const [merchantPk, setMerchantPk] = useState("");
  const [unitKerja, setUnitKerja] = useState("");
  const [unitKerjaError, setUnitKerjaError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  let history = useHistory();

  const validateForm = () => {
    let error = false;
    if (unitKerja === "") {
      setUnitKerjaError("invalid");
      error = true;
    }
    return error;
  };

  useEffect(() => {
    getUnitKerjaById();
  }, []);

  const getUnitKerjaById = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/workunit/${id}`, {
        method: 'GET', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(),
      })
      const data = await response.json();
      setMerchantPk(data.response_data.merchantPk)
      setUnitKerja(data.response_data.workUnitName);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  function Update() {
    setLoading(true);
    let body = {
      merchantPk: parseInt(merchantPk),
      workUnitName: unitKerja
    }
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/workunit/update/${id}`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then(function (response) {
        history.push("/admin/customers/daftar-project");
      })
      .then(json => {
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      Update();
    }
  }
  return (
    <>
      <SimpleHeader name="Edit Unit Kerja" parentName="Admin" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardBody>
                <Form onSubmit={handleSubmit}>
                  <CardHeader>
                    <h3>Edit  Unit Kerja</h3>
                  </CardHeader>
                  <CardBody>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        Nama Unit Kerja
                      </Label>
                      <Input
                        type="text"
                        name="unit-kerja"
                        placeholder="Masukan Unit Kerja"
                        value={unitKerja}
                        invalid={unitKerjaError === "invalid"}
                        onChange={e => setUnitKerja(e.target.value)}
                      />
                      <FormFeedback>Unit Kerja tidak boleh kosong</FormFeedback>
                    </FormGroup>
                  </CardBody>
                  <CardFooter>
                    {!isLoading && (<Button color="primary" type="submit">
                      Simpan
                    </Button>)}
                    {isLoading && (<Button color="primary" disabled>
                      <i className="fas fa-spinner fa-spin"></i>{""}
                      Loading..
                    </Button>)}
                    <Link class="btn btn-info" to="/admin/customers/daftar-project">
                      Kembali
                    </Link>
                  </CardFooter>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
