/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
    Card,
    CardBody,
    Label,
    FormGroup,
    Row,
    Col,
    Input,
} from "reactstrap";

export default function DataPemilik() {
    const token = localStorage.token;
    const merchantPk = localStorage.getItem("merchantPk");
    const [nama, setNama] = useState("");
    const [tempatLahit, setTempatLahir] = useState("");
    const [tanggalLahir, setTanggalLahir] = useState("");
    const [telpon, setTelpon] = useState("");
    const [email, setEmail] = useState("");

    useEffect(() => {
        getProfileByMk();
    }, []);

    const getProfileByMk = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/merchant/profile/${merchantPk}`, {
                method: 'GET', // or 'PUT'
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(),
            })
            const data = await response.json();
            setNama(data.response_data.pic_name);
            setTempatLahir(data.response_data.pic_birth_place);
            setTanggalLahir(data.response_data.pic_dob);
            setTelpon(data.response_data.pic_phone);
            setEmail(data.response_data.pic_email);
        } catch (error) {
            console.error('Error:', error);
        }
    }
    return (
        <>
            <Card>
                <CardBody>
                    <FormGroup>
                        <Label
                            className="form-control-label"
                            htmlFor="exampleFormControlInput1"
                        >
                            Nama
                    </Label>
                        <Input
                            disabled="true"
                            type="text"
                            value={nama}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label
                            className="form-control-label"
                            htmlFor="exampleFormControlInput1"
                        >
                            Tempat & Tanggal Lahir
                    </Label>
                        <Row>
                            <Col md="6">
                                <Input
                                    disabled="true"
                                    type="text"
                                    value={tempatLahit}
                                />
                            </Col>
                            <Col md="6">
                                <Input
                                    disabled="true"
                                    type="text"
                                    value={tanggalLahir}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Label
                            className="form-control-label"
                            htmlFor="exampleFormControlInput1"
                        >
                            Nomor Telepon
                    </Label>
                        <Input
                            disabled="true"
                            type="text"
                            value={telpon}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label
                            className="form-control-label"
                            htmlFor="exampleFormControlInput1"
                        >
                            Email
                    </Label>
                        <Input
                            disabled="true"
                            type="text"
                            value={email}
                        />
                    </FormGroup>
                </CardBody>
            </Card>
        </>
    );
}
