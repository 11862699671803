/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Label,
  FormGroup,
  Row,
  Col,
  Input,
  Container,
  CardHeader,
  Button,
  Form,
  CardFooter, FormFeedback
} from "reactstrap";
import { useHistory } from "react-router-dom";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Select from 'react-select';
import axios from 'axios';
import moment from 'moment';

export default function Create() {
  const token = localStorage.getItem("token");
  const [isLoading, setLoading] = useState(false);
  let history = useHistory();
  const [bentukMerchant, setBentuMerchant] = useState(1);
  const [isSelectedStatus, setSelectedStatus] = useState(1);
  const [isSelectUnitKerja, setSelecUnitKerja] = useState(2);
  const [unitKerja, setUnitKerja] = useState("");
  const [unitKerjaError, setUnitKerjaError] = useState(null);
  const [listUnitKerja, setListUnitKerja] = useState([]);
  const [brand, setBrand] = useState("");
  const [brandError, setBrandError] = useState(null);
  const [namaBadanHukum, setNamaBadanHukum] = useState("");
  const [badanHukumError, setBadanHukumError] = useState(null);
  const [address, setAddress] = useState("");
  const [addressError, setAddressError] = useState(null);
  const [countries, setCountries] = useState([]);
  const [countryError, setCountryError] = useState(null);
  const [region, setRegion] = useState(null);
  const [regionError, setRegionError] = useState(null);
  const [regions, setRegions] = useState([]);
  const [regancy, setRegancy] = useState(null);
  const [regancyError, setRegancyError] = useState(null);
  const [regencies, setRegencies] = useState([]);
  const [district, setDistrict] = useState(null);
  const [districtError, setDistrictError] = useState(null);
  const [districts, setDistricts] = useState([]);
  const [kodePos, setKodePos] = useState("");
  const [kodePosError, setKodePosError] = useState(null);
  const [categories, setCategories] = useState([]);
  const [kategori, setKategori] = useState("");
  const [kategoriError, setKategoriError] = useState(null);
  const [dateSewa, setDataSewa] = useState("");
  const [dateSewaError, setDateSewaError] = useState(null);
  const [jamOprasionalA, setJamOprasionalA] = useState("");
  const [jamOprasionalB, setJamOprasionalB] = useState("");
  const [omzet, setOmzet] = useState("");
  const [omzetError, setOmzetError] = useState(null);
  const [jenisLangganan, setJenisLangganan] = useState("");
  const [jenisLanggananError, setJenisLanggananError] = useState(null);

  const [nama, setNama] = useState("");
  const [namaError, setNamaError] = useState(null);
  const [tempatLahir, setTempatLahir] = useState("");
  const [tempatLahirError, setTempatLahirError] = useState("");
  const [tanggalLahir, setTanggalLahir] = useState("");
  const [tanggalLahirError, setTanggalLahirError] = useState(null);
  const [nomorTelpon, setNomorTelpon] = useState("");
  const [nomorTelponError, setNomorTelponError] = useState(null);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(null);

  const [namaBank, setNamaBank] = useState("");
  const [namaBankError, setNamaBankError] = useState(null);
  const [nomorBank, setNomorBank] = useState("");
  const [nomorBankError, setNomorBankError] = useState(null);
  const [pemilikRekening, setPemilikRekening] = useState("");
  const [pemilikRekeningError, setPemilikRekeningError] = useState(null);
  const [cabang, setCabang] = useState("");
  const [cabangError, setCabangError] = useState(null);

  const [ktp, setKtp] = useState(false);
  const [npwp, setNpwp] = useState(false);
  const [pbb, setPbb] = useState(false);
  const [fotoUsaha, setFotoUsaha] = useState(false);

  const [ktpBadanHukum, setKtpBadanHukum] = useState(false);
  const [npwpBadanHukum, setNpwpBadanHukum] = useState(false);
  const [aktaPerusahaan, setAktaPerusahaan] = useState(false);
  const [suratKemenkumham, setSuratKemenkumham] = useState(false);
  const [siup, setSiup] = useState(false);
  const [tdp, setTdp] = useState(false);
  const [situ, setSitu] = useState(false);
  const [fotoTempatUsaha, setFotoTempatUsaha] = useState(false);
  const [suratKuasa, setSuratKuasaa] = useState(false);
  const [tanggalDoc, setTanggalDoc] = useState("");
  const [tanggalDocError, setTanggalDocError] = useState(null);

  const [isCheckedIn] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const [project, setProject] = useState("");
  const [projectError, setProjectError] = useState(null);
  const [groups, setGroups] = useState([]);
  const [group, setGroup] = useState("");
  const [groupError, setGroupError] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [groupAlls, setGroupAlls] = useState([]);

  const validateForm = () => {
    let error = false;
    if (brand === "") {
      setBrandError("invalid");
      error = true;
    }
    if (namaBadanHukum === "") {
      setBadanHukumError("invalid");
      error = true;
    }
    if (address === "") {
      setAddressError("invalid");
      error = true;
    }
    if (countries === "") {
      setCountryError("invalid");
      error = true;
    }
    if (region === "") {
      setRegionError("invalid");
      error = true;
    }
    if (regancy === "") {
      setRegancyError("invalid");
      error = true;
    }
    if (district === "") {
      setDistrictError("invalid");
      error = true;
    }
    if (kodePos === "") {
      setKodePosError("invalid");
      error = true;
    }
    if (kategori === "") {
      setKategoriError("invalid");
      error = true;
    }
    if (isSelectUnitKerja === 1) {
      if (unitKerja === "") {
        setUnitKerjaError("invalid");
        error = true;
      }
    }
    if (isSelectedStatus === 2) {
      if (dateSewa === "") {
        setDateSewaError("invalid");
        error = true;
      }
    }
    if (omzet === "") {
      setOmzetError("invalid");
      error = true;
    }
    if (jenisLangganan === "") {
      setJenisLanggananError("invalid");
      error = true;
    }
    if (nama === "") {
      setNamaError("invalid");
      error = true;
    }
    if (tempatLahir === "") {
      setTempatLahirError("invalid");
      error = true;
    }
    if (tanggalLahir === "") {
      setTanggalLahirError("invalid");
      error = true;
    }
    if (nomorTelpon === "") {
      setNomorTelponError("invalid");
      error = true;
    }
    if (email === "") {
      setEmailError("invalid");
      error = true;
    }
    if (namaBank === "") {
      setNamaBankError("invalid");
      error = true;
    }
    if (nomorBank === "") {
      setNomorBankError("invalid");
      error = true;
    }
    if (pemilikRekening === "") {
      setPemilikRekeningError("invalid");
      error = true;
    }
    if (cabang === "") {
      setCabangError("invalid");
      error = true;
    }
    if (tanggalDoc === "") {
      setTanggalDocError("invalid");
      error = true;
    }
    return error;
  };

  useEffect(() => {
    getProjects();
    getCountries();
    getCategories();
    if (project === "") {
      getGroupAll();
    }
  }, []);

  const getProjects = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/projects/list-create`, {
        method: 'GET', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(),
      })
      const data = await response.json();
      let options = [];
      data.response_data.map((project) => {
        return options.push({ value: project.merchant_pk, label: project.merchant_name })
      })
      setProjectList(options);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const getUniKerja = async (projectId) => {
    const body = {
      projectId: parseInt(projectId)
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/workunit/list`, {
        method: 'POST', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
      const data = await response.json();
      setListUnitKerja(data.response_data);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const getGroup = async (merchant_pk) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/merchants/groups/list?project_id=${merchant_pk}`, {
        method: 'GET', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(),
      })
      const data = await response.json();
      let options = [];
      data.response_data.map((group) => {
        return options.push({ value: group.merchant_pk, label: group.merchant_name })
      })
      setGroups(options);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const getGroupAll = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/merchants/group/list`, {
        method: 'GET', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(),
      })
      const data = await response.json();
      let options = [];
      data.response_data.map((group) => {
        return options.push({ value: group.merchant_pk, label: group.merchant_name })
      })
      setGroupAlls(options);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const getCategories = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/merchantCategories/list`, {
        method: 'GET', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(),
      })
      const data = await response.json();
      setCategories(data.response_data);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const getCountries = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/countries`, {
        method: 'GET', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(),
      })
      const data = await response.json();
      let options = [];
      data.response_data.map((country) => {
        return options.push({ value: country.country_id, label: country.name })
      })
      setCountries(options);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const getRegions = async (country_id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/regions?country_id=${country_id}`, {
        method: 'GET', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(),
      })
      const data = await response.json();
      let options = [];
      data.response_data.map((region) => {
        return options.push({ value: region.region_id, label: region.name })
      })
      setRegions(options);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const getRegencies = async (regency_id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/regencies?region_id=${regency_id}`, {
        method: 'GET', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(),
      })
      const data = await response.json();
      let options = [];
      data.response_data.map((regancy) => {
        return options.push({ value: regancy.regency_id, label: regancy.name })
      })
      setRegencies(options);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const getDistricts = async (district_id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/districts?regency_id=${district_id}`, {
        method: 'GET', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(),
      })
      const data = await response.json();
      let options = [];
      data.response_data.map((district) => {
        return options.push({ value: district.district_id, label: district.name })
      })
      setDistricts(options);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const handleSelectedItem = (element) => {
    setSelectedOption(element);
    getRegions(element.value);
  }

  const handleSelectedRegion = (element) => {
    setRegion(element);
    getRegencies(element.value);
  }

  const handleSelectedRegancy = (element) => {
    setRegancy(element);
    getDistricts(element.value);
  }

  const handleSelectedDistrict = (element) => {
    setDistrict(element);
  }

  const handleSelectedProject = (element) => {
    setProject(element);
    getGroup(element.value);
    getUniKerja(element.value);
  }

  const handleSelectedGroup = (element) => {
    setGroup(element);
  }

  const handleSelectedGroupAll = (element) => {
    setGroup(element);
  }

  function SaveDraft() {
    setLoading(true);
    let body = {
      entity_type: parseInt(bentukMerchant),
      merchant_name: brand,
      corporation_name: namaBadanHukum,
      merchant_address: address,
      country_id: parseInt(selectedOption.value),
      region_id: parseInt(region.value),
      regency_id: parseInt(regancy.value),
      district_id: parseInt(district.value),
      zip_code: kodePos,
      turnover: omzet,
      location_status: parseInt(isSelectedStatus),
      location_end_period: dateSewa === "" ? null : moment(dateSewa).format("DD/MM/YYYY"),
      merchant_category_id: parseInt(kategori),
      operational_hour_start: jamOprasionalA === "" ? null : moment(jamOprasionalA, 'HH:mm:ss').format("HH:mm:ss"),
      operational_hour_end: jamOprasionalB === "" ? null : moment(jamOprasionalB, 'HH:mm:ss').format("HH:mm:ss"),
      subscribe_type: parseInt(jenisLangganan),
      pic_name: nama,
      pic_birthplace: tempatLahir,
      pic_dob: moment(tanggalLahir).format("DD/MM/yyyy"),
      pic_phone: nomorTelpon,
      pic_email: email,
      bank_name: namaBank,
      account_number: nomorBank,
      account_name: pemilikRekening,
      bank_branch: cabang,
      identity_copy: parseInt(ktp),
      tax_copy: parseInt(npwp),
      property_tax_copy: parseInt(pbb),
      pob_picture: parseInt(fotoUsaha),
      group_id: parseInt(group.value),
      project_id: parseInt(project.value),
      doc_collected_date: moment(tanggalDoc).format("DD/MM/yyyy"),

      company_deed_copy: parseInt(aktaPerusahaan),
      gov_letter_copy: parseInt(suratKemenkumham),
      trade_licence_copy: parseInt(siup),
      crc_copy: parseInt(tdp),
      pob_permit_copy: parseInt(situ),
      power_of_attorney: parseInt(suratKuasa),

      workUnitPk: parseInt(unitKerja),
      isWorkUnit: parseInt(isSelectUnitKerja),
    }
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/merchant/draft`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then(function (response) {
        history.push("admin/customers/daftar-merchant")
      })
      .then(json => {
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  function CreateData() {
    setLoading(true);
    let body = {
      entity_type: parseInt(bentukMerchant),
      merchant_name: brand,
      corporation_name: namaBadanHukum,
      merchant_address: address,
      country_id: parseInt(selectedOption.value),
      region_id: parseInt(region.value),
      regency_id: parseInt(regancy.value),
      district_id: parseInt(district.value),
      zip_code: kodePos,
      turnover: omzet,
      location_status: parseInt(isSelectedStatus),
      location_end_period: dateSewa === "" ? null : moment(dateSewa).format("DD/MM/YYYY"),
      merchant_category_id: parseInt(kategori),
      operational_hour_start: jamOprasionalA === "" ? null : moment(jamOprasionalA, 'HH:mm:ss').format("HH:mm:ss"),
      operational_hour_end: jamOprasionalB === "" ? null : moment(jamOprasionalB, 'HH:mm:ss').format("HH:mm:ss"),
      subscribe_type: parseInt(jenisLangganan),
      pic_name: nama,
      pic_birthplace: tempatLahir,
      pic_dob: moment(tanggalLahir).format("DD/MM/yyyy"),
      pic_phone: nomorTelpon,
      pic_email: email,
      bank_name: namaBank,
      account_number: nomorBank,
      account_name: pemilikRekening,
      bank_branch: cabang,
      identity_copy: parseInt(ktp),
      tax_copy: parseInt(npwp),
      property_tax_copy: parseInt(pbb),
      pob_picture: parseInt(fotoUsaha),
      group_id: parseInt(group.value),
      project_id: parseInt(project.value),
      doc_collected_date: moment(tanggalDoc).format("DD/MM/yyyy"),

      company_deed_copy: parseInt(aktaPerusahaan),
      gov_letter_copy: parseInt(suratKemenkumham),
      trade_licence_copy: parseInt(siup),
      crc_copy: parseInt(tdp),
      pob_permit_copy: parseInt(situ),
      power_of_attorney: parseInt(suratKuasa),

      workUnitPk: parseInt(unitKerja),
      isWorkUnit: parseInt(isSelectUnitKerja),
    }
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/merchant/save`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then(function (response) {
        history.push("admin/customers/daftar-merchant")
      })
      .then(json => {
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      CreateData();
    }
  }

  const handleSubmitDraft = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      SaveDraft();
    }
  }

  return (
    <>
      <SimpleHeader name="Create Merchant" parentName="Customers" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Form onSubmit={handleSubmit}>
              <Card>
                <CardHeader>
                  <h3>Data Merchant</h3>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <Label
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect3"
                        >
                          Bentuk Merchant
                                    </Label>
                        <Row>
                          <Col>
                            <div style={{ display: "flex" }}>
                              <div className="custom-control custom-radio mb-3">
                                <Input
                                  className="custom-control-input"
                                  defaultChecked
                                  id="customRadio5"
                                  name="custom-radio-2"
                                  type="radio"
                                  value={1}
                                  checked={bentukMerchant === 1}
                                  onClick={() => setBentuMerchant(1)}
                                />
                                <Label
                                  className="custom-control-label"
                                  htmlFor="customRadio5"
                                >
                                  Perorangan
                                                    </Label>
                              </div>
                              <div className="custom-control custom-radio mb-3" style={{ marginLeft: "20px" }}>
                                <Input
                                  className="custom-control-input"
                                  id="customRadio6"
                                  name="custom-radio-2"
                                  type="radio"
                                  value={2}
                                  checked={bentukMerchant === 2}
                                  onClick={() => setBentuMerchant(2)}
                                />
                                <Label
                                  className="custom-control-label"
                                  htmlFor="customRadio6"
                                >
                                  Badan Hukum
                                                    </Label>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <Label
                          className="form-control-label"
                          htmlFor="exampleFormControlInput1"
                        >
                          Project
                                    </Label>
                        <Select
                          className="project-select"
                          classNamePrefix="select"
                          onChange={e => handleSelectedProject(e)}
                          value={project}
                          isDisabled={isCheckedIn}
                          options={projectList}
                        />
                      </FormGroup>
                    </Col>
                    {
                      project === "" ?
                        <Col md="4">
                          <FormGroup>
                            <Label
                              className="form-control-label"
                              htmlFor="exampleFormControlInput1"
                            >
                              Group Merchant
                            </Label>
                            <Select
                              className="group-select"
                              classNamePrefix="select"
                              onChange={e => handleSelectedGroupAll(e)}
                              value={group}
                              isDisabled={isCheckedIn}
                              options={groupAlls}
                            />
                          </FormGroup>
                        </Col>
                        :

                        <Col md="4">
                          <FormGroup>
                            <Label
                              className="form-control-label"
                              htmlFor="exampleFormControlInput1"
                            >
                              Group Merchant
                        </Label>
                            <Select
                              className="group-select"
                              classNamePrefix="select"
                              onChange={e => handleSelectedGroup(e)}
                              value={group}
                              isDisabled={isCheckedIn}
                              options={groups}
                            />
                          </FormGroup>
                        </Col>
                    }
                  </Row>
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <Label
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect3"
                        >
                          Unit Kerja
                                    </Label>
                        <Row>
                          <Col md="6">
                            <div className="custom-control custom-radio mb-3">
                              <Input
                                className="custom-control-input"
                                id="iya"
                                name="custom-radio-3"
                                type="radio"
                                value={1}
                                checked={isSelectUnitKerja === 1}
                                onClick={() => setSelecUnitKerja(1)}
                              />
                              <Label
                                className="custom-control-label"
                                htmlFor="iya"
                              >
                                Ya
                                        </Label>
                            </div>
                            <Row>
                              <Col md="6">
                                <div className="custom-control custom-radio mb-3">
                                  <Input
                                    className="custom-control-input"
                                    id="tidak"
                                    name="custom-radio-3"
                                    type="radio"
                                    value={2}
                                    checked={isSelectUnitKerja === 2}
                                    onClick={() => setSelecUnitKerja(2)}
                                  />
                                  <Label
                                    className="custom-control-label"
                                    htmlFor="tidak"
                                  >
                                    Tidak
                                                </Label>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </FormGroup>
                    </Col>
                    {
                      isSelectUnitKerja === 1 ?
                        <Col md="4">
                          <FormGroup>
                            <Label
                              className="form-control-label"
                              htmlFor="exampleFormControlInput1"
                            >
                              Unit Kerja
                        </Label>
                            <Input
                              name="unitKerja"
                              type="select"
                              value={unitKerja}
                              invalid={unitKerjaError === "invalid"}
                              onChange={(e) => {
                                setUnitKerja(e.target.value);
                                if (e.target.value !== "") {
                                  setUnitKerjaError("");
                                }
                              }}
                            >
                              <option value="">Pilih Unit Kerja</option>
                              {
                                listUnitKerja.map((workUnit, key) => {
                                  return <option key={key} value={workUnit.workUnitPk}>{workUnit.workUnitName}</option>
                                })
                              }
                            </Input>
                            <FormFeedback>Unit Kerja tidak boleh kosong</FormFeedback>
                          </FormGroup>
                        </Col>
                        :
                        <div></div>
                    }
                  </Row>
                  <FormGroup>
                    <Label
                      className="form-control-label"
                      htmlFor="exampleFormControlInput1"
                    >
                      Nama / Brand
                    </Label>
                    <Input
                      type="text"
                      placeholder="Masukan Nama / Brand"
                      value={brand}
                      invalid={brandError === "invalid"}
                      onChange={(e) => {
                        setBrand(e.target.value);
                        if (e.target.value !== "") {
                          setBrandError("");
                        }
                      }}
                    />
                    <FormFeedback>Brand tidak boleh kosong</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label
                      className="form-control-label"
                      htmlFor="exampleFormControlInput1"
                    >
                      Nama Badan Hukum
                            </Label>
                    <Input
                      type="text"
                      placeholder="Masukan Nama Badan Hukum"
                      value={namaBadanHukum}
                      invalid={badanHukumError === "invalid"}
                      onChange={(e) => {
                        setNamaBadanHukum(e.target.value);
                        if (e.target.value !== "") {
                          setBadanHukumError("");
                        }
                      }}
                    />
                    <FormFeedback>Nama badan hukum tidak boleh kosong</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label
                      className="form-control-label"
                      htmlFor="exampleFormControlInput1"
                    >
                      Alamat
                            </Label>
                    <Input
                      type="textarea"
                      placeholder="Masukan Alamat"
                      value={address}
                      invalid={addressError === "invalid"}
                      onChange={(e) => {
                        setAddress(e.target.value);
                        if (e.target.value !== "") {
                          setAddressError("");
                        }
                      }}
                    />
                    <FormFeedback>Alamat tidak boleh kosong</FormFeedback>
                  </FormGroup>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label
                          className="form-control-label"
                          htmlFor="exampleFormControlInput1"
                        >
                          Negara
                                    </Label>
                        <Select
                          className="country-select"
                          classNamePrefix="select"
                          onChange={e => handleSelectedItem(e)}
                          value={selectedOption}
                          isDisabled={isCheckedIn}
                          options={countries}
                          invalid={countryError === "invalid"}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label
                          className="form-control-label"
                          htmlFor="exampleFormControlInput1"
                        >
                          Provinsi
                                    </Label>
                        <Select
                          className="region-select"
                          classNamePrefix="select"
                          onChange={e => handleSelectedRegion(e)}
                          value={region}
                          isDisabled={isCheckedIn}
                          options={regions}
                          invalid={regionError === "invalid"}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label
                          className="form-control-label"
                          htmlFor="exampleFormControlInput1"
                        >
                          Kab./Kota
                        </Label>
                        <Select
                          className="regancy-select"
                          classNamePrefix="select"
                          onChange={e => handleSelectedRegancy(e)}
                          value={regancy}
                          isDisabled={isCheckedIn}
                          options={regencies}
                          invalid={regancyError === "invalid"}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label
                          className="form-control-label"
                          htmlFor="exampleFormControlInput1"
                        >
                          Kecamatan
                                    </Label>
                        <Select
                          className="district-select"
                          classNamePrefix="select"
                          onChange={e => handleSelectedDistrict(e)}
                          value={district}
                          isDisabled={isCheckedIn}
                          options={districts}
                          invalid={districtError === "invalid"}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Label
                      className="form-control-label"
                      htmlFor="exampleFormControlInput1"
                    >
                      Kode Pos
                            </Label>
                    <Input
                      type="number"
                      placeholder="Masukan Kode Pos"
                      value={kodePos}
                      invalid={kodePosError === "invalid"}
                      onChange={(e) => {
                        setKodePos(e.target.value);
                        if (e.target.value !== "") {
                          setKodePosError("");
                        }
                      }}
                    />
                    <FormFeedback>Kode pos tidak boleh kosong</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label
                      className="form-control-label"
                      htmlFor="exampleFormControlSelect3"
                      md="6"
                    >
                      Status Lokasi
                            </Label>
                    <Row>
                      <Col md="6">
                        <div className="custom-control custom-radio mb-3">
                          <Input
                            className="custom-control-input"
                            defaultChecked
                            id="milikSendiri"
                            name="custom-radio-1"
                            type="radio"
                            value={1}
                            checked={isSelectedStatus === 1}
                            onClick={() => setSelectedStatus(1)}
                          />
                          <Label
                            className="custom-control-label"
                            htmlFor="milikSendiri"
                          >
                            Milik Sendiri
                                        </Label>
                        </div>
                        <Row>
                          <Col md="6">
                            <div className="custom-control custom-radio mb-3">
                              <Input
                                className="custom-control-input"
                                id="sewa"
                                name="custom-radio-1"
                                type="radio"
                                value={2}
                                checked={isSelectedStatus === 2}
                                onClick={() => setSelectedStatus(2)}
                              />
                              <Label
                                className="custom-control-label"
                                htmlFor="sewa"
                              >
                                Sewa
                                                </Label>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      {
                        isSelectedStatus === 1 ? <></> :
                          <Col md="6">
                            <Label
                              className="form-control-label"
                              htmlFor="exampleFormControlSelect3"
                            >
                              Berlaku s/d
                            </Label>
                            <Input
                              id="example-date-input"
                              type="date"
                              value={dateSewa}
                              invalid={dateSewaError === "invalid"}
                              onChange={(e) => {
                                setDataSewa(e.target.value);
                                if (e.target.value !== "") {
                                  setDateSewaError("");
                                }
                              }}
                            />
                            <FormFeedback>Masa berlaku tidak boleh kosong</FormFeedback>
                          </Col>
                      }
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Label
                      className="form-control-label"
                      htmlFor="exampleFormControlSelect3"
                    >
                      Kategori
                            </Label>
                    <Input
                      name="kategori"
                      type="select"
                      value={kategori}
                      invalid={kategoriError === "invalid"}
                      onChange={(e) => {
                        setKategori(e.target.value);
                        if (e.target.value !== "") {
                          setKategoriError("");
                        }
                      }}
                    >
                      <option value="">Pilih Kategori</option>
                      {
                        categories.map((category, key) => {
                          return <option key={key} value={category.merchant_category_id}>{category.merchant_category_name}</option>
                        })
                      }

                    </Input>
                    <FormFeedback>Kategori tidak boleh kosong</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label
                      className="form-control-label"
                      htmlFor="example-time-input"
                    >
                      Jam Operasional
                            </Label>
                    <Row>
                      <Col md="6">
                        <Input
                          defaultValue="00:00:00"
                          id="example-time-input"
                          type="time"
                          placeholder="Jam Buka"
                          value={jamOprasionalA}
                          onChange={(e) => {
                            setJamOprasionalA(e.target.value);
                          }}
                        />
                      </Col>
                      <Col md="6">
                        <Input
                          defaultValue="00:00:00"
                          id="example-time-input"
                          type="time"
                          placeholder="Jam Tutup"
                          value={jamOprasionalB}
                          onChange={(e) => {
                            setJamOprasionalB(e.target.value);
                          }}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Label
                      className="form-control-label"
                      htmlFor="exampleFormControlInput1"
                    >
                      Omzet
                            </Label>
                    <Input
                      type="text"
                      placeholder="Masukan Omzet"
                      value={omzet}
                      invalid={omzetError === "invalid"}
                      onChange={(e) => {
                        setOmzet(e.target.value);
                        if (e.target.value !== "") {
                          setOmzetError("");
                        }
                      }}
                    />
                    <FormFeedback>Omzet tidak boleh kosong</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label
                      className="form-control-label"
                      htmlFor="jenisLangganan"
                    >
                      Jenis Langganan
                            </Label>
                    <Input
                      id="jenisLangganan"
                      type="select"
                      value={jenisLangganan}
                      invalid={jenisLanggananError === "invalid"}
                      onChange={(e) => {
                        setJenisLangganan(e.target.value);
                        if (e.target.value !== "") {
                          setJenisLanggananError("");
                        }
                      }}
                    >
                      <option>Pilih Jenis Langganan</option>
                      <option value={1}>Basic</option>
                      <option value={2}>Menengah</option>
                      <option value={3}>Penuh</option>
                    </Input>
                    <FormFeedback>Jenis Langganan tidak boleh kosong</FormFeedback>
                  </FormGroup>
                </CardBody>
              </Card>
              <Card>
                <CardHeader style={{ borderTop: "20px solid rgba(0, 0, 0, 0.05)" }}>
                  <h3>Data Pemilik / Penanggung Jawab</h3>
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label
                      className="form-control-label"
                      htmlFor="exampleFormControlInput1"
                    >
                      Nama
                            </Label>
                    <Input
                      type="text"
                      placeholder="Masukan Nama"
                      value={nama}
                      invalid={namaError === "invalid"}
                      onChange={(e) => {
                        setNama(e.target.value);
                        if (e.target.value !== "") {
                          setNamaError("");
                        }
                      }}
                    />
                    <FormFeedback>Nama tidak boleh kosong</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label
                      className="form-control-label"
                      htmlFor="exampleFormControlInput1"
                    >
                      Tempat & Tanggal Lahir
                            </Label>
                    <Row>
                      <Col md="6">
                        <Input
                          type="text"
                          placeholder="Masukan Tempat Lahir"
                          value={tempatLahir}
                          invalid={tempatLahirError === "invalid"}
                          onChange={(e) => {
                            setTempatLahir(e.target.value);
                            if (e.target.value !== "") {
                              setTempatLahirError("");
                            }
                          }}
                        />
                        <FormFeedback>Tempat lahir tidak boleh kosong</FormFeedback>
                      </Col>
                      <Col md="6">
                        <Input
                          defaultValue={new Date().getFullYear() + "-11-23"}
                          id="example-date-input"
                          type="date"
                          value={tanggalLahir}
                          invalid={tanggalLahirError === "invalid"}
                          onChange={(e) => {
                            setTanggalLahir(e.target.value);
                            if (e.target.value !== "") {
                              setTanggalLahirError("");
                            }
                          }}
                        />
                        <FormFeedback>Tanggal lahir tidak boleh kosong</FormFeedback>
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Label
                      className="form-control-label"
                      htmlFor="exampleFormControlInput1"
                    >
                      Nomor Telepon
                            </Label>
                    <Input
                      type="number"
                      placeholder="Masukan No. Telp"
                      value={nomorTelpon}
                      invalid={nomorTelponError === "invalid"}
                      onChange={(e) => {
                        setNomorTelpon(e.target.value);
                        if (e.target.value !== "") {
                          setNomorTelponError("");
                        }
                      }}
                    />
                    <FormFeedback>Nomor Telpon tidak boleh kosong</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label
                      className="form-control-label"
                      htmlFor="exampleFormControlInput1"
                    >
                      Email
                            </Label>
                    <Input
                      type="email"
                      placeholder="Masukan Email"
                      value={email}
                      invalid={emailError === "invalid"}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        if (e.target.value !== "") {
                          setEmailError("");
                        }
                      }}
                    />
                    <FormFeedback>Email tidak boleh kosong</FormFeedback>
                  </FormGroup>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <h3>Data Rekening</h3>
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label
                      className="form-control-label"
                      htmlFor="exampleFormControlInput1"
                    >
                      Nama Bank
                            </Label>
                    <Input
                      type="text"
                      placeholder="Masukan Nama Bank"
                      value={namaBank}
                      invalid={namaBankError === "invalid"}
                      onChange={(e) => {
                        setNamaBank(e.target.value);
                        if (e.target.value !== "") {
                          setNamaBankError("");
                        }
                      }}
                    />
                    <FormFeedback>Nama Bank tidak boleh kosong</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label
                      className="form-control-label"
                      htmlFor="exampleFormControlInput1"
                    >
                      Nomor Rekening
                            </Label>
                    <Input
                      type="number"
                      placeholder="Masukan Nomor Rekening"
                      value={nomorBank}
                      invalid={nomorBankError === "invalid"}
                      onChange={(e) => {
                        setNomorBank(e.target.value);
                        if (e.target.value !== "") {
                          setNomorBankError("");
                        }
                      }}
                    />
                    <FormFeedback>Nomor Rekening tidak boleh kosong</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label
                      className="form-control-label"
                      htmlFor="exampleFormControlInput1"
                    >
                      Nama Pemilik Rekening
                            </Label>
                    <Input
                      type="text"
                      placeholder="Masukan Pemilik Rekening"
                      value={pemilikRekening}
                      invalid={pemilikRekeningError === "invalid"}
                      onChange={(e) => {
                        setPemilikRekening(e.target.value);
                        if (e.target.value !== "") {
                          setPemilikRekeningError("");
                        }
                      }}
                    />
                    <FormFeedback>Nama pemilik rekening tidak boleh kosong</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label
                      className="form-control-label"
                      htmlFor="exampleFormControlInput1"
                    >
                      Cabang
                            </Label>
                    <Input
                      type="text"
                      placeholder="Masukan Cabang"
                      value={cabang}
                      invalid={cabangError === "invalid"}
                      onChange={(e) => {
                        setCabang(e.target.value);
                        if (e.target.value !== "") {
                          setCabangError("");
                        }
                      }}
                    />
                    <FormFeedback>Cabang tidak boleh kosong</FormFeedback>
                  </FormGroup>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <h3>Administrasi Merchant</h3>
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label
                      className="form-control-label"
                      htmlFor="exampleFormControlSelect3"
                    >
                      Persyaratan
                            </Label>
                    <Row>
                      {
                        bentukMerchant === 1 ?
                          <Col md="6">
                            <div className="custom-control custom-checkbox mb-3">
                              <Input
                                className="custom-control-input"
                                id="ktp"
                                type="checkbox"
                                value={1}
                                checked={ktp}
                                onChange={(e) => {
                                  let checkedValue = 0;
                                  if (e.target.checked) {
                                    checkedValue = 1;
                                  }
                                  setKtp(checkedValue);
                                }}

                              />
                              <Label
                                className="custom-control-label"
                                htmlFor="ktp"
                              >
                                Fotokopi KTP / Password (WNA)
                                            </Label>
                            </div>
                            <div className="custom-control custom-checkbox mb-3">
                              <Input
                                className="custom-control-input"
                                id="npwp"
                                type="checkbox"
                                checked={npwp}
                                value={1}
                                onChange={(e) => {
                                  let checkedValue = 0;
                                  if (e.target.checked) {
                                    checkedValue = 1;
                                  }
                                  setNpwp(checkedValue);
                                }}
                              />
                              <Label
                                className="custom-control-label"
                                htmlFor="npwp"
                              >
                                Fotokopi NPWP (Jika ada)
                                            </Label>
                            </div>
                            <div className="custom-control custom-checkbox mb-3">
                              <Input
                                className="custom-control-input"
                                id="pbb"
                                type="checkbox"
                                checked={pbb}
                                value={1}
                                onChange={(e) => {
                                  let checkedValue = 0;
                                  if (e.target.checked) {
                                    checkedValue = 1;
                                  }
                                  setPbb(checkedValue);
                                }}
                              />
                              <Label
                                className="custom-control-label"
                                htmlFor="pbb"
                              >
                                Fotokopi PBB/Surat Sewa/Surat Domisili (Jika ada)
                                            </Label>
                            </div>
                            <div className="custom-control custom-checkbox mb-3">
                              <Input
                                className="custom-control-input"
                                id="foto"
                                type="checkbox"
                                checked={fotoUsaha}
                                value={1}
                                onChange={(e) => {
                                  let checkedValue = 0;
                                  if (e.target.checked) {
                                    checkedValue = 1;
                                  }
                                  setFotoUsaha(checkedValue);
                                }}
                              />
                              <Label
                                className="custom-control-label"
                                htmlFor="foto"
                              >
                                Foto Tempat Usaha
                                            </Label>
                            </div>
                          </Col> :
                          <Col md="6">
                            <div className="custom-control custom-checkbox mb-3">
                              <Input
                                className="custom-control-input"
                                id="ktp1"
                                type="checkbox"
                                value={1}
                                checked={ktp}
                                onChange={(e) => {
                                  let checkedValue = 0;
                                  if (e.target.checked) {
                                    checkedValue = 1;
                                  }
                                  setKtp(checkedValue);
                                }}
                              />
                              <Label
                                className="custom-control-label"
                                htmlFor="ktp1"
                              >
                                Fotokopi KTP / Password (WNA)
                                            </Label>
                            </div>
                            <div className="custom-control custom-checkbox mb-3">
                              <Input
                                className="custom-control-input"
                                id="npwpBadanHukum"
                                type="checkbox"
                                value={1}
                                checked={npwp}
                                onChange={(e) => {
                                  let checkedValue = 0;
                                  if (e.target.checked) {
                                    checkedValue = 1;
                                  }
                                  setNpwp(checkedValue);
                                }}
                              />
                              <Label
                                className="custom-control-label"
                                htmlFor="npwpBadanHukum"
                              >
                                Fotokopi NPWP Badan Hukum
                                            </Label>
                            </div>
                            <div className="custom-control custom-checkbox mb-3">
                              <Input
                                className="custom-control-input"
                                id="aktaPerusahaan"
                                type="checkbox"
                                value={1}
                                checked={aktaPerusahaan}
                                onChange={(e) => {
                                  let checkedValue = 0;
                                  if (e.target.checked) {
                                    checkedValue = 1;
                                  }
                                  setAktaPerusahaan(checkedValue);
                                }}
                              />
                              <Label
                                className="custom-control-label"
                                htmlFor="aktaPerusahaan"
                              >
                                Fotokopi Akta Perusahaan
                                            </Label>
                            </div>
                            <div className="custom-control custom-checkbox mb-3">
                              <Input
                                className="custom-control-input"
                                id="kemenkumham"
                                type="checkbox"
                                value={1}
                                checked={suratKemenkumham}
                                onChange={(e) => {
                                  let checkedValue = 0;
                                  if (e.target.checked) {
                                    checkedValue = 1;
                                  }
                                  setSuratKemenkumham(checkedValue);
                                }}
                              />
                              <Label
                                className="custom-control-label"
                                htmlFor="kemenkumham"
                              >
                                Fotokopi Surat Kemenkumham
                                            </Label>
                            </div>
                            <div className="custom-control custom-checkbox mb-3">
                              <Input
                                className="custom-control-input"
                                id="siup"
                                type="checkbox"
                                value={1}
                                checked={siup}
                                onChange={(e) => {
                                  let checkedValue = 0;
                                  if (e.target.checked) {
                                    checkedValue = 1;
                                  }
                                  setSiup(checkedValue);
                                }}
                              />
                              <Label
                                className="custom-control-label"
                                htmlFor="siup"
                              >
                                Fotokopi SIUP
                                            </Label>
                            </div>
                            <div className="custom-control custom-checkbox mb-3">
                              <Input
                                className="custom-control-input"
                                id="tdp"
                                type="checkbox"
                                value={1}
                                checked={tdp}
                                onChange={(e) => {
                                  let checkedValue = 0;
                                  if (e.target.checked) {
                                    checkedValue = 1;
                                  }
                                  setTdp(checkedValue);
                                }}
                              />
                              <Label
                                className="custom-control-label"
                                htmlFor="tdp"
                              >
                                Fotokopi TDP
                                            </Label>
                            </div>
                            <div className="custom-control custom-checkbox mb-3">
                              <Input
                                className="custom-control-input"
                                id="skdu"
                                type="checkbox"
                                value={1}
                                checked={situ}
                                onChange={(e) => {
                                  let checkedValue = 0;
                                  if (e.target.checked) {
                                    checkedValue = 1;
                                  }
                                  setSitu(checkedValue);
                                }}
                              />
                              <Label
                                className="custom-control-label"
                                htmlFor="skdu"
                              >
                                Fotokopi SITU/SKDU
                                            </Label>
                            </div>
                            <div className="custom-control custom-checkbox mb-3">
                              <Input
                                className="custom-control-input"
                                id="tempatUsaha"
                                type="checkbox"
                                value={1}
                                checked={fotoUsaha}
                                onChange={(e) => {
                                  let checkedValue = 0;
                                  if (e.target.checked) {
                                    checkedValue = 1;
                                  }
                                  setFotoUsaha(checkedValue);
                                }}
                              />
                              <Label
                                className="custom-control-label"
                                htmlFor="tempatUsaha"
                              >
                                Foto Tempat Usaha
                                            </Label>
                            </div>
                            <div className="custom-control custom-checkbox mb-3">
                              <Input
                                className="custom-control-input"
                                id="suratKuasa"
                                type="checkbox"
                                value={suratKuasa}
                                value={1}
                                checked={suratKuasa}
                                onChange={(e) => {
                                  let checkedValue = 0;
                                  if (e.target.checked) {
                                    checkedValue = 1;
                                  }
                                  setSuratKuasaa(checkedValue);
                                }}
                              />
                              <Label
                                className="custom-control-label"
                                htmlFor="suratKuasa"
                              >
                                Surat Kuasa (Jika ada)
                                            </Label>
                            </div>
                          </Col>
                      }
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Label
                      className="form-control-label"
                      htmlFor="exampleFormControlSelect3"
                    >
                      Tanggal Berkas Terkumpul
                            </Label>
                    <Input
                      defaultValue={new Date().getFullYear() + "-11-23"}
                      id="example-date-input"
                      type="date"
                      value={tanggalDoc}
                      invalid={tanggalDocError === "invalid"}
                      onChange={(e) => {
                        setTanggalDoc(e.target.value);
                        if (e.target.value !== "") {
                          setTanggalDocError("");
                        }
                      }}
                    />
                    <FormFeedback>Tanggal Doc tidak boleh kosong</FormFeedback>
                  </FormGroup>
                </CardBody>
                <CardFooter>
                  {!isLoading && (<Button color="primary" type="submit" >
                    Simpan
                  </Button>)}
                  {isLoading && (<Button color="primary" disabled>
                    <i className="fas fa-spinner fa-spin"></i>{""}
                      loading...
                  </Button>)}
                  {!isLoading && (<Button color="secondary" onClick={handleSubmitDraft}>
                    Draft
                  </Button>)}
                  {isLoading && (<Button color="secondary" disabled>
                    <i className="fas fa-spinner fa-spin"></i>{""}
                      loading...
                  </Button>)}
                  <Link class="btn btn-info" to="/admin/customers/daftar-merchant">
                    Kembali
                  </Link>
                </CardFooter>
              </Card>
            </Form>
          </div>
        </Row>
      </Container>
    </>
  );
}
