/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Label,
  FormGroup,
  Row,
  Input,
  Container,
  CardHeader,
  CardFooter,
  Button,
  Form
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import axios from 'axios';
import SimpleHeader from "components/Headers/SimpleHeader.js";

export default function EditAcquirer(props) {
  const token = localStorage.getItem("token");
  const [acquirerId, setAcquirerId] = useState("");
  const [acqTransTypeMdr, setAcqTransTypeMdr] = useState("");
  const [transactionType, setTrasactionType] = useState("");
  const [transtypeId, setTranstypeId] = useState("");
  const [acqTransTypeStatus, setAcqTransTypeStatus] = useState(1);
  const [hostTypes, setHostTypes] = useState([]);
  const [types, setTypes] = useState([]);
  let history = useHistory();
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    getAcquirerMdrById();
  }, []);

  const getAcquirerMdrById = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/acqtranstype/${props.match.params.id}`, {
        method: 'GET', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(),
      })
      const data = await response.json();
      setAcquirerId(data.response_data.acquirer_id)
      setAcqTransTypeMdr(data.response_data.acq_transtype_mdr)
      setTranstypeId(data.response_data.transtype_id)
      getHostTypeList();
      getTransTypeById(data.response_data.transtype_id);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const getTransTypeById = async (transtype_id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/transtype/${transtype_id}`, {
        method: 'GET', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(),
      })
      let data = await response.json();
      setTrasactionType(data.response_data.transtype_name);
      getTypesList(data.response_data.transtype_name, transtype_id);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const getHostTypeList = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/transtypes`, {
      method: 'GET', // or 'PUT'
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(),
    })
      .then(response => response.json())
      .then(data => {
        const responseData = data.response_data;
        setHostTypes(responseData);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const getTypesList = (hostType, transTypeId) => {
    const data = { transtype_name: hostType }
    fetch(`${process.env.REACT_APP_API_BASE_URL}/transtypes/list`, {
      method: 'POST', // or 'PUT'
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        setTypes(data.response_data);
        setTranstypeId(transTypeId);
        setAcqTransTypeMdr();
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  function UpdateAcquirerMdr() {
    setLoading(true);
    let body = {
      acquirer_id: parseInt(acquirerId),
      transtype_id: parseInt(transtypeId),
      acq_transtype_mdr: parseFloat(acqTransTypeMdr),
      acq_transtype_status: acqTransTypeStatus
    }
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/acqtranstype/update/${props.match.params.id}`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then(function (response) {
        history.push("/admin/acquirer");
      })
      .then(json => {
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    UpdateAcquirerMdr();
  }
  return (
    <>
      <SimpleHeader name="Edit Acquirer Mdr" parentName="Admin" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardBody>
                <Form onSubmit={handleSubmit} >
                  <input type="hidden" name="acquirer_id" value={acquirerId} />
                  <input type="hidden" name="acqTransTypeStatus" value={acqTransTypeStatus} />
                  <CardHeader>
                    <h3>Edit Acquirer Mdr</h3>
                  </CardHeader>
                  <CardBody>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="acquirer_host_type"
                      >
                        Host Type
                      </Label>
                      <Input
                        id="exampleFormControlSelect3"
                        disabled
                        name="transaction_type"
                        type="select"
                        value={transactionType}
                        onChange={(e) => {
                          setTrasactionType(e.target.value);
                          getTypesList(e.target.value);
                        }}
                      >
                        <option value={0}>Pilih Host Type</option>
                        {
                          hostTypes.map((hostType, key) => {
                            return <option key={key} value={hostType.transtype_name}>{hostType.transtype_name}</option>
                          })
                        }
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="encryption_type"
                      >
                        Card Type
                      </Label>
                      <Input
                        id="exampleFormControlSelect3"
                        disabled
                        name="transtype_id"
                        type="select"
                        value={transtypeId}
                        onChange={e => setTranstypeId(e.target.value)}
                      >
                        <option value={0}>Pilih Card Type</option>
                        {
                          types.map((type, key) => {
                            return <option key={key} value={type.transtype_id}>{type.transtype_clsf}</option>
                          })
                        }
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        MDR Value
                      </Label>
                      <Input
                        type="number"
                        name="acq_transtype_mdr"
                        placeholder="Masukan MDR Value"
                        value={acqTransTypeMdr}
                        onChange={e => setAcqTransTypeMdr(e.target.value)}
                      />
                    </FormGroup>
                  </CardBody>
                  <CardFooter>
                    {!isLoading && (<Button color="primary" type="submit">
                      Simpan
                    </Button>)}
                    {isLoading && (<Button color="primary" disabled>
                      <i className="fas fa-spinner fa-spin"></i>{""}
                      loading...
                    </Button>)}
                    <Link className="btn btn-info" to="/admin/acquirer">
                      Kembali
                    </Link>
                  </CardFooter>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
