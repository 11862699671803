/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  CardHeader,
} from "reactstrap";
import { Bar, Line } from "react-chartjs-2";
import Chart from "chart.js";
import {
  chartOptions,
  parseOptions,
  colors
} from "variables/charts.js";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import moment from 'moment';

export default function Dashboard() {
  const token = localStorage.getItem("token");
  const [transcationAmount, setTransactionAmount] = useState([]);
  const [transactionVolume, setTransactionVolume] = useState([]);

  useEffect(() => {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
    getAmount();
    getVolume();
  }, []);

  const getAmount = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/dashboard/transaction/amount`, {
        method: 'GET', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(),
      })
      const data = await response.json();
      let options = {
        labels: [],
        data: []
      };
      data.map((amount) => {
        options.data.push(amount.value)
        return options.labels.push(moment(amount.month, 'YYYY-MM').format("MMMM"))
      })
      setTransactionAmount({
        scales: {
          yAxes: [
            {
              gridLines: {
                color: colors.gray[200],
                zeroLineColor: colors.gray[200],
              },
              ticks: {
                callback: function (value) {
                  if (!(value % 10)) {
                    //return '$' + value + 'k'
                    return value;
                  }
                },
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            label: function (item, data) {
              var label = data.datasets[item.datasetIndex].label || "";
              var yLabel = item.yLabel;
              var content = "";
              if (data.datasets.length > 1) {
                content += label;
              }
              content += yLabel;
              return content;
            },
          },
        },
        data: {
          labels: options.labels,
          datasets: [
            {
              label: "Monthly Transaction Amount",
              data: options.data,
              maxBarThickness: 10,
            },
          ],
        },
      });
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const getVolume = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/dashboard/transaction/volume`, {
        method: 'GET', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(),
      })
      const data = await response.json();
      let options = {
        labels: [],
        data: []
      };
      data.map((amount) => {
        options.data.push(amount.value)
        return options.labels.push(moment(amount.month, 'YYYY-MM').format("MMMM"))
      })
      setTransactionVolume({
        options: {
          scales: {
            yAxes: [
              {
                gridLines: {
                  color: colors.gray[200],
                  zeroLineColor: colors.gray[200],
                },
                ticks: {},
              },
            ],
          },
        },
        data: {
          labels: options.labels,
          datasets: [
            {
              label: "Performance",
              data: options.data,
            },
          ],
        },
      });
    } catch (error) {
      console.error('Error:', error);
    }
  }

  return (
    <>
      <SimpleHeader name="Dashboard" parentName="Admin" />
      <Container className="mt--6" fluid>
        <h2>Amount By Transaction Type</h2>
        <Row md={12}>
          <Col md={6}>
            <Card>
              <CardHeader>
                <div className="col">
                  <h6 className="text-uppercase text-muted ls-1 mb-1">
                    Performance
              </h6>
                  <h5 className="h3 mb-0">Transaction Amount (IDR)</h5>
                </div>
              </CardHeader>
              <CardBody>
                <div className="chart">
                  <Bar
                    data={transcationAmount.data}
                    options={transcationAmount.options}
                    className="chart-canvas"
                    id="chart-bars"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md={6}>
            <Card>
              <CardHeader>
                <div className="col">
                  <h6 className="text-uppercase text-muted ls-1 mb-1">
                    Overview
                  </h6>
                  <h5 className="h3 mb-0">Transaction Value</h5>
                </div>
              </CardHeader>
              <CardBody>
                <div className="chart">
                  <Line
                    data={transactionVolume.data}
                    options={transactionVolume.options}
                    id="chart-sales"
                    className="chart-canvas"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
