/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Label,
  FormGroup,
  Row,
  Col,
  Input,
  UncontrolledTooltip,
  Container,
  CardHeader,
  ButtonGroup,
  Button,
  Form, Collapse
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import axios from 'axios';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

export default function Users() {
  const token = localStorage.token;
  const role = localStorage.getItem("authority");
  const merchantPk = localStorage.getItem("merchantPk");
  const projectId = localStorage.getItem("projectId");
  const userId = localStorage.getItem("userId");
  const [allUsers, setAllUsers] = useState([]);
  const [allUserProject, setAllUserProject] = useState([]);
  const [alert, setAlert] = React.useState(null);
  const [rowIndex, setRowIndex] = useState(0);
  const [merchants, setMerchants] = useState([]);
  const [merchantName, setMerchantName] = useState("");
  const [groupName, setGroupName] = useState("");
  const [projectName, setProjectName] = useState("");
  const [name, setName] = useState("");
  const [userName, setUserName] = useState("");
  const redirectPrefix = `/admin/user/edit/`;
  const allowedRolesProject = ["ROLE_OPERATOR_PROJECT", "ROLE_SUPERVISOR_PROJECT", "ROLE_ADMIN_PROJECT"];
  const allowedRolesAdmin = ["ROLE_SUPERADMIN"];
  const allowedGroup = ["ROLE_OPERATOR_GROUP", "ROLE_SUPERVISOR_GROUP", "ROLE_ADMIN_GROUP"];
  const allowedMerchant = ["ROLE_OPERATOR_MERCHANT", "ROLE_SUPERVISOR_MERCHANT", "ROLE_ADMIN_MERCHANT"];
  const allowedOperatorMerchant = ["ROLE_OPERATOR_MERCHANT"];
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const [page, setPage] = useState(1);
  const [perPage, setPerpage] = useState(10);
  const [totalItem, setTotalItem] = useState(0);
  const [currentSort, setCurrentSort] = useState("");

  let paginationOption = {
    page: page,
    alwaysShowAllBtns: true,
    override: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPage: perPage,
    totalSize: totalItem,
    onPageChange: (page) => {
      if (allowedRolesAdmin.includes(localStorage.getItem("authority"))) {
        updateDataTable(page, perPage, currentSort, groupName, merchantName, projectName, userName, name);
      }
      if (allowedRolesProject.includes(localStorage.getItem("authority"))) {
        updateTableProject(page, perPage, currentSort, groupName, merchantName, userName, name);
      }
      if (allowedGroup.includes(localStorage.getItem("authority"))) {
        updateTableGroup(page, perPage, currentSort, merchantName, userName, name);
      }
      if (allowedMerchant.includes(localStorage.getItem("authority"))) {
        updateTableMerchant(page, perPage, currentSort, userName, name);
      }
    },
    sizePerPageRenderer: () => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                if (allowedRolesAdmin.includes(localStorage.getItem("authority"))) {
                  updateDataTable(page, perPage, currentSort, groupName, merchantName, projectName, userName, name);
                }
                if (allowedRolesProject.includes(localStorage.getItem("authority"))) {
                  updateTableProject(page, perPage, currentSort, groupName, merchantName, projectName, userName, name);
                }
                if (allowedGroup.includes(localStorage.getItem("authority"))) {
                  updateTableGroup(page, perPage, currentSort, merchantName, userName, name);
                }
                if (allowedMerchant.includes(localStorage.getItem("authority"))) {
                  updateTableMerchant(page, perPage, currentSort, userName, name);
                }
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  }

  const updateDataTable = (page, perPage, sort, groupName, merchantName, projectName, userName, name) => {
    getAllUser(page, perPage, sort, groupName, merchantName, projectName, userName, name);
    setPage(page);
    setPerpage(perPage);
    setRowIndex((page - 1) * perPage);
    setCurrentSort(sort);
    setGroupName(groupName);
    setMerchantName(merchantName);
    setProjectName(projectName);
    setUserName(userName);
    setName(name);
  }

  const updateTableProject = (page, perPage, sort, groupName, merchantName, userName, name) => {
    getAllProject(page, perPage, sort, groupName, merchantName, userName, name);
    setPage(page);
    setPerpage(perPage);
    setRowIndex((page - 1) * perPage);
    setCurrentSort(sort);
    setGroupName(groupName);
    setMerchantName(merchantName);
    setUserName(userName);
    setName(name);
  }

  const updateTableGroup = (page, perPage, sort, merchantName, userName, name) => {
    getAllGroup(page, perPage, sort, merchantName, userName, name);
    setPage(page);
    setPerpage(perPage);
    setRowIndex((page - 1) * perPage);
    setCurrentSort(sort);
    setMerchantName(merchantName);
    setUserName(userName);
    setName(name);
  }

  const updateTableMerchant = (page, perPage, sort, userName, name) => {
    getAllMerchant(page, perPage, sort, userName, name);
    setPage(page);
    setPerpage(perPage);
    setRowIndex((page - 1) * perPage);
    setCurrentSort(sort);
    setUserName(userName);
    setName(name);
  }

  const handleTableChange = (type, { sortField, sortOrder }) => {
    if (type === "sort") {
      let sort = `${sortField} ${sortOrder}`
      updateDataTable(page, perPage, sort, groupName, merchantName, projectName, userName, name)
    }
  }

  useEffect(() => {
    if (allowedRolesAdmin.includes(localStorage.getItem("authority"))) {
      getAllUser(page, perPage, currentSort, "", "", "", "", "");
    }
    if (allowedRolesProject.includes(localStorage.getItem("authority"))) {
      getAllProject(page, perPage, currentSort, "", "", "", "");
    }
    if (allowedGroup.includes(localStorage.getItem("authority"))) {
      getAllGroup(page, perPage, currentSort, "", "", "");
    }
    if (allowedMerchant.includes(localStorage.getItem("authority"))) {
      getAllMerchant(page, perPage, currentSort, "", "")
    }
    getMerchants();
  }, []);

  const getAllUser = (page, perPage, currentSort, group_name = null, merchant_name = null, project_name = null, username = null, name = null) => {
    let filter = { page: page, per_page: perPage, role: role, userId: parseInt(userId), merchantPk: parseInt(merchantPk) }
    if (project_name !== null) {
      filter = Object.assign(filter, { project_name: project_name })
    }
    if (group_name !== null) {
      filter = Object.assign(filter, { group_name: group_name })
    }
    if (merchant_name !== null) {
      filter = Object.assign(filter, { merchant_name: merchant_name })
    }
    if (username !== null) {
      filter = Object.assign(filter, { username: username })
    }
    if (name !== null) {
      filter = Object.assign(filter, { name: name })
    }
    const data = filter;
    fetch(`${process.env.REACT_APP_API_BASE_URL}/users`, {
      method: 'POST', // or 'PUT'
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        setAllUsers(data.response_data);
        setPage(data.current_page);
        setPerpage(data.per_page);
        setTotalItem(data.total_item);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const getAllProject = (page, perPage, currentSort, group_name = null, merchant_name = null, username = null, name = null) => {
    let filter = { page: page, per_page: perPage, role: role, projectId: parseInt(merchantPk), merchantPk: parseInt(merchantPk) }
    if (group_name !== null) {
      filter = Object.assign(filter, { group_name: group_name })
    }
    if (merchant_name !== null) {
      filter = Object.assign(filter, { merchant_name: merchant_name })
    }
    if (username !== null) {
      filter = Object.assign(filter, { username: username })
    }
    if (name !== null) {
      filter = Object.assign(filter, { name: name })
    }
    const data = filter;
    fetch(`${process.env.REACT_APP_API_BASE_URL}/users`, {
      method: 'POST', // or 'PUT'
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        setAllUserProject(data.response_data);
        setPage(data.current_page);
        setPerpage(data.per_page);
        setTotalItem(data.total_item);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const getAllGroup = (page, perPage, currentSort, merchant_name = null, username = null, name = null) => {
    let filter = { page: page, per_page: perPage, role: role, projectId: parseInt(projectId), groupId: parseInt(merchantPk), merchantPk: parseInt(merchantPk) }
    if (merchant_name !== 0) {
      filter = Object.assign(filter, { merchant_name: merchant_name })
    }
    if (username !== null) {
      filter = Object.assign(filter, { username: username })
    }
    if (name !== null) {
      filter = Object.assign(filter, { name: name })
    }
    const data = filter;
    fetch(`${process.env.REACT_APP_API_BASE_URL}/users`, {
      method: 'POST', // or 'PUT'
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        setAllUserProject(data.response_data);
        setPage(data.current_page);
        setPerpage(data.per_page);
        setTotalItem(data.total_item);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const getAllMerchant = (page, perPage, currentSort, username = null, name = null) => {
    let filter = { page: page, per_page: perPage, role: role, merchantPk: parseInt(merchantPk) }
    if (username !== null) {
      filter = Object.assign(filter, { username: username })
    }
    if (name !== null) {
      filter = Object.assign(filter, { name: name })
    }
    const data = filter;
    fetch(`${process.env.REACT_APP_API_BASE_URL}/users`, {
      method: 'POST', // or 'PUT'
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        setAllUserProject(data.response_data);
        setPage(data.current_page);
        setPerpage(data.per_page);
        setTotalItem(data.total_item);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const getMerchants = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/user/list`, {
      method: 'GET', // or 'PUT'
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(),
    })
      .then(response => response.json())
      .then(data => {
        setMerchants(data.response_data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const delateUser = (userId) => {
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/user/delete/${userId}`, null, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    })
      .then(function (response) {
        setSuccessAlert();
        getAllUser();
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const setSuccessAlert = () => {
    setAlert(
      <SweetAlert
        success
        showConfirm
        confirmBtnText="Ok"
        title="User deleted"
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    )
  }

  const setQuestionAlert = (userId) => {
    setAlert(
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={() => delateUser(userId)}
        onCancel={hideAlert}
        focusCancelBtn
      />
    )
  }

  const hideAlert = () => {
    setAlert(null);
  }

  const reset = () => {
    setProjectName("");
    setMerchantName("");
    setGroupName("");
    setUserName("");
    setName("");
    getAllUser();
    getAllProject();
  }

  return (
    <>
      {alert}
      <SimpleHeader name="User" parentName="Admin" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <h3>List User</h3>
                  {
                    allowedOperatorMerchant.includes(localStorage.getItem("authority"))
                      ?
                      <div></div>
                      :
                      <div style={{ textAlign: "right" }}>
                        <Link className="btn btn-info" to="/admin/user/create">
                          Add
                        </Link>
                      </div>
                  }
                </div>
              </CardHeader>
              <CardBody>
                <h3 onClick={toggle} >Filter &nbsp;
                  {
                    isOpen === true ? <i className="fa fa-angle-down" aria-hidden="true"></i> : <i className="fa fa-angle-right" aria-hidden="true"></i>
                  }
                </h3>
                <Collapse isOpen={isOpen}>
                  {
                    allowedRolesAdmin.includes(localStorage.getItem("authority")) ?
                      <Form>
                        <Row md="12">
                          <Col md="4">
                            <FormGroup>
                              <Label
                                className="form-control-label"
                                htmlFor="exampleFormControlInput1"
                              >
                                Nama Project
                            </Label>
                              <Input
                                type="text"
                                placeholder="Masukan Nama Group"
                                value={projectName}
                                onChange={(e) => {
                                  updateDataTable(page, perPage, currentSort, groupName, merchantName, e.target.value)
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label
                                className="form-control-label"
                                htmlFor="exampleFormControlInput1"
                              >
                                Nama group
                            </Label>
                              <Input
                                type="text"
                                placeholder="Masukan Nama Group"
                                value={groupName}
                                onChange={(e) => {
                                  updateDataTable(page, perPage, currentSort, e.target.value)
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label
                                className="form-control-label"
                                htmlFor="exampleFormControlInput1"
                              >
                                Nama Merchant
                            </Label>
                              <Input
                                type="text"
                                placeholder="Masukan Nama Merchant"
                                value={merchantName}
                                onChange={(e) => {
                                  updateDataTable(page, perPage, currentSort, groupName, e.target.value)
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="4">
                            <FormGroup>
                              <Label
                                className="form-control-label"
                                htmlFor="exampleFormControlInput1"
                              >
                                User Name
                              </Label>
                              <Input
                                type="text"
                                placeholder="Masukan User Name"
                                value={userName}
                                onChange={(e) => {
                                  updateDataTable(page, perPage, currentSort, groupName, merchantName, projectName, e.target.value)
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label
                                className="form-control-label"
                                htmlFor="exampleFormControlInput1"
                              >
                                Name
                              </Label>
                              <Input
                                type="text"
                                placeholder="Masukan Name"
                                value={name}
                                onChange={(e) => {
                                  updateDataTable(page, perPage, currentSort, groupName, merchantName, projectName, userName, e.target.value)
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Button type="button" onClick={reset} color="secondary">Reset</Button>
                          </Col>
                        </Row>
                      </Form>
                      :
                      allowedMerchant.includes(localStorage.getItem("authority"))
                        ?
                        <Form>
                          <Row md="12">
                            <Col md="4">
                              <FormGroup>
                                <Label
                                  className="form-control-label"
                                  htmlFor="exampleFormControlInput1"
                                >
                                  User Name
                            </Label>
                                <Input
                                  type="text"
                                  placeholder="Masukan User Name"
                                  value={userName}
                                  onChange={(e) => {
                                    updateTableMerchant(page, perPage, currentSort, e.target.value)
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              <FormGroup>
                                <Label
                                  className="form-control-label"
                                  htmlFor="exampleFormControlInput1"
                                >
                                  Name
                            </Label>
                                <Input
                                  type="text"
                                  placeholder="Masukan Name"
                                  value={name}
                                  onChange={(e) => {
                                    updateTableMerchant(page, perPage, currentSort, userName, e.target.value)
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Button type="button" onClick={reset} color="secondary">Reset</Button>
                            </Col>
                          </Row>
                        </Form>
                        :
                        allowedGroup.includes(localStorage.getItem("authority"))
                          ?
                          <Form>
                            <Row md="12">
                              <Col md="4">
                                <FormGroup>
                                  <Label
                                    className="form-control-label"
                                    htmlFor="exampleFormControlInput1"
                                  >
                                    Nama Merchant
                            </Label>
                                  <Input
                                    type="text"
                                    placeholder="Masukan Nama Merchant"
                                    value={merchantName}
                                    onChange={(e) => {
                                      updateTableGroup(page, perPage, currentSort, e.target.value)
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="4">
                                <FormGroup>
                                  <Label
                                    className="form-control-label"
                                    htmlFor="exampleFormControlInput1"
                                  >
                                    User Name
                              </Label>
                                  <Input
                                    type="text"
                                    placeholder="Masukan User Name"
                                    value={userName}
                                    onChange={(e) => {
                                      updateTableGroup(page, perPage, currentSort, merchantName, e.target.value)
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="4">
                                <FormGroup>
                                  <Label
                                    className="form-control-label"
                                    htmlFor="exampleFormControlInput1"
                                  >
                                    Name
                              </Label>
                                  <Input
                                    type="text"
                                    placeholder="Masukan Name"
                                    value={name}
                                    onChange={(e) => {
                                      updateTableGroup(page, perPage, currentSort, merchantName, userName, e.target.value)
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Button type="button" onClick={reset} color="secondary">Reset</Button>
                              </Col>
                            </Row>
                          </Form>
                          :
                          <Form>
                            <Row md="12">
                              <Col md="3">
                                <FormGroup>
                                  <Label
                                    className="form-control-label"
                                    htmlFor="exampleFormControlInput1"
                                  >
                                    Nama group
                            </Label>
                                  <Input
                                    type="text"
                                    placeholder="Masukan Nama Group"
                                    value={groupName}
                                    onChange={(e) => {
                                      updateTableProject(page, perPage, currentSort, e.target.value)
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="3">
                                <FormGroup>
                                  <Label
                                    className="form-control-label"
                                    htmlFor="exampleFormControlInput1"
                                  >
                                    Nama Merchant
                            </Label>
                                  <Input
                                    type="text"
                                    placeholder="Masukan Nama Merchant"
                                    value={merchantName}
                                    onChange={(e) => {
                                      updateTableProject(page, perPage, currentSort, groupName, e.target.value)
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="3">
                                <FormGroup>
                                  <Label
                                    className="form-control-label"
                                    htmlFor="exampleFormControlInput1"
                                  >
                                    User Name
                              </Label>
                                  <Input
                                    type="text"
                                    placeholder="Masukan User Name"
                                    value={userName}
                                    onChange={(e) => {
                                      updateTableProject(page, perPage, currentSort, groupName, merchantName, e.target.value)
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="3">
                                <FormGroup>
                                  <Label
                                    className="form-control-label"
                                    htmlFor="exampleFormControlInput1"
                                  >
                                    Name
                              </Label>
                                  <Input
                                    type="text"
                                    placeholder="Masukan Name"
                                    value={name}
                                    onChange={(e) => {
                                      updateTableProject(page, perPage, currentSort, groupName, merchantName, userName, e.target.value)
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Button type="button" onClick={reset} color="secondary">Reset</Button>
                              </Col>
                            </Row>
                          </Form>
                  }
                </Collapse>
                {
                  allowedRolesAdmin.includes(localStorage.getItem("authority"))
                    ?
                    <ToolkitProvider
                      rowNumber={rowIndex}
                      data={allUsers}
                      keyField="name"
                      columns={[
                        {
                          dataField: "no",
                          text: "#",
                          sort: true,
                          page: 1,
                          formatter: (cell, row, index) => {
                            let currentRow = ++index;
                            return currentRow + rowIndex;
                          }
                        },
                        {
                          dataField: "username",
                          text: "User Name",
                          sort: true,
                        },
                        {
                          dataField: "name",
                          text: "Nama",
                          sort: true,
                        },
                        {
                          dataField: "email",
                          text: "Email",
                          sort: true,
                        },
                        {
                          dataField: "merchant_name",
                          text: "Nama Customer",
                          sort: true,
                        },
                        {
                          dataField: "role",
                          text: "Roles",
                          sort: true,
                        },
                        {
                          dataField: "",
                          text: "",
                          formatter: (cell, row, index) => {
                            if (row.userId === parseInt(userId)) {
                              return (
                                <div></div>
                              )
                            } else {

                              return (
                                <ButtonGroup>
                                  <Button>
                                    <Link
                                      to={redirectPrefix + row.userId}
                                      id={"tooltip_" + row.userId}
                                    >
                                      <i className="fas fa-user-edit" />
                                    </Link>
                                  </Button>
                                  <UncontrolledTooltip delay={0} target={"tooltip_" + row.userId}>
                                    Edit
                                    </UncontrolledTooltip>
                                  <Button
                                    id="btn-delete-bank"
                                    onClick={() => setQuestionAlert(row.userId)}
                                  >
                                    <i className="fas fa-trash" />
                                  </Button>
                                  <UncontrolledTooltip
                                    delay={0}
                                    placement="top"
                                    target="btn-delete-bank"
                                  >
                                    Delete User
                                    </UncontrolledTooltip>
                                </ButtonGroup>
                              )
                            }
                          }
                        },
                      ]}
                    >
                      {(props) => (
                        <div className="py-4 table-responsive">
                          <BootstrapTable
                            remote
                            {...props.baseProps}
                            bootstrap4={true}
                            bordered={false}
                            pagination={paginationFactory({ ...paginationOption })}
                            onTableChange={handleTableChange}
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                    :
                    allowedOperatorMerchant.includes(localStorage.getItem("authority"))
                      ?
                      <ToolkitProvider
                        rowNumber={rowIndex}
                        data={allUserProject}
                        keyField="name"
                        columns={[
                          {
                            dataField: "no",
                            text: "#",
                            sort: true,
                            page: 1,
                            formatter: (cell, row, index) => {
                              let currentRow = ++index;
                              return currentRow + rowIndex;
                            }
                          },
                          {
                            dataField: "username",
                            text: "User Name",
                            sort: true,
                          },
                          {
                            dataField: "name",
                            text: "Nama",
                            sort: true,
                          },
                          {
                            dataField: "email",
                            text: "Email",
                            sort: true,
                          },
                          {
                            dataField: "merchant_name",
                            text: "Nama Merchant",
                            sort: true,
                          },
                          {
                            dataField: "role",
                            text: "Roles",
                            sort: true,
                          }
                        ]}
                      >
                        {(props) => (
                          <div className="py-4 table-responsive">
                            <BootstrapTable
                              remote
                              {...props.baseProps}
                              bootstrap4={true}
                              bordered={false}
                              pagination={paginationFactory({ ...paginationOption })}
                              onTableChange={handleTableChange}
                            />
                          </div>
                        )}
                      </ToolkitProvider>
                      :
                      <ToolkitProvider
                        rowNumber={rowIndex}
                        data={allUserProject}
                        keyField="name"
                        columns={[
                          {
                            dataField: "no",
                            text: "#",
                            sort: true,
                            page: 1,
                            formatter: (cell, row, index) => {
                              let currentRow = ++index;
                              return currentRow + rowIndex;
                            }
                          },
                          {
                            dataField: "username",
                            text: "User Name",
                            sort: true,
                          },
                          {
                            dataField: "name",
                            text: "Nama",
                            sort: true,
                          },
                          {
                            dataField: "email",
                            text: "Email",
                            sort: true,
                          },
                          {
                            dataField: "merchant_name",
                            text: "Nama Merchant",
                            sort: true,
                          },
                          {
                            dataField: "role",
                            text: "Roles",
                            sort: true,
                          },
                          {
                            dataField: "",
                            text: "",
                            formatter: (cell, row, index) => {
                              if (row.userId === parseInt(userId)) {
                                return (
                                  <div></div>
                                )
                              } else {

                                return (
                                  <ButtonGroup>
                                    <Button>
                                      <Link
                                        to={redirectPrefix + row.userId}
                                        id={"tooltip_" + row.userId}
                                      >
                                        <i className="fas fa-user-edit" />
                                      </Link>
                                    </Button>
                                    <UncontrolledTooltip delay={0} target={"tooltip_" + row.userId}>
                                      Edit
                                    </UncontrolledTooltip>
                                    <Button
                                      id="btn-delete-bank"
                                      onClick={() => setQuestionAlert(row.userId)}
                                    >
                                      <i className="fas fa-trash" />
                                    </Button>
                                    <UncontrolledTooltip
                                      delay={0}
                                      placement="top"
                                      target="btn-delete-bank"
                                    >
                                      Delete User
                                    </UncontrolledTooltip>
                                  </ButtonGroup>
                                )
                              }
                            }
                          },
                        ]}
                      >
                        {(props) => (
                          <div className="py-4 table-responsive">
                            <BootstrapTable
                              remote
                              {...props.baseProps}
                              bootstrap4={true}
                              bordered={false}
                              pagination={paginationFactory({ ...paginationOption })}
                              onTableChange={handleTableChange}
                            />
                          </div>
                        )}
                      </ToolkitProvider>
                }
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
