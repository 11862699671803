/*eslint-disable*/
import React, { createRef, useEffect, useState } from 'react';
import { Card, Button, Row, CardBody, CardHeader, Container, FormGroup, Col, Input, Label, Collapse, Form } from 'reactstrap';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import axios from 'axios';
import moment from 'moment';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import SweetAlert from "react-bootstrap-sweetalert";

const HistoryLainnya = () => {
  const token = localStorage.token;
  const [alert, setAlert] = React.useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [rowIndex, setRowIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [isOpenSync, setIsOpenSync] = useState(false);
  const toggleSync = () => setIsOpenSync(!isOpenSync);
  const [allHistory, setAllHistory] = useState([]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [mid, setMid] = useState("");
  const [tid, setTid] = useState("");
  const [workUnit, setWorkUnit] = useState("");
  const [method, setMethod] = useState("");

  const allowedMerchant = ["ROLE_OPERATOR_MERCHANT", "ROLE_SUPERVISOR_MERCHANT", "ROLE_ADMIN_MERCHANT"];

  const _export = createRef(null);

  const formatRupiah = (money) => {
    return new Intl.NumberFormat('id-ID',
      { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
    ).format(money);
  }

  useEffect(() => {
    getHistory();
  }, []);

  const getHistory = () => {
    let params = {};
    if (mid) {
      params['mid'] = mid
    }
    if (mid) {
      params['tid'] = tid
    }
    if (method) {
      params['method'] = method
    }
    if (startDate) {
      params['startDate'] = moment(startDate).format("yyyy-MM-DD")
    }
    if (endDate) {
      params['endDate'] = moment(endDate).format("yyyy-MM-DD")
    }
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/dsl/histories?methods=1000&methods=1302&methods=1303&methods=1305&methods=1306`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params
    })
      .then((response) => {
        setAllHistory(response.data);
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getSync = () => {
    let data = {
      startDate: moment(startDate).format("yyyy-MM-DD"),
      endDate: moment(endDate).format("yyyy-MM-DD")
    }
    fetch(`${process.env.REACT_APP_API_BASE_URL}/dsl/sync`, {
      method: 'POST', // or 'PUT'
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setSuccessAlert();
      })
      .catch((error) => {
        console.log("cek error", error);
        console.error('Error:', error);
      });
  }
  const setSuccessAlert = () => {
    setAlert(
      <SweetAlert
        success
        showConfirm
        confirmBtnText="Ok"
        title="Sinkronisasi Sukses"
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    )
  }

  const hideAlert = () => {
    setAlert(null);
  }

  let paginationOption = {
    page: 1,
    alwaysShowAllBtns: true,
    override: true,
    showTotal: true,
    withFirstAndLast: false,
    onPageChange: (page) => {
      calculateRowIndex(page, currentPageSize);
    },
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                onSizePerPageChange(e.target.value);
                setCurrentPageSize(e.target.value);
                calculateRowIndex(currentPage, e.target.value);
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          }{" "}
      entries.
    </label>
      </div>
    ),
  }

  const calculateRowIndex = (currentPage, currentPageSize) => {
    setRowIndex((currentPage - 1) * currentPageSize);
  }

  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    getHistory();
  }

  const reset = () => {
    setStartDate("");
    setEndDate("");
    setMid("");
    setTid("");
    setMethod("");
    getHistory();
  }

  return (
    <div>
      {alert}
      <SimpleHeader name="Transaksi Lainnya" parentName="Admin" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <h3>List Transaksi Lainnya</h3>
                  <Button color="primary" onClick={excelExport}>Export</Button>
                  <ExcelExport
                    data={allHistory}
                    fileName="History.xlsx"
                    ref={_export}
                    filterable={true}
                  >
                    <ExcelExportColumn field="item.tid" title="TID" />
                    <ExcelExportColumn field="item.mid" title="MID" />
                    <ExcelExportColumn field="item.amount" title="Amount" />
                    <ExcelExportColumn field="item.datetime" title="Tanggal" />
                    <ExcelExportColumn field="item.method" title="Type" />
                  </ExcelExport>
                </div>
              </CardHeader>
              <CardBody>
                <h3 onClick={toggleSync} >Sinkronisasi &nbsp;
                  {
                    isOpenSync === true ? <i className="fa fa-angle-down" aria-hidden="true"></i> : <i className="fa fa-angle-right" aria-hidden="true"></i>
                  }
                </h3>
                <Collapse isOpen={isOpenSync}>
                  <Form>
                    <Row md="12">
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="exampleFormControlSelect3">Start Date</Label>
                          <Input

                            id="example-date-input"
                            type="date"
                            value={startDate}
                            onChange={(e) => {
                              setStartDate(e.target.value);
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="exampleFormControlSelect3">End Date</Label>
                          <Input

                            id="example-date-input"
                            type="date"
                            value={endDate}
                            onChange={(e) => {
                              setEndDate(e.target.value);
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {
                          startDate !== "" && endDate !== "" ?
                            <Button type="button" color="primary" onClick={getSync}>Sinkronisasi</Button>
                            :
                            <Button disabled type="button" color="primary">Sinkronisasi</Button>
                        }
                      </Col>
                    </Row>
                  </Form>
                </Collapse>
                <h3 onClick={toggle} >Filter &nbsp;
                  {
                    isOpen === true ? <i className="fa fa-angle-down" aria-hidden="true"></i> : <i className="fa fa-angle-right" aria-hidden="true"></i>
                  }
                </h3>
                <Collapse isOpen={isOpen}>
                  {
                    allowedMerchant.includes(localStorage.getItem("authority"))
                      ?
                      <Form>
                        <Row md="12">
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect3">Start Date</Label>
                              <Input

                                id="example-date-input"
                                type="date"
                                value={startDate}
                                onChange={(e) => {
                                  setStartDate(e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect3">TID</Label>
                              <Input
                                type="text"
                                placeholder="Masukan TID"
                                value={tid}
                                onChange={(e) => {
                                  setTid(e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect3">Type</Label>
                              <Input
                                id="exampleFormControlSelect3"
                                name="method"
                                type="select"
                                value={method}
                                onChange={e => setMethod(e.target.value)}
                              >
                                <option value="">Pilih Type</option>
                                <option value="1000">Login</option>
                                <option value="1302">Reversal Sale</option>
                                <option value="1303">Reversal Void</option>
                                <option value="1305">Batch Upload</option>
                                <option value="1306">Last Transaction</option>
                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row md="12">
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect3">End Date</Label>
                              <Input

                                id="example-date-input"
                                type="date"
                                value={endDate}
                                onChange={(e) => {
                                  setEndDate(e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect3">Mid</Label>
                              <Input
                                type="text"
                                placeholder="Masukan MID"
                                value={mid}
                                onChange={(e) => {
                                  setMid(e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Button type="submit" color="primary" onClick={handleSubmit}>Search</Button>
                            <Button type="button" color="secondary" onClick={reset}>Reset</Button>
                          </Col>
                        </Row>
                      </Form>
                      :
                      <Form>
                        <Row md="12">
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect3">Start Date</Label>
                              <Input

                                id="example-date-input"
                                type="date"
                                value={startDate}
                                onChange={(e) => {
                                  setStartDate(e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect3">TID</Label>
                              <Input
                                type="text"
                                placeholder="Masukan TID"
                                value={tid}
                                onChange={(e) => {
                                  setTid(e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect3">Work Unit</Label>
                              <Input
                                type="text"
                                placeholder="Masukan Work Unit"
                                value={workUnit}
                                onChange={(e) => {
                                  setWorkUnit(e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row md="12">
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect3">End Date</Label>
                              <Input
                                id="example-date-input"
                                type="date"
                                value={endDate}
                                onChange={(e) => {
                                  setEndDate(e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect3">Mid</Label>
                              <Input
                                type="text"
                                placeholder="Masukan MID"
                                value={mid}
                                onChange={(e) => {
                                  setMid(e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect3">Type</Label>
                              <Input
                                id="exampleFormControlSelect3"
                                name="method"
                                type="select"
                                value={method}
                                onChange={e => setMethod(e.target.value)}
                              >
                                <option value="">Pilih Type</option>
                                <option value="1000">Login</option>
                                <option value="1302">Reversal Sale</option>
                                <option value="1303">Reversal Void</option>
                                <option value="1305">Batch Upload</option>
                                <option value="1306">Last Transaction</option>
                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Button type="submit" color="primary" onClick={handleSubmit}>Search</Button>
                            <Button type="button" color="secondary" onClick={reset}>Reset</Button>
                          </Col>
                        </Row>
                      </Form>
                  }
                </Collapse>
                <ToolkitProvider
                  rowNumber={rowIndex}
                  data={allHistory}
                  keyField="id"
                  columns={[
                    {
                      dataField: "no",
                      text: "#",
                      sort: true,
                      page: 1,
                      formatter: (cell, row, index) => {
                        let currentRow = ++index;
                        return currentRow + rowIndex;
                      }
                    },
                    {
                      dataField: "item.tid",
                      text: "TID",
                      sort: true,
                    },
                    {
                      dataField: "item.mid",
                      text: "MID",
                      sort: true,
                    },
                    {
                      dataField: "item.tcode",
                      text: "Type",
                      sort: true,
                    },
                    {
                      dataField: "item.amount",
                      text: "Amount",
                      sort: true,
                      formatter: (cell, row, index) => {
                        return formatRupiah(row.item.amount)
                      }
                    },
                    {
                      dataField: "item.datetime",
                      text: "Tanggal",
                      sort: true,
                    },
                    {
                      dataField: "item.method",
                      text: "Method",
                      sort: true,
                    },
                  ]}
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      <BootstrapTable
                        {...props.baseProps}
                        bootstrap4={true}
                        bordered={false}
                        hover={true}
                        pagination={paginationFactory({ ...paginationOption })}
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  );
}

export default HistoryLainnya;
