import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Container,
  Row,
  Col,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";

export default function PricingPlan() {
  return (
    <>
      <SimpleHeader name="Pricing Plan" parentName="Admin" />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card>
                <CardHeader>
                  <h2>Pricing Plan</h2>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="4">
                      <Card className="card-pricing border-0 text-center mb-4">
                          <CardHeader style={{ background: "#F6C745", borderBottom: "10px solid #000000" }}>
                            <h2 className="text-uppercase ls-1 text-white py-3 mb-0">
                              Basic
                            </h2>
                          </CardHeader>
                          <CardBody className="px-lg-7">
                            <div className="display-2">$Free</div>
                            <span className="text-muted">per application</span>
                            <ul className="list-unstyled my-4">
                              <li>
                                <div className="d-flex align-items-center">
                                  <div>
                                    <div className="icon icon-xs icon-shape bg-gradient-info shadow rounded-circle text-white">
                                    </div>
                                  </div>
                                  <div>
                                    <span className="pl-2">Laporan Standar</span>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="d-flex align-items-center">
                                  <div>
                                    <div className="icon icon-xs icon-shape bg-gradient-info shadow rounded-circle text-white">
                                    </div>
                                  </div>
                                  <div>
                                    <span className="pl-2">
                                      Aplikasi Payment
                                    </span>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="d-flex align-items-center">
                                  <div>
                                    <div>
                                    </div>
                                  </div>
                                  <div>
                                    <span className="pl-2">
                                    </span>
                                  </div>
                                </div>
                              </li>
                            </ul>
                            <Button className="mb-3" color="info" type="button">
                              Start free trial
                            </Button>
                          </CardBody>
                          <CardFooter>
                            <a
                              className="text-light"
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              Request a demo
                            </a>
                          </CardFooter>
                        </Card>
                    </Col>
                    <Col md="4">
                      <Card className="card-pricing border-0 text-center mb-4">
                          <CardHeader style={{ background: "#460E7B", borderBottom: "10px solid #000000"}}>
                            <h2 className="text-uppercase ls-1 text-white py-3 mb-0">
                              Menengah
                            </h2>
                          </CardHeader>
                          <CardBody className="px-lg-7">
                            <div className="display-2">$25</div>
                            <span className="text-muted">per application</span>
                            <ul className="list-unstyled my-4">
                              <li>
                                <div className="d-flex align-items-center">
                                  <div>
                                    <div className="icon icon-xs icon-shape bg-gradient-info shadow rounded-circle text-white">
                                    </div>
                                  </div>
                                  <div>
                                    <span className="pl-2">Credit Scoring</span>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="d-flex align-items-center">
                                  <div>
                                    <div className="icon icon-xs icon-shape bg-gradient-info shadow rounded-circle text-white">
                                    </div>
                                  </div>
                                  <div>
                                    <span className="pl-2">
                                      Installment
                                    </span>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="d-flex align-items-center">
                                  <div>
                                    <div className="icon icon-xs icon-shape bg-gradient-info shadow rounded-circle text-white">
                                    </div>
                                  </div>
                                  <div>
                                    <span className="pl-2">
                                      Loyalty
                                    </span>
                                  </div>
                                </div>
                              </li>
                            </ul>
                            <Button className="mb-3" color="info" type="button">
                              Start free trial
                            </Button>
                          </CardBody>
                          <CardFooter>
                            <a
                              className="text-light"
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              Request a demo
                            </a>
                          </CardFooter>
                        </Card>
                    </Col>
                    <Col md="4">
                      <Card className="card-pricing border-0 text-center mb-4">
                          <CardHeader style={{ background: "#DF336B", borderBottom: "10px solid #000000"}}>
                            <h2 className="text-uppercase ls-1 text-white py-3 mb-0">
                              Penuh
                            </h2>
                          </CardHeader>
                          <CardBody className="px-lg-7">
                            <div className="display-2">$49</div>
                            <span className="text-muted">per application</span>
                            <ul className="list-unstyled my-4">
                              <li>
                                <div className="d-flex align-items-center">
                                  <div>
                                    <div className="icon icon-xs icon-shape bg-gradient-info shadow rounded-circle text-white">
                                    </div>
                                  </div>
                                  <div>
                                    <span className="pl-2">PPOB</span>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="d-flex align-items-center">
                                  <div>
                                    <div className="icon icon-xs icon-shape bg-gradient-info shadow rounded-circle text-white">
                                    </div>
                                  </div>
                                  <div>
                                    <span className="pl-2">
                                      Cash Register
                                    </span>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="d-flex align-items-center">
                                  <div>
                                    <div className="icon icon-xs icon-shape bg-gradient-info shadow rounded-circle text-white">
                                    </div>
                                  </div>
                                  <div>
                                    <span className="pl-2">
                                      Inventory
                                    </span>
                                  </div>
                                </div>
                              </li>
                            </ul>
                            <Button className="mb-3" color="info" type="button">
                              Start free trial
                            </Button>
                          </CardBody>
                          <CardFooter>
                            <a
                              className="text-light"
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              Request a demo
                            </a>
                          </CardFooter>
                        </Card>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
    </>
  );
}
