/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, Row, Col, CardBody, CardHeader, Container, UncontrolledTooltip, FormGroup, Label, Input, Form } from 'reactstrap';
import classnames from 'classnames';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

import MerchantMdr from "views/pages/customers/merchant/merchant_mdr/Index.js";
import MerchantAcquirer from "views/pages/customers/merchant/merchant_dokumen/Index.js";
import MerchantTerminal from "views/pages/customers/merchant/merchant_terminal/Index.js";

export default function Merchant(props) {
  const token = localStorage.token;
  const role = localStorage.getItem("authority");
  const merchantLevel = parseInt(localStorage.getItem("merchantLevel"));
  const projectId = parseInt(localStorage.getItem("projectId"));
  const merchantPk = parseInt(localStorage.getItem("merchantPk"));
  const groupId = parseInt(localStorage.getItem("groupId"));
  const [allMerchant, setAllMerchant] = useState([]);
  const [allMerchantByGroupId, setAllMerchantByGroupId] = useState([]);
  const [allMerchantByProject, setAllMerchantByProject] = useState([]);
  const [alert, setAlert] = React.useState(null);
  const redirectPrefix = `/admin/customers/daftar-merchant/edit/`;
  const [hide, setHide] = useState(true);
  const [selectMerchantPk, setSelectedMerchantPk] = useState(0);
  const [selectMerchantName, setSelectMerchantName] = useState("");
  const [activeTab, setActiveTab] = useState('1');
  const [merchants, setMerchants] = useState([]);
  const [merchant, setMerchant] = useState("");
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("");
  const [listStatus, setListStatus] = useState([])
  const [status, setStatus] = useState("");
  const [rowIndex, setRowIndex] = useState(0);
  const allowedRoles = ["ROLE_OPERATOR_BUSDEV", "ROLE_BOD", "ROLE_OPERATOR_FINANCE", "ROLE_SUPERVISOR_FINANCE"];
  const allowedRolesProject = ["ROLE_OPERATOR_PROJECT", "ROLE_SUPERVISOR_PROJECT", "ROLE_ADMIN_PROJECT"];
  const allowedRolesGroup = ["ROLE_OPERATOR_GROUP", "ROLE_SUPERVISOR_GROUP", "ROLE_ADMIN_GROUP"];
  const allowedAdmin = ["ROLE_SUPERADMIN"];

  const [page, setPage] = useState(1);
  const [perPage, setPerpage] = useState(10);
  const [totalItem, setTotalItem] = useState(0);
  const [currentSort, setCurrentSort] = useState("");

  useEffect(() => {
    // component did mount
    if (allowedAdmin.includes(localStorage.getItem("authority"))) {
      getAllMerchant(page, perPage, currentSort, "", "", "");
    } else if (allowedRolesProject.includes(localStorage.getItem("authority"))) {
      getAllMerchantByProjectId(page, perPage, "", "", "");
    } else {
      getAllMerchantByGroupId(page, perPage, "", "", "");
    }
    getMerchants();
    getCategories();
    getStatus();
    return () => {
      // cleanup
    }
  }, [])

  let paginationOption = {
    page: page,
    alwaysShowAllBtns: true,
    override: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPage: perPage,
    totalSize: totalItem,
    onPageChange: (page) => {
      updateDataTable(page, perPage, currentSort, category, merchant, status);
    },
    sizePerPageRenderer: () => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                updateDataTable(page, e.target.value, currentSort, category, merchant, status)
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  }

  const updateDataTable = (page, perPage, sort, category, merchant, status) => {
    getAllMerchant(page, perPage, sort, parseInt(category), parseInt(merchant), parseInt(status));
    getAllMerchantByGroupId(page, perPage, sort, parseInt(category), parseInt(merchant), parseInt(status));
    getAllMerchantByProjectId(page, perPage, sort, parseInt(category), parseInt(merchant), parseInt(status));
    setPage(page);
    setPerpage(perPage);
    setRowIndex((page - 1) * perPage);
    setCurrentSort(sort);
    setCategory(category);
    setMerchant(merchant);
    setStatus(status);
  }

  const handleTableChange = (type, { sortField, sortOrder }) => {
    if (type === "sort") {
      let sort = `${sortField} ${sortOrder}`
      updateDataTable(page, perPage, sort, category, merchant, status)
    }
  }

  const getAllMerchant = (page, perPage, currentSort, merchant_category_id = 0, merchant_pk = 0, merchant_status = 0) => {
    const token = localStorage.token;
    let filter = { page: page, per_page: perPage }
    if (merchant_category_id !== 0) {
      filter = Object.assign(filter, { merchant_category_id: merchant_category_id })
    }
    if (merchant_pk !== 0) {
      filter = Object.assign(filter, { merchant_pk: merchant_pk })
    }
    if (merchant_status !== 0) {
      filter = Object.assign(filter, { merchant_status: merchant_status })
    }
    const data = filter;
    fetch(`${process.env.REACT_APP_API_BASE_URL}/merchants`, {
      method: 'POST', // or 'PUT'
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        setAllMerchant(data.response_data);
        setPage(data.current_page);
        setPerpage(data.per_page);
        setTotalItem(data.total_item);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const getAllMerchantByGroupId = (page, perPage, currentSort, merchant_category_id = 0, merchant_pk = 0, merchant_status = 0) => {
    const token = localStorage.token;
    let filter = { page: page, per_page: perPage, role: role, projectId: projectId, groupId: merchantPk }
    if (merchant_category_id !== 0) {
      filter = Object.assign(filter, { merchant_category_id: merchant_category_id })
    }
    if (merchant_pk !== 0) {
      filter = Object.assign(filter, { merchant_pk: merchant_pk })
    }
    if (merchant_status !== 0) {
      filter = Object.assign(filter, { merchant_status: merchant_status })
    }
    const data = filter;
    fetch(`${process.env.REACT_APP_API_BASE_URL}/merchants`, {
      method: 'POST', // or 'PUT'
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        setAllMerchantByGroupId(data.response_data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const getAllMerchantByProjectId = (page, perPage, currentSort, merchant_category_id = 0, merchant_pk = 0, merchant_status = 0) => {
    const token = localStorage.token;
    let filter = { page: page, per_page: perPage, role: role, projectId: merchantPk }
    if (merchant_category_id !== 0) {
      filter = Object.assign(filter, { merchant_category_id: merchant_category_id })
    }
    if (merchant_pk !== 0) {
      filter = Object.assign(filter, { merchant_pk: merchant_pk })
    }
    if (merchant_status !== 0) {
      filter = Object.assign(filter, { merchant_status: merchant_status })
    }
    const data = filter;
    fetch(`${process.env.REACT_APP_API_BASE_URL}/merchants`, {
      method: 'POST', // or 'PUT'
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        setAllMerchantByProject(data.response_data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const getMerchants = () => {
    if (allowedAdmin.includes(localStorage.getItem("authority"))) {
      let data = {
        role: role
      }
      fetch(`${process.env.REACT_APP_API_BASE_URL}/merchants/list`, {
        method: 'POST', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(response => response.json())
        .then(data => {
          setMerchants(data.response_data);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
    if (allowedRolesProject.includes(localStorage.getItem("authority"))) {
      let data = { role: role, project_id: parseInt(merchantPk) }
      fetch(`${process.env.REACT_APP_API_BASE_URL}/merchants/list`, {
        method: 'POST', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(response => response.json())
        .then(data => {
          setMerchants(data.response_data);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
    if (allowedRolesGroup.includes(localStorage.getItem("authority"))) {
      let data = { role: role, project_id: parseInt(projectId), group_id: parseInt(merchantPk) }
      fetch(`${process.env.REACT_APP_API_BASE_URL}/merchants/list`, {
        method: 'POST', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(response => response.json())
        .then(data => {
          setMerchants(data.response_data);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  }

  const getCategories = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/merchantCategories`, {
        method: 'GET', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(),
      })
      const data = await response.json();
      setCategories(data.response_data);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const getStatus = () => {
    const data = { type: "merchant" }
    fetch(`${process.env.REACT_APP_API_BASE_URL}/status`, {
      method: 'POST', // or 'PUT'
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        setListStatus(data.response_data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }

  const rowEvents = {
    onDoubleClick: (e, row, rowIndex) => {
      setHide(false);
      setSelectedMerchantPk(row.merchant_pk);
      setSelectMerchantName(row.merchant_name);
    }
  };

  const reset = () => {
    setMerchant("");
    setCategory("");
    setStatus("");
    getAllMerchant();
    getAllMerchantByProjectId();
    getAllMerchantByGroupId();
  }

  return (
    <>
      {alert}
      <SimpleHeader name="Merchant" parentName="Admin" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <h3>Daftar Merchant</h3>
                  {
                    allowedRoles.includes(localStorage.getItem("authority"))
                      ?
                      <div style={{ textAlign: "right" }}>
                        <Link className="btn btn-info" to="/admin/customers/daftar-merchant/create">
                          Add
                        </Link>
                      </div>
                      :
                      allowedAdmin.includes(localStorage.getItem("authority"))
                        ?
                        <div style={{ textAlign: "right" }}>
                          <Link className="btn btn-info" to="/admin/customers/daftar-merchant/create">
                            Add
                        </Link>
                        </div>
                        :
                        <div></div>
                  }
                </div>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row md="12">
                    <Col md="4">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect3">Nama Merchant</Label>
                        <Input
                          id="exampleFormControlSelect3"
                          name="merchant"
                          type="select"
                          value={merchant}
                          onChange={e => updateDataTable(page, perPage, currentSort, category, e.target.value)}
                        >
                          <option value="">Pilih Nama Merchant</option>
                          {
                            merchants.map((merchant, key) => {
                              return <option key={key} value={merchant.merchant_pk}>{merchant.merchant_name}</option>
                            })
                          }
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect3">Kategori Merchant</Label>
                        <Input
                          id="exampleFormControlSelect3"
                          name="category"
                          type="select"
                          value={category}
                          onChange={e => updateDataTable(page, perPage, currentSort, e.target.value)}
                        >
                          <option value="">Pilih Kategori Merchant</option>
                          {
                            categories.map((category, key) => {
                              return <option key={key} value={category.merchantCategoryId}>{category.merchantCategoryName}</option>
                            })
                          }
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect3">Status Merchant</Label>
                        <Input
                          id="exampleFormControlSelect3"
                          name="projects"
                          type="select"
                          value={status}
                          onChange={e => updateDataTable(page, perPage, currentSort, category, merchant, e.target.value)}
                        >
                          <option value="">Pilih Status Merchant</option>
                          {
                            listStatus.map((stat, key) => {
                              return <option key={key} value={stat.value}>{stat.description}</option>
                            })
                          }
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button type="button" onClick={reset} color="secondary">Reset</Button>
                    </Col>
                  </Row>
                </Form>
                {
                  allowedRolesProject.includes(localStorage.getItem("authority"))
                    ?
                    <ToolkitProvider
                      rowNumber={rowIndex}
                      data={allMerchantByProject}
                      keyField="merchant_pk"
                      columns={[
                        {
                          dataField: "no",
                          text: "#",
                          sort: true,
                          page: 1,
                          formatter: (cell, row, index) => {
                            let currentRow = ++index;
                            return currentRow + rowIndex;
                          }
                        },
                        {
                          dataField: "merchant_code",
                          text: "Merchant Code",
                          sort: true,
                        },
                        {
                          dataField: "groupName",
                          text: "Nama Group",
                          sort: true,
                        },
                        {
                          dataField: "merchant_name",
                          text: "Nama Merchant",
                          sort: true,
                        },
                        {
                          dataField: "merchant_category",
                          text: "Kategori Merchant",
                          sort: true,
                        },
                        {
                          dataField: "merchant_address",
                          text: "Alamat",
                          sort: true,
                        },
                        {
                          dataField: "terminal_count",
                          text: "Jumlah Edc",
                          sort: true,
                        },
                        {
                          dataField: "merchant_status",
                          text: "Status Merchant",
                          sort: true,
                          formatter: (cell, row, index) => {
                            return row.merchant_status === 1 ? "Active" : row.merchant_status === 2 ? "Draft" : "DeActive"
                          }
                        }
                      ]}
                    >
                      {(props) => (
                        <div className="py-4 table-responsive">
                          <BootstrapTable
                            remote
                            {...props.baseProps}
                            bootstrap4={true}
                            bordered={false}
                            hover={true}
                            rowEvents={rowEvents}
                            pagination={paginationFactory({ ...paginationOption })}
                            onTableChange={handleTableChange}
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                    : allowedRolesGroup.includes(localStorage.getItem("authority"))
                      ?
                      <ToolkitProvider
                        rowNumber={rowIndex}
                        data={allMerchantByGroupId}
                        keyField="merchant_pk"
                        columns={[
                          {
                            dataField: "no",
                            text: "#",
                            sort: true,
                            page: 1,
                            formatter: (cell, row, index) => {
                              let currentRow = ++index;
                              return currentRow + rowIndex;
                            }
                          },
                          {
                            dataField: "merchant_code",
                            text: "Merchant Code",
                            sort: true,
                          },
                          {
                            dataField: "merchant_name",
                            text: "Nama Merchant",
                            sort: true,
                          },
                          {
                            dataField: "merchant_category",
                            text: "Kategori Merchant",
                            sort: true,
                          },
                          {
                            dataField: "merchant_address",
                            text: "Alamat",
                            sort: true,
                          },
                          {
                            dataField: "terminal_count",
                            text: "Jumlah Edc",
                            sort: true,
                          },
                          {
                            dataField: "merchant_status",
                            text: "Status Merchant",
                            sort: true,
                            formatter: (cell, row, index) => {
                              return row.merchant_status === 1 ? "Active" : row.merchant_status === 2 ? "Draft" : "DeActive"
                            }
                          }
                        ]}
                      >
                        {(props) => (
                          <div className="py-4 table-responsive">
                            <BootstrapTable
                              remote
                              {...props.baseProps}
                              bootstrap4={true}
                              bordered={false}
                              hover={true}
                              rowEvents={rowEvents}
                              pagination={paginationFactory({ ...paginationOption })}
                              onTableChange={handleTableChange}
                            />
                          </div>
                        )}
                      </ToolkitProvider>
                      :
                      <ToolkitProvider
                        rowNumber={rowIndex}
                        data={allMerchant}
                        keyField="merchant_pk"
                        columns={[
                          {
                            dataField: "no",
                            text: "#",
                            sort: true,
                            page: 1,
                            formatter: (cell, row, index) => {
                              let currentRow = ++index;
                              return currentRow + rowIndex;
                            }
                          },
                          {
                            dataField: "merchant_code",
                            text: "Merchant Code",
                            sort: true,
                          },
                          {
                            dataField: "projectName",
                            text: "Nama Project/Group",
                            sort: true,
                            formatter: (cell, row) => {
                              return (
                                <React.Fragment>
                                  {row.projectName} <br />
                                  {row.groupName}
                                </React.Fragment>
                              )
                            }
                          },
                          {
                            dataField: "merchant_name",
                            text: "Nama Merchant",
                            sort: true,
                          },
                          {
                            dataField: "merchant_category",
                            text: "Kategori Merchant",
                            sort: true,
                          },
                          {
                            dataField: "merchant_address",
                            text: "Alamat",
                            sort: true,
                          },
                          {
                            dataField: "terminal_count",
                            text: "Jumlah Edc",
                            sort: true,
                          },
                          {
                            dataField: "merchant_status",
                            text: "Status Merchant",
                            sort: true,
                            formatter: (cell, row, index) => {
                              return row.merchant_status === 1 ? "Active" : row.merchant_status === 2 ? "Draft" : "DeActive"
                            }
                          },
                          {
                            dataField: "", text: "", formatter: (cell, row, index) => {
                              return (
                                <>
                                  <Link
                                    className="btn btn-primary btn-sm"
                                    to={redirectPrefix + row.merchant_pk}
                                    id={"tooltip_" + row.merchant_pk}
                                  >
                                    <i className="fas fa-pencil-alt" />
                                  </Link>
                                  <UncontrolledTooltip delay={0} target={"tooltip_" + row.merchant_pk}>
                                    Edit
                            </UncontrolledTooltip>
                                </>
                              )
                            }
                          }
                        ]}
                      >
                        {(props) => (
                          <div className="py-4 table-responsive">
                            <BootstrapTable
                              remote
                              {...props.baseProps}
                              bootstrap4={true}
                              bordered={false}
                              hover={true}
                              rowEvents={rowEvents}
                              pagination={paginationFactory({ ...paginationOption })}
                              onTableChange={handleTableChange}
                            />
                          </div>
                        )}
                      </ToolkitProvider>
                }
              </CardBody>
            </Card>
            <Card>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '1' })}
                    onClick={() => { toggle('1'); }}
                  >
                    Merchant MDR
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '2' })}
                    onClick={() => { toggle('2'); }}
                  >
                    Berkas Dokumen
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '3' })}
                    onClick={() => { toggle('3'); }}
                  >
                    Terminal
                  </NavLink>
                </NavItem>
              </Nav>
              {hide ? <></> : <>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <Row>
                      <Col md="12">
                        <MerchantMdr merchantPk={selectMerchantPk} merchantName={selectMerchantName} />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row>
                      <Col md="12">
                        <MerchantAcquirer merchantPk={selectMerchantPk} merchantName={selectMerchantName} />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="3">
                    <Row>
                      <Col md="12">
                        <MerchantTerminal merchantPk={selectMerchantPk} merchantName={selectMerchantName} />
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </>
              }
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
