/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Label,
  FormGroup,
  Row,
  Input,
  Container,
  CardHeader,
  CardFooter,
  Button,
  Form, FormFeedback
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import axios from 'axios';
import SimpleHeader from "components/Headers/SimpleHeader.js";

export default function CreateMerchantGroupMdr(props) {
  const token = localStorage.getItem("token");
  const [hostTypes, setHostTypes] = useState([]);
  const [types, setTypes] = useState([]);
  let history = useHistory();

  const merchantPk = props.match.params.id;
  const [hostType, setHostType] = useState("");
  const [hostTypeError, setHostTypeError] = useState(null);
  const [cardType, setCardType] = useState("");
  const [cardTypeError, setCardTypeError] = useState(null);
  const [cardTypeDuplicate, setCardTypeDuplicate] = useState(null);
  const [mdrValue, setMdrValue] = useState("");
  const [mdrValueError, setMdrValueError] = useState(null);
  const [mdrFee, setMdrFee] = useState("");
  const [mdrFeeError, setMdrFeeError] = useState(null);

  const validateForm = () => {
    let error = false;
    if (hostType === "") {
      setHostTypeError("invalid");
      error = true;
    }
    if (cardType === "") {
      setCardTypeError("invalid");
      error = true;
    }
    if (mdrValue === "") {
      setMdrValueError("invalid");
      error = true;
    }
    if (mdrFee === "") {
      setMdrFeeError("invalid");
      error = true;
    }
    return error;
  };

  useEffect(() => {
    getHostType();
    getTypesList();
  }, []);

  const getHostType = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/transtypes`, {
      method: 'GET', // or 'PUT'
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(),
    })
      .then(response => response.json())
      .then(data => {
        setHostTypes(data.response_data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const getTypesList = (hostType) => {
    const data = { transtype_name: hostType }
    fetch(`${process.env.REACT_APP_API_BASE_URL}/transtypes/list`, {
      method: 'POST', // or 'PUT'
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        setTypes(data.response_data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  function CreateGroupMdr() {
    let data = {
      merchant_pk: parseInt(merchantPk),
      transtype_id: parseInt(cardType),
      merchant_mdr_val: parseFloat(mdrValue),
      merchant_mdr_fee: parseFloat(mdrFee),
      merchant_mdr_status: 1
    }
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/merchantmdr/save`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then(function (response) {
        if (response.data.error) {
          showErrors(response.data.error);
          return;
        }
        history.push("/admin/customers/daftar-group");
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const showErrors = (error) => {
    let errors = false;
    if (error.error_message) {
      setCardTypeError("invalid");
      setCardTypeDuplicate(error.error_message);
      errors = true;
    }
    return errors;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      CreateGroupMdr();
    }
  }
  return (
    <>
      <SimpleHeader name="Daftar Merchant Group Mdr" parentName="Admin" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardBody>
                <Form onSubmit={handleSubmit} >
                  <CardHeader>
                    <h3>Daftar Merchant Group MDR</h3>
                  </CardHeader>
                  <CardBody>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="acquirer_host_type"
                      >
                        Host Type
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        id="exampleFormControlSelect3"
                        name="transaction_type"
                        type="select"
                        value={hostType}
                        invalid={hostTypeError === "invalid"}
                        onChange={(e) => {
                          setHostType(e.target.value);
                          if (e.target.value !== "") {
                            setHostTypeError("");
                          }
                          getTypesList(e.target.value);
                        }}
                      >
                        <option value="">Pilih Host Type</option>
                        {
                          hostTypes.map((hostType, key) => {
                            return <option key={key} value={hostType.transtype_name}>{hostType.transtype_name}</option>
                          })
                        }
                      </Input>
                      <FormFeedback>Host type tidak boleh kosong</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="encryption_type"
                      >
                        Card Type
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        id="exampleFormControlSelect3"
                        name="transtype_id"
                        type="select"
                        value={cardType}
                        invalid={cardTypeError === "invalid"}
                        onChange={(e) => {
                          setCardType(e.target.value);
                          if (e.target.value !== "") {
                            setCardTypeError("");
                          }
                        }}
                      >
                        <option value="">Pilih Card Type</option>
                        {
                          types.map((type, key) => {
                            return <option key={key} value={type.transtype_id}>{type.transtype_clsf}</option>
                          })
                        }
                      </Input>
                      <FormFeedback>
                        {
                          cardType === "" ? "Card Type tidak boleh kosong" : cardTypeDuplicate
                        }
                      </FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        MDR Value (%)
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="number"
                        name="merchant_mdr_val"
                        placeholder="Masukan MDR Value"
                        value={mdrValue}
                        invalid={mdrValueError === "invalid"}
                        onChange={(e) => {
                          setMdrValue(e.target.value);
                          if (e.target.value !== "") {
                            setMdrValueError("");
                          }
                        }}
                      />
                      <FormFeedback>Mdr Value tidak boleh kosong</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        MDR Fee (Rp)
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="number"
                        name="merchant_mdr_fee"
                        placeholder="Masukan MDR Fee"
                        value={mdrFee}
                        invalid={mdrFeeError === "invalid"}
                        onChange={(e) => {
                          setMdrFee(e.target.value);
                          if (e.target.value !== "") {
                            setMdrFeeError("");
                          }
                        }}
                      />
                      <FormFeedback>Mdr Fee tidak boleh kosong</FormFeedback>
                    </FormGroup>
                  </CardBody>
                  <CardFooter>
                    <Button color="primary" type="submit">
                      Simpan
                                        </Button>
                    <Link class="btn btn-info" to="/admin/customers/daftar-group">
                      Kembali
                                        </Link>
                  </CardFooter>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
