/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

export default function TerminalReceipt(props) {
  const role = localStorage.getItem("authority");
  const merchantPk = parseInt(localStorage.getItem("merchantPk"));
  const projectId = parseInt(localStorage.getItem("projectId"));
  const token = localStorage.token;
  const terminalPk = props.terminalPk;
  const [terminalReceipt, setTerminalReceipt] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [rowIndex, setRowIndex] = useState(0);
  const allowedRoles = ["ROLE_SUPERADMIN"];
  const allowedProject = ["ROLE_OPERATOR_PROJECT", "ROLE_SUPERVISOR_PROJECT", "ROLE_ADMIN_PROJECT"];
  const allowedGroup = ["ROLE_OPERATOR_GROUP", "ROLE_SUPERVISOR_GROUP", "ROLE_ADMIN_GROUP"];
  const allowedMerchant = ["ROLE_OPERATOR_MERCHANT", "ROLE_SUPERVISOR_MERCHANT", "ROLE_ADMIN_MERCHANT"];

  let paginationOption = {
    page: 1,
    alwaysShowAllBtns: true,
    override: true,
    showTotal: true,
    withFirstAndLast: false,
    onPageChange: (page) => {
      calculateRowIndex(page, currentPageSize);
    },
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                onSizePerPageChange(e.target.value);
                setCurrentPageSize(e.target.value);
                calculateRowIndex(currentPage, e.target.value);
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          }{" "}
      entries.
    </label>
      </div>
    ),
  }

  const calculateRowIndex = (currentPage, currentPageSize) => {
    setRowIndex((currentPage - 1) * currentPageSize);
  }

  useEffect(() => {
    getTerminalReceipt();
  }, [props.terminalPk]);

  const getTerminalReceipt = () => {
    if (allowedRoles.includes(localStorage.getItem("authority"))) {
      const data = { page: 1, per_page: 100, terminal_id: terminalPk, role: role };
      fetch(`${process.env.REACT_APP_API_BASE_URL}/terminals`, {
        method: 'POST', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(response => response.json())
        .then(data => {
          setTerminalReceipt(data.response_data);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
    if (allowedProject.includes(localStorage.getItem("authority"))) {
      const data = { page: 1, per_page: 100, terminal_id: terminalPk, role: role, projectId: merchantPk, merchantPk: merchantPk };
      fetch(`${process.env.REACT_APP_API_BASE_URL}/terminals`, {
        method: 'POST', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(response => response.json())
        .then(data => {
          setTerminalReceipt(data.response_data);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
    if (allowedGroup.includes(localStorage.getItem("authority"))) {
      const data = { page: 1, per_page: 100, terminal_id: terminalPk, role: role, projectId: projectId, groupId: merchantPk, merchantPk: merchantPk };
      fetch(`${process.env.REACT_APP_API_BASE_URL}/terminals`, {
        method: 'POST', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(response => response.json())
        .then(data => {
          setTerminalReceipt(data.response_data);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
    if (allowedMerchant.includes(localStorage.getItem("authority"))) {
      const data = { page: 1, per_page: 100, terminal_id: terminalPk, role: role, merchantPk: merchantPk };
      fetch(`${process.env.REACT_APP_API_BASE_URL}/terminals`, {
        method: 'POST', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(response => response.json())
        .then(data => {
          setTerminalReceipt(data.response_data);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  }

  return (
    <>
      <Card>
        <CardHeader>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h3>List Terminal Receipt</h3>
          </div>
        </CardHeader>
        <CardBody>
          <ToolkitProvider
            rowNumber={rowIndex}
            data={terminalReceipt}
            keyField="terminal_pk"
            columns={[
              {
                dataField: "no",
                text: "#",
                sort: true,
                page: 1,
                formatter: (cell, row, index) => {
                  let currentRow = ++index;
                  return currentRow + rowIndex;
                }
              },
              {
                dataField: "merchantName_edc",
                text: "Nama Merchant Edc",
                sort: true,
              },
              {
                dataField: "merchantAddress1",
                text: "Alamat 1",
                sort: true,
              },
              {
                dataField: "merchantAddress2",
                text: "Alamat 2",
                sort: true,
              },
              {
                dataField: "merchantAddress3",
                text: "Alamat 3",
                sort: true,
              },
            ]}
          >
            {(props) => (
              <div className="py-4 table-responsive">
                <BootstrapTable
                  {...props.baseProps}
                  bootstrap4={true}
                  bordered={false}
                  pagination={paginationFactory({ ...paginationOption })}
                />
              </div>
            )}
          </ToolkitProvider>
        </CardBody>
      </Card>
    </>
  );
}
