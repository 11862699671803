/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Label,
  FormGroup,
  Row,
  Input,
  Container,
  CardHeader,
  CardFooter,
  Button,
  Form
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import axios from 'axios';
import moment from 'moment';
import SimpleHeader from "components/Headers/SimpleHeader.js";

export default function EditAcquirer(props) {
  const token = localStorage.getItem("token");
  const [merchantPk, setMerchantPk] = useState("");
  const [acquirerId, setAcquirerId] = useState("");
  const [subbmited, setSubbmited] = useState("");
  const [acquirers, setAcquirers] = useState([]);
  let history = useHistory();

  useEffect(() => {
    getAcquirerById();
  }, []);

  const getAcquirerById = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/mcacquirer/${props.match.params.id}`, {
        method: 'GET', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(),
      })
      const data = await response.json();
      setMerchantPk(data.response_data.merchant_pk);
      getAcquirer(data.response_data.acquirer_id);
      setAcquirerId(data.response_data.acquirer_id);
      setSubbmited(moment(data.response_data.submitted, "DD/MM/YYYY").format("YYYY-MM-DD"));
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const getAcquirer = () => {
    const data = { page: 1, per_page: 100 };
    fetch(`${process.env.REACT_APP_API_BASE_URL}/acquirers`, {
      method: 'POST', // or 'PUT'
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        setAcquirers(data.response_data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  function UpdateData() {
    let data = {
      merchant_pk: parseInt(merchantPk),
      acquirer_id: parseInt(acquirerId),
      submitted: moment(subbmited).format("DD/MM/yyyy")
    }
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/mcacquirer/update/${props.match.params.id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then(function (response) {
        history.push("/admin/customers/daftar-project");
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    UpdateData();
  }
  return (
    <>
      <SimpleHeader name="Edit Berkas Dokumen" parentName="Admin" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardBody>
                <Form onSubmit={handleSubmit} >
                  <CardHeader>
                    <h3>Edit Berkas Dokumen</h3>
                  </CardHeader>
                  <CardBody>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="serial_number"
                      >
                        Acquirer Name
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        disabled
                        id="exampleFormControlSelect3"
                        name="transaction_type"
                        type="select"
                        value={acquirerId}
                        onChange={e => setAcquirerId(e.target.value)}
                      >
                        <option value={0}>Pilih Acquirer Name</option>
                        {
                          acquirers.map((acquirer, key) => {
                            return <option key={key} value={acquirer.acquirer_id}>{acquirer.acquirer_name}</option>
                          })
                        }
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        Subbmited
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        defaultValue={new Date().getFullYear() + "-11-23"}
                        id="example-date-input"
                        type="date"
                        value={subbmited}
                        onChange={(e) => {
                          setSubbmited(e.target.value);
                        }}
                      />
                    </FormGroup>
                  </CardBody>
                  <CardFooter>
                    <Button color="primary" type="submit">
                      Simpan
                                        </Button>
                    <Link class="btn btn-info" to="/admin/customers/daftar-project">
                      Kembali
                                        </Link>
                  </CardFooter>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
