import React, { useState } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { AppContext } from "libs/context.js";

import AdminLayout from "layouts/Admin.js";
import CustomersLayout from "layouts/Customer.js";
import AuthLayout from "layouts/Auth.js";

import { ToastContainer } from "react-toastify";
import moment from "moment";
moment.defaultFormat = "YYYY-MM-DD HH:mm:ss";

function App() {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [userName, setUserName] = useState("");
  const [token, setToken] = useState("");
  const [userSession, setUserSession] = React.useState(null);

  return (
    <AppContext.Provider
      value={{
        isAuthenticated,
        userHasAuthenticated,
        userName,
        setUserName,
        token,
        setToken,
        userSession,
        setUserSession
      }}
    >
      <ToastContainer />
      <BrowserRouter>
        <Switch>
          <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
          <Route path="/customers" render={(props) => <CustomersLayout {...props} />} />
          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          <Redirect from="/" to="/admin/dashboard" />
        </Switch>
      </BrowserRouter>
    </AppContext.Provider>
  )

}

export default App;