/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Label,
  FormGroup,
  Row,
  Input,
  Container,
  CardHeader,
  CardFooter,
  Button,
  Form, Col, FormFeedback
} from "reactstrap";
import md5 from 'js-md5';
import { TripleDes } from "data-crypto";
import { Link, useHistory } from "react-router-dom";
import axios from 'axios';
import SimpleHeader from "components/Headers/SimpleHeader.js";

function ascii_to_hexa(str) {
  var arr1 = [];
  for (var n = 0, l = str.length; n < l; n++) {
    var hex = Number(str.charCodeAt(n)).toString(16);
    arr1.push(hex);
  }
  return arr1.join('');
}

export default function CreateUser() {
  const token = localStorage.token;
  const projectId = parseInt(localStorage.getItem("projectId"));
  const merchantId = parseInt(localStorage.getItem("merchantPk"));
  const userRole = localStorage.getItem("authority");
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(null);
  const [userName, setUserName] = useState("");
  const [userNameError, setUserNameError] = useState(null);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(null);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(null);
  const [address, setAddress] = useState("");
  const [gender, setGender] = useState(1);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [status, setStatus] = useState(1);
  const [role, setRole] = useState("");
  const [roleError, setRoleError] = useState(null);
  const [roles, setRoles] = useState([]);
  const [merchantPk, setMerchantPk] = useState("");
  const [merchantError, setMerchantError] = useState(null);
  const [merchants, setMerchants] = useState([]);
  let history = useHistory();
  const allowedRolesMerchant = ["ROLE_OPERATOR_MERCHANT", "ROLE_SUPERVISOR_MERCHANT", "ROLE_ADMIN_MERCHANT"];
  const allowedSuperAdmin = ["ROLE_SUPERADMIN"];
  const [isLoading, setLoading] = useState(false);
  const timestamp = Date.now();
  const [emailReady, setEmailReady] = useState(null);
  const [usernameReady, setUsernameReady] = useState(null);
  const [unitKerja, setUnitKerja] = useState("");
  const [unitKerjaError, setUnitKerjaError] = useState(null);
  const [isSelectUnitKerja, setSelecUnitKerja] = useState(2);
  const [listUnitKerja, setListUnitKerja] = useState([]);
  const [roleId, setRoleId] = useState("");
  const allowedRolesAdmin = ["ROLE_SUPERADMIN"];

  const validateForm = () => {
    if (allowedRolesMerchant.includes(localStorage.getItem("authority"))) {
      let error = false;
      if (name === "") {
        setNameError("invalid");
        error = true;
      }
      if (userName === "") {
        setUserNameError("invalid");
        error = true;
      }
      if (email === "") {
        setEmailError("invalid");
        error = true;
      }
      if (password === "") {
        setPasswordError("invalid");
        error = true;
      }
      if (role === 0) {
        setRoleError("invalid");
        error = true;
      }
      return error;
    } else {
      let error = false;
      if (name === "") {
        setNameError("invalid");
        error = true;
      }
      if (userName === "") {
        setUserNameError("invalid");
        error = true;
      }
      if (email === "") {
        setEmailError("invalid");
        error = true;
      }
      if (password === "") {
        setPasswordError("invalid");
        error = true;
      }
      if (merchantPk === "") {
        setMerchantError("invalid");
        error = true;
      }
      if (isSelectUnitKerja === 1) {
        if (unitKerja === "") {
          setUnitKerjaError("invalid");
          error = true;
        }
      }
      if (role === 0) {
        setRoleError("invalid");
        error = true;
      }
      return error;
    }
  };

  useEffect(() => {
    getRoles();
    getUniKerja();
  }, []);

  const getRoles = () => {
    const data = { role: userRole };
    fetch(`${process.env.REACT_APP_API_BASE_URL}/role/list`, {
      method: 'POST', // or 'PUT'
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        setRoles(data.response_data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const getMerchant = (roleId) => {
    setRoleId(roleId);
    if (allowedRolesAdmin.includes(localStorage.getItem("authority"))) {
      const data = { role: userRole, role_id: parseInt(roleId) };
      fetch(`${process.env.REACT_APP_API_BASE_URL}/listmerchant`, {
        method: 'POST', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(response => response.json())
        .then(data => {
          setMerchants(data.response_data);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    } else {
      const data = { role: userRole, role_id: parseInt(roleId), merchantPk: parseInt(merchantId) };
      fetch(`${process.env.REACT_APP_API_BASE_URL}/listmerchant`, {
        method: 'POST', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(response => response.json())
        .then(data => {
          setMerchants(data.response_data);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  }

  const getUniKerja = async (merchantPk) => {
    const body = {
      merchantPk: parseInt(merchantPk)
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/workunit/list`, {
        method: 'POST', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
      const data = await response.json();
      setListUnitKerja(data.response_data);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  function Padding(passwordWithLanght) {
    let strToPad = passwordWithLanght;
    let strLength = strToPad.length;
    let nearestMultipler = Math.ceil(strLength / 8) * 8;
    return strToPad.padEnd(nearestMultipler, "0");
  }

  function CreateUser() {
    const key = userName + timestamp;
    const md5Key = md5(key);
    const passwordLenght = password.length;
    let passwordWithLanght;
    if (passwordLenght < 10) {
      passwordWithLanght = "0" + passwordLenght + password;
    } else {
      passwordWithLanght = passwordLenght + password;
    }
    let paddingPassword = Padding(passwordWithLanght);
    let passwordHex = ascii_to_hexa(paddingPassword);
    const tripleDesPassword = TripleDes.encrypt(passwordHex, md5Key);
    if (allowedRolesMerchant.includes(localStorage.getItem("authority"))) {
      setLoading(true);
      let body = {
        name: name,
        username: userName,
        email: email,
        gender: parseInt(gender),
        phone_number: phoneNumber,
        password: tripleDesPassword,
        address: address,
        merchantPk: merchantId,
        user_status: parseInt(status),
        role: [parseInt(role)],
        workUnitPk: parseInt(unitKerja),
        isWorkUnit: parseInt(isSelectUnitKerja),
        timestamp: timestamp.toString()
      }
      axios.post(`${process.env.REACT_APP_API_BASE_URL}/register`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
        .then(function (response) {
          if (response.data.errors) {
            showErrors(response.data.errors);
            return;
          }
          history.push("/admin/user");
        })
        .then(json => {
          setLoading(false);
        })
        .catch(function (error) {
          console.log(error)
        })
    } else {
      setLoading(true);
      let body = {
        name: name,
        username: userName,
        email: email,
        gender: parseInt(gender),
        phone_number: phoneNumber,
        password: tripleDesPassword,
        address: address,
        merchantPk: parseInt(merchantPk),
        user_status: parseInt(status),
        role: [parseInt(role)],
        workUnitPk: parseInt(unitKerja),
        isWorkUnit: parseInt(isSelectUnitKerja),
        timestamp: timestamp.toString()
      }
      axios.post(`${process.env.REACT_APP_API_BASE_URL}/register`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
        .then(function (response) {
          if (response.data.errors) {
            showErrors(response.data.errors);
            return;
          }
          history.push("/admin/user");
        })
        .then(json => {
          setLoading(false);
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }

  const showErrors = (error) => {
    let errors = false;
    if (error.email) {
      setEmailError("invalid");
      setEmailReady(error.email);
      errors = true;
    }
    if (error.username) {
      setUserNameError("invalid");
      setUsernameReady(error.username);
      errors = true;
    }
    return errors;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      CreateUser();
    }
  }
  return (
    <>
      <SimpleHeader name="Daftar User" parentName="Admin" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardBody>
                <Form onSubmit={handleSubmit}>
                  <CardHeader>
                    <h3>Daftar User</h3>
                  </CardHeader>
                  <CardBody>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="serial_number"
                      >
                        Nama
                      </Label>
                      <Input
                        type="text"
                        name="name"
                        placeholder="Masukan Name"
                        value={name}
                        invalid={nameError === "invalid"}
                        onChange={(e) => {
                          setName(e.target.value);
                          if (e.target.value !== "") {
                            setNameError("");
                          }
                        }}
                      />
                      <FormFeedback>Name tidak boleh kosong</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        User Name
                      </Label>
                      <Input
                        type="text"
                        name="user_name"
                        placeholder="Masukan User Name"
                        value={userName}
                        invalid={userNameError === "invalid"}
                        onChange={(e) => {
                          setUserName(e.target.value);
                          if (e.target.value !== "") {
                            setUserNameError("");
                          }
                        }}
                      />
                      <FormFeedback>
                        {
                          userName === "" ? "Username tidak boleh kosong" : usernameReady
                        }
                      </FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        Email
                      </Label>
                      <Input
                        type="email"
                        name="email"
                        placeholder="Masukan Email"
                        value={email}
                        invalid={emailError === "invalid"}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          if (e.target.value !== "") {
                            setEmailError("");
                          }
                        }}
                      />
                      <FormFeedback>
                        {
                          email === "" ? "Email tidak boleh kosong" : emailReady
                        }
                      </FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        Password
                      </Label>
                      <Input
                        type="password"
                        name="password"
                        placeholder="Masukan Password"
                        value={password}
                        invalid={passwordError === "invalid"}
                        onChange={(e) => {
                          setPassword(e.target.value);
                          if (e.target.value !== "") {
                            setPasswordError("");
                          }
                        }}
                      />
                      <FormFeedback>Password tidak boleh kosong</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        Address
                      </Label>
                      <Input
                        type="text"
                        name="address"
                        placeholder="Masukan Address"
                        value={address}
                        onChange={e => setAddress(e.target.value)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlSelect3"
                      >
                        Jenis Kelamin
                                    </Label>
                      <Row>
                        <Col>
                          <div style={{ display: "flex" }}>
                            <div className="custom-control custom-radio mb-3">
                              <Input
                                className="custom-control-input"
                                id="customRadio5"
                                name="custom-radio-2"
                                type="radio"
                                value={1}
                                checked={gender === 1}
                                onClick={() => setGender(1)}
                              />
                              <Label
                                className="custom-control-label"
                                htmlFor="customRadio5"
                              >
                                Pria
                                </Label>
                            </div>
                            <div className="custom-control custom-radio mb-3" style={{ marginLeft: "20px" }}>
                              <Input
                                className="custom-control-input"
                                id="customRadio6"
                                name="custom-radio-2"
                                type="radio"
                                value={2}
                                checked={gender === 2}
                                onClick={() => setGender(2)}
                              />
                              <Label
                                className="custom-control-label"
                                htmlFor="customRadio6"
                              >
                                Wanita
                                </Label>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        Nomor Telp/ Handphone
                      </Label>
                      <Input
                        type="number"
                        name="No Telphone"
                        placeholder="Masukan Nomor Telp"
                        value={phoneNumber}
                        onChange={e => setPhoneNumber(e.target.value)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        Roles
                      </Label>
                      <Input
                        name="role"
                        type="select"
                        value={role}
                        invalid={roleError === "invalid"}
                        onChange={(e) => {
                          setRole(e.target.value);
                          if (e.target.value !== 0) {
                            setRoleError("");
                          }
                          getMerchant(e.target.value);
                        }}
                      >
                        <option value={0}>Pilih Role</option>
                        {
                          roles.map((role, key) => {
                            return <option key={key} value={role.role_id}>{role.name}</option>
                          })
                        }
                      </Input>
                      <FormFeedback>Role harus diisi</FormFeedback>
                    </FormGroup>
                    {
                      allowedRolesMerchant.includes(localStorage.getItem("authority"))
                        ?
                        <div></div>
                        :
                        <FormGroup>
                          <Label
                            className="form-control-label"
                            htmlFor="exampleFormControlInput1"
                          >
                            Nama Merchant
                      </Label>
                          <Input
                            name="merchant_pk"
                            type="select"
                            value={merchantPk}
                            invalid={merchantError === "invalid"}
                            onChange={(e) => {
                              setMerchantPk(e.target.value);
                              if (e.target.value !== 0) {
                                setMerchantError("");
                                getUniKerja(e.target.value);
                              }
                            }}
                          >
                            <option value="0">Pilih Merchant</option>
                            {
                              merchants.map((merchant, key) => {
                                return <option key={key} value={merchant.merchant_pk}>{merchant.merchant_name}</option>
                              })
                            }
                          </Input>
                          <FormFeedback>Nama Merchant harus diisi</FormFeedback>
                        </FormGroup>
                    }
                    {
                      roleId > 1 && roleId < 5
                        ?
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <Label
                                className="form-control-label"
                                htmlFor="exampleFormControlSelect3"
                              >
                                Unit Kerja
                                    </Label>
                              <Row>
                                <Col md="6">
                                  <div className="custom-control custom-radio mb-3">
                                    <Input
                                      className="custom-control-input"
                                      id="iya"
                                      name="custom-radio-3"
                                      type="radio"
                                      value={1}
                                      checked={isSelectUnitKerja === 1}
                                      onClick={() => setSelecUnitKerja(1)}
                                    />
                                    <Label
                                      className="custom-control-label"
                                      htmlFor="iya"
                                    >
                                      Ya
                                        </Label>
                                  </div>
                                  <Row>
                                    <Col md="6">
                                      <div className="custom-control custom-radio mb-3">
                                        <Input
                                          className="custom-control-input"
                                          id="tidak"
                                          name="custom-radio-3"
                                          type="radio"
                                          value={2}
                                          checked={isSelectUnitKerja === 2}
                                          onClick={() => setSelecUnitKerja(2)}
                                        />
                                        <Label
                                          className="custom-control-label"
                                          htmlFor="tidak"
                                        >
                                          Tidak
                                                </Label>
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                          {
                            isSelectUnitKerja === 1 ?
                              <Col md="6">
                                <FormGroup>
                                  <Label
                                    className="form-control-label"
                                    htmlFor="exampleFormControlInput1"
                                  >
                                    Unit Kerja
                            </Label>
                                  <Input
                                    name="unitKerja"
                                    type="select"
                                    value={unitKerja}
                                    invalid={unitKerjaError === "invalid"}
                                    onChange={(e) => {
                                      setUnitKerja(e.target.value);
                                      if (e.target.value !== "") {
                                        setUnitKerjaError("");
                                      }
                                    }}
                                  >
                                    <option value="">Pilih Unit Kerja</option>
                                    {
                                      listUnitKerja.map((workUnit, key) => {
                                        return <option key={key} value={workUnit.workUnitPk}>{workUnit.workUnitName}</option>
                                      })
                                    }
                                  </Input>
                                  <FormFeedback>Unit kerja tidak boleh kosong</FormFeedback>
                                </FormGroup>
                              </Col>
                              :
                              <div></div>
                          }
                        </Row>
                        :
                        <div></div>
                    }
                  </CardBody>
                  <CardFooter>
                    {!isLoading && (<Button color="primary" type="submit">
                      Simpan
                    </Button>)}
                    {isLoading && (<Button color="primary" disabled>
                      <i className="fas fa-spinner fa-spin"></i>{""}
                      loading...
                    </Button>)}
                    <Link class="btn btn-info" to="/admin/user">
                      Kembali
                    </Link>
                  </CardFooter>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
