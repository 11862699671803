/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Label,
  FormGroup,
  Row,
  Col,
  Input,
  UncontrolledTooltip,
  Container,
  CardHeader,
  Button, ButtonGroup, Form
} from "reactstrap";
import axios from 'axios';
import SweetAlert from "react-bootstrap-sweetalert";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

export default function BinRange() {
  const redirectPrefix = `/admin/bin-range/edit/`;
  const [allBinRange, setAllBinRange] = useState([]);
  const [alert, setAlert] = React.useState(null);
  let [rowNumber, setRowNumber] = useState([]);
  const [binRanges, setBinRanges] = useState([]);
  const [binRange, setBinRange] = useState("");
  const [transTypes, setTransTypes] = useState([]);
  const [transType, setTransType] = useState("");
  const [issuerNames, setIssuerNames] = useState([]);
  const [issuerName, setIssuerName] = useState("");
  const [principals, setPrincipalas] = useState([]);
  const [principal, setPrincipal] = useState("");

  let paginationOption = {
    page: 1,
    alwaysShowAllBtns: true,
    override: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => onSizePerPageChange(e.target.value)}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  }

  useEffect(() => {
    getAllBinRange();
    getBanks();
    getCardTypes();
    getPrincipals();
    getBinRanges();
  }, []);

  const getAllBinRange = () => {
    const data = { page: 1, per_page: 100 };
    fetch(`${process.env.REACT_APP_API_BASE_URL}/binranges`, {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        setAllBinRange(data.response_data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const getBanks = () => {
    const data = { page: 1, per_page: 100 };
    fetch(`${process.env.REACT_APP_API_BASE_URL}/banks`, {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        setIssuerNames(data.response_data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const getBinRanges = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/binrange/list`, {
      method: 'GET', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(),
    })
      .then(response => response.json())
      .then(data => {
        setBinRanges(data.response_data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const getCardTypes = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/transtypes`, {
      method: 'GET', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(),
    })
      .then(response => response.json())
      .then(data => {
        setTransTypes(data.response_data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const getPrincipals = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/principals`, {
      method: 'GET', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(),
    })
      .then(response => response.json())
      .then(data => {
        setPrincipalas(data.response_data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const delateBinRange = (bin_range_id) => {
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/binrange/delete/${bin_range_id}`)
      .then(function (response) {
        setSuccessAlert();
        getAllBinRange();
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const setSuccessAlert = () => {
    setAlert(
      <SweetAlert
        success
        showConfirm
        confirmBtnText="Ok"
        title="Bin Range deleted"
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    )
  }

  const hideAlert = () => {
    setAlert(null);
  }

  const setQuestionAlert = (bin_range_id) => {
    setAlert(
      <SweetAlert
        warning
        showCancel
        placement="center"
        confirmBtnText="Yes"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        text="Delete this?"
        onConfirm={() => delateBinRange(bin_range_id)}
        onCancel={hideAlert}
        focusCancelBtn
      />
    )
  }
  return (
    <>
      {alert}
      <SimpleHeader name="Bin Range" parentName="Admin" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <h3>List Bin Range</h3>
                  <div style={{ textAlign: "right" }}>
                    <Link class="btn btn-info" to="/admin/bin-range/create">
                      Add
                        </Link>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row md="12">
                    <Col md="3">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect3">Bin Name</Label>
                        <Input
                          id="exampleFormControlSelect3"
                          name="bin_name"
                          type="select"
                          value={binRange}
                          onChange={e => setBinRange(e.target.value)}
                        >
                          <option value="0">Pilih Bin Name</option>
                          {
                            binRanges.map((binRange, key) => {
                              return <option key={key} value={binRange.bin_range_id}>{binRange.bin_name}</option>
                            })
                          }
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect3">Card Type</Label>
                        <Input
                          id="exampleFormControlSelect3"
                          name="transType"
                          type="select"
                          value={transType}
                          onChange={e => setTransType(e.target.value)}
                        >
                          <option value="0">Pilih Card Type</option>
                          {
                            transTypes.map((transType, key) => {
                              return <option key={key} value={transType.transtype_name}>{transType.transtype_name}</option>
                            })
                          }
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect3">Issuer Name</Label>
                        <Input
                          id="exampleFormControlSelect3"
                          name="bank_code"
                          type="select"
                          value={issuerName}
                          onChange={e => setIssuerName(e.target.value)}
                        >
                          <option value="0">Pilih Issuer Name</option>
                          {
                            issuerNames.map((bank, key) => {
                              return <option key={key} value={bank.bank_code}>{bank.bank_name}</option>
                            })
                          }
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect3">Principal</Label>
                        <Input
                          id="exampleFormControlSelect3"
                          name="principal"
                          type="select"
                          value={principal}
                          onChange={e => setPrincipal(e.target.value)}
                        >
                          <option value="0">Pilih Principal</option>
                          {
                            principals.map((principal, key) => {
                              return <option key={key} value={principal.principal_id}>{principal.principal_name}</option>
                            })
                          }
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button type="submit" color="primary">Search</Button>
                      <Button type="button" color="secondary">Reset</Button>
                    </Col>
                  </Row>
                </Form>
                <ToolkitProvider
                  rowNumber={rowNumber}
                  data={allBinRange}
                  keyField="name"
                  columns={[
                    {
                      dataField: "no",
                      text: "#",
                      sort: true,
                      page: 1,
                      formatter: (cell, row, index) => {
                        return ++index
                      }
                    },
                    {
                      dataField: "bin_name",
                      text: "Bin Name",
                      sort: true,
                    },
                    {
                      dataField: "pan_lo",
                      text: "Pan Lo",
                      sort: true,
                    },
                    {
                      dataField: "pan_hi",
                      text: "Pan Hi",
                      sort: true,
                    },
                    {
                      dataField: "card_type",
                      text: "Card Type",
                      sort: true,
                    },
                    {
                      dataField: "bank_name",
                      text: "Issuer Name",
                      sort: true,
                    },
                    {
                      dataField: "principal_name",
                      text: "Principal Name",
                      sort: true,
                    },
                    {
                      dataField: "", text: "", formatter: (cell, row, index) => {
                        return (
                          <ButtonGroup>
                            <Button>
                              <Link
                                to={redirectPrefix + row.bin_range_id}
                                id={"tooltip_" + row.bin_range_id}
                              >
                                <i className="fas fa-user-edit" />
                              </Link>
                            </Button>
                            <UncontrolledTooltip delay={0} target={"tooltip_" + row.bin_range_id}>
                              Edit
                          </UncontrolledTooltip>
                            <Button
                              id="btn-delete-bank"
                              onClick={() => setQuestionAlert(row.bin_range_id)}
                            >
                              <i className="fas fa-trash" />
                            </Button>
                            <UncontrolledTooltip
                              delay={0}
                              placement="center"
                              target="btn-delete-bank"
                            >
                              Delete Bin Range
                            </UncontrolledTooltip>
                          </ButtonGroup>
                        )
                      }
                    },
                  ]}
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      <BootstrapTable
                        {...props.baseProps}
                        bootstrap4={true}
                        bordered={false}
                        pagination={paginationFactory({ ...paginationOption })}
                        onTableChange
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
