/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Label,
  Row,
  Input,
  Container,
  CardHeader,
  CardFooter,
  Button,
  InputGroupAddon,
  InputGroupText,
  FormFeedback
} from "reactstrap";
import { AvForm, AvGroup } from 'availity-reactstrap-validation';
import { Link, useHistory } from "react-router-dom";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import axios from 'axios';
import SweetAlert from "react-bootstrap-sweetalert";

export default function ChangePassword() {
  let history = useHistory();
  const [userId, setUserId] = useState(null);
  const [sandiLama, setSandiLama] = useState("");
  const [oldPasswordError, setOldPasswordError] = useState(null);
  const [sandiBaru, setSandiBaru] = useState("");
  const [passwordError, setPasswordError] = useState(null);
  const [ulangSandiBaru, setUlangSandiBaru] = useState("");
  const [confrimPasswordError, setConfrimPasswordError] = useState(null);
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const [alert, setAlert] = React.useState(null);
  const [errorPasswordMessage, setErrorPasswordMessage] = useState("");
  const [isLoading, setLoading] = useState(false);

  const validateForm = () => {
    let error = false;
    if (sandiLama === "") {
      setOldPasswordError("invalid");
      error = true;
    } else {
      setOldPasswordError("valid");
    }
    if (sandiBaru === sandiLama) {
      setPasswordError("invalid");
      error = true;
    }
    if (sandiBaru !== ulangSandiBaru) {
      setPasswordError("invalid");
      error = true;
    }
    if (sandiBaru === "") {
      setPasswordError("invalid");
      error = true;
    }
    if (ulangSandiBaru === "") {
      setConfrimPasswordError("invalid");
      error = true;
    } else {
      setConfrimPasswordError("valid")
    }
    return error;
  };

  useEffect(() => {
    getMyProfil();
  }, []);

  const getMyProfil = async () => {
    const token = localStorage.token;
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/info`, {
        method: 'GET', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(),
      })
      const data = await response.json();
      setUserId(data.userId);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const hideAlert = () => {
    setAlert(null);
  }

  const setSuccessAlert = () => {
    setAlert(
      <SweetAlert
        success
        showConfirm
        confirmBtnText="Ok"
        title="Perubahan password berhasil disimpan"
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    )
  }

  function updatePassword() {
    setLoading(true);
    const token = localStorage.token;
    const headers = {
      Authorization: `Bearer ${token}`
    }
    let body = {
      old_password: sandiLama,
      new_password: sandiBaru
    }
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/user/changepass/${userId}`, body, {
      headers: headers
    })
      .then(function (response) {
        if (response.data.errors) {
          showErrors(response.data.errors);
          return;
        }
        setSuccessAlert();
        history.push("/admin/change-password");
      })
      .then(json => {
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const showErrors = (errors) => {
    setOldPasswordError("invalid");
    setErrorPasswordMessage(errors.old_password);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      updatePassword();
    }
  }

  return (
    <>
      {alert}
      <SimpleHeader name="Ubah Kata Sandi" parentName="Admin" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardBody>
                <AvForm onSubmit={handleSubmit} invalid>
                  <CardHeader>
                    <h3>Ubah Kata Sandi</h3>
                  </CardHeader>
                  <CardBody>
                    <Label
                      className="form-control-label"
                      htmlFor="name"
                    >
                      Kata Sandi Lama
                    </Label>
                    <AvGroup className="input-group">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Masukan Kata Sandi Lama"
                        type={passwordShown ? "text" : "password"}
                        value={sandiLama}
                        invalid={oldPasswordError === "invalid"}
                        onChange={(e) => {
                          setSandiLama(e.target.value);
                          if (e.target.value !== "") {
                            setOldPasswordError("");
                          }
                        }}
                      />
                      <InputGroupText>
                        {
                          passwordShown !== true ? <i class="fa fa-eye" aria-hidden="true" onClick={togglePasswordVisiblity} /> : <i class="fa fa-eye-slash" aria-hidden="true" onClick={togglePasswordVisiblity} />
                        }
                      </InputGroupText>
                      <FormFeedback>
                        {
                          sandiLama === "" ? "Kata sandi tidak boleh kosong" : errorPasswordMessage
                        }
                      </FormFeedback>
                    </AvGroup>

                    <Label
                      className="form-control-label"
                      htmlFor="name"
                    >
                      Kata Sandi Baru
                    </Label>
                    <AvGroup className="input-group">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Masukan Kata Sandi Baru"
                        type={passwordShown ? "text" : "password"}
                        value={sandiBaru}
                        invalid={passwordError === "invalid"}
                        onChange={(e) => {
                          setSandiBaru(e.target.value);
                          if (e.target.value !== "") {
                            setPasswordError("");
                          }
                        }}
                      />
                      <InputGroupText>
                        {
                          passwordShown !== true ? <i class="fa fa-eye" aria-hidden="true" onClick={togglePasswordVisiblity} /> : <i class="fa fa-eye-slash" aria-hidden="true" onClick={togglePasswordVisiblity} />
                        }
                      </InputGroupText>
                      {
                        sandiBaru !== ulangSandiBaru ? <FormFeedback>Kata sandi baru dan pengulangan kata sandi tidak sama</FormFeedback> : sandiBaru === "" ? <FormFeedback>Kata sandi baru tidak boleh kosong</FormFeedback> : sandiBaru === sandiLama ? <FormFeedback>Kata Sandi Baru tidak boleh sama dengan kata sandi lama </FormFeedback> : <FormFeedback></FormFeedback>
                      }
                    </AvGroup>

                    <Label
                      className="form-control-label"
                      htmlFor="name"
                    >
                      Ulangi Kata Sandi Baru
                    </Label>
                    <AvGroup className="input-group">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Ulangi Kata Sandi Baru"
                        type={passwordShown ? "text" : "password"}
                        value={ulangSandiBaru}
                        invalid={confrimPasswordError === "invalid"}
                        onChange={(e) => {
                          setUlangSandiBaru(e.target.value);
                          if (e.target.value !== "") {
                            setConfrimPasswordError("");
                          }
                        }}
                      />
                      <InputGroupText>
                        {
                          passwordShown !== true ? <i class="fa fa-eye" aria-hidden="true" onClick={togglePasswordVisiblity} /> : <i class="fa fa-eye-slash" aria-hidden="true" onClick={togglePasswordVisiblity} />
                        }
                      </InputGroupText>
                      <FormFeedback>Ulangi kata sandi tidak boleh kosong</FormFeedback>
                    </AvGroup>
                  </CardBody>
                  <CardFooter>
                    {!isLoading && (<Button color="primary" type="submit">
                      Simpan
                    </Button>)}
                    {isLoading && (<Button color="primary" disabled>
                      <i className="fas fa-spinner fa-spin"></i>{""}
                      loading...
                    </Button>)}
                    <Link class="btn btn-info" to="/admin/dasboard">
                      Kembali
                    </Link>
                  </CardFooter>
                </AvForm>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
