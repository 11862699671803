/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Label,
  FormGroup,
  Row,
  Col,
  Input,
  UncontrolledTooltip,
  Container,
  CardHeader,
  Button, Nav, NavItem, NavLink, TabContent, TabPane, Form, Collapse
} from "reactstrap";
import classnames from 'classnames';
import moment from 'moment';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import TerminalParameter from "views/pages/terminal/terminal_params/Index.js";
import TerminalReceipt from "views/pages/terminal/terminal_receipt/Index.js";

export default function Terminal() {
  const token = localStorage.token;
  const role = localStorage.getItem("authority");
  const merchantPk = localStorage.getItem("merchantPk");
  const projectId = localStorage.getItem("projectId");
  const [activeTab, setActiveTab] = useState('1');
  const [allTerminal, setAllTerminal] = useState([]);
  const [allTerminalByProject, setAllTerminalByProject] = useState([]);
  const redirectPrefix = `/admin/terminal/edit/`;
  const [hide, setHide] = useState(true);
  const [selectTerminalPk, setSeectTerminalPk] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);
  const [types, setTypes] = useState([]);
  const [type, setType] = useState("");
  const [merchants, setMerchants] = useState([]);
  const [merchant, setMerchant] = useState("");
  const [listStatus, setListStatus] = useState([]);
  const [status, setStatus] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [reffNo, setReffNo] = useState("");
  const [datePeriod, setDatePeriod] = useState("");
  const [rowIndex, setRowIndex] = useState(0);
  const allowedRoles = ["ROLE_SUPERADMIN"];
  const allowedProject = ["ROLE_OPERATOR_PROJECT", "ROLE_SUPERVISOR_PROJECT", "ROLE_ADMIN_PROJECT"];
  const allowedGroup = ["ROLE_OPERATOR_GROUP", "ROLE_SUPERVISOR_GROUP", "ROLE_ADMIN_GROUP"];
  const allowedMerchant = ["ROLE_OPERATOR_MERCHANT", "ROLE_SUPERVISOR_MERCHANT", "ROLE_ADMIN_MERCHANT"];

  const [page, setPage] = useState(1);
  const [perPage, setPerpage] = useState(10);
  const [totalItem, setTotalItem] = useState(0);
  const [currentSort, setCurrentSort] = useState("");

  let paginationOption = {
    page: page,
    alwaysShowAllBtns: true,
    override: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPage: perPage,
    totalSize: totalItem,
    onPageChange: (page) => {
      updateDataTable(page, perPage, currentSort, type, merchant, status, reffNo, serialNumber, datePeriod);
    },
    sizePerPageRenderer: () => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                updateDataTable(page, e.target.value, currentSort, type, merchant, status, reffNo, serialNumber, datePeriod)
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  }

  const updateDataTable = (page, perPage, sort, type, merchant, status, reffNo, serialNumber, datePeriod) => {
    getAllTerminal(page, perPage, sort, parseInt(type), parseInt(merchant), parseInt(status), reffNo, serialNumber, datePeriod === "Invalid date" ? "" : moment(datePeriod).format("YYYY-MM-DD"));
    getAllTerminalByProject(page, perPage, sort, parseInt(type), parseInt(merchant), parseInt(status), reffNo, serialNumber, datePeriod);
    setPage(page);
    setPerpage(perPage);
    setRowIndex((page - 1) * perPage);
    setCurrentSort(sort);
    setType(type);
    setMerchant(merchant);
    setStatus(status);
    setReffNo(reffNo);
    setSerialNumber(serialNumber);
    setDatePeriod(moment(datePeriod).format("YYYY-MM-DD"));
  }

  const handleTableChange = (type, { sortField, sortOrder }) => {
    if (type === "sort") {
      let sort = `${sortField} ${sortOrder}`
      updateDataTable(page, perPage, sort, type, merchant, status, reffNo, serialNumber, datePeriod)
    }
  }

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }

  useEffect(() => {
    getAllTerminal(page, perPage, currentSort, "", "", "", "", "", "");
    getAllTerminalByProject(page, perPage, currentSort, "", "", "", "", "", "");
    getMerchants();
    getTypes();
    getStatus();
  }, []);

  const getAllTerminal = (page, perPage, currentSort, terminalTypeId = 0, merchant_pk = 0, terminal_status = 0, reference_no = null, terminal_sn = null, date_period = null) => {
    let filter = { page: page, per_page: perPage, role: role }
    if (terminalTypeId !== 0) {
      filter = Object.assign(filter, { terminalTypeId: terminalTypeId })
    }
    if (merchant_pk !== 0) {
      filter = Object.assign(filter, { merchant_pk: merchant_pk })
    }
    if (terminal_status !== 0) {
      filter = Object.assign(filter, { terminal_status: terminal_status })
    }
    if (reference_no !== null) {
      filter = Object.assign(filter, { reference_no: reference_no })
    }
    if (terminal_sn !== null) {
      filter = Object.assign(filter, { terminal_sn: terminal_sn })
    }
    if (date_period !== null) {
      filter = Object.assign(filter, { date_period: date_period === "Invalid date" ? "" : date_period })
    }
    const data = filter;
    fetch(`${process.env.REACT_APP_API_BASE_URL}/terminals`, {
      method: 'POST', // or 'PUT'
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        setAllTerminal(data.response_data);
        setPage(data.current_page);
        setPerpage(data.per_page);
        setTotalItem(data.total_item);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const getAllTerminalByProject = (page, perPage, currentSort, terminalTypeId = 0, merchant_pk = 0, terminal_status = 0, reference_no = null, terminal_sn = null, date_period = null) => {
    if (allowedProject.includes(localStorage.getItem("authority"))) {
      let filter = { page: page, per_page: perPage, role: role, projectId: parseInt(merchantPk), merchantPk: parseInt(merchantPk) }
      if (terminalTypeId !== 0) {
        filter = Object.assign(filter, { terminalTypeId: terminalTypeId })
      }
      if (merchant_pk !== 0) {
        filter = Object.assign(filter, { merchant_pk: merchant_pk })
      }
      if (terminal_status !== 0) {
        filter = Object.assign(filter, { terminal_status: terminal_status })
      }
      if (reference_no !== null) {
        filter = Object.assign(filter, { reference_no: reference_no })
      }
      if (terminal_sn !== null) {
        filter = Object.assign(filter, { terminal_sn: terminal_sn })
      }
      if (date_period !== null) {
        filter = Object.assign(filter, { date_period: date_period === "Invalid date" ? "" : date_period })
      }
      const data = filter;
      fetch(`${process.env.REACT_APP_API_BASE_URL}/terminals`, {
        method: 'POST', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(response => response.json())
        .then(data => {
          setAllTerminalByProject(data.response_data);
          setPage(data.current_page);
          setPerpage(data.per_page);
          setTotalItem(data.total_item);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
    if (allowedGroup.includes(localStorage.getItem("authority"))) {
      let filter = { page: page, per_page: perPage, role: role, projectId: parseInt(projectId), groupId: parseInt(merchantPk), merchantPk: parseInt(merchantPk) }
      if (terminalTypeId !== 0) {
        filter = Object.assign(filter, { terminalTypeId: terminalTypeId })
      }
      if (merchant_pk !== 0) {
        filter = Object.assign(filter, { merchant_pk: merchant_pk })
      }
      if (terminal_status !== 0) {
        filter = Object.assign(filter, { terminal_status: terminal_status })
      }
      if (reference_no !== null) {
        filter = Object.assign(filter, { reference_no: reference_no })
      }
      if (terminal_sn !== null) {
        filter = Object.assign(filter, { terminal_sn: terminal_sn })
      }
      if (date_period !== null) {
        filter = Object.assign(filter, { date_period: date_period === "Invalid date" ? "" : date_period })
      }
      const data = filter;
      fetch(`${process.env.REACT_APP_API_BASE_URL}/terminals`, {
        method: 'POST', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(response => response.json())
        .then(data => {
          setAllTerminalByProject(data.response_data);
          setPage(data.current_page);
          setPerpage(data.per_page);
          setTotalItem(data.total_item);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
    if (allowedMerchant.includes(localStorage.getItem("authority"))) {
      let filter = { page: page, per_page: perPage, role: role, merchantPk: parseInt(merchantPk) }
      if (terminalTypeId !== 0) {
        filter = Object.assign(filter, { terminalTypeId: terminalTypeId })
      }
      if (merchant_pk !== 0) {
        filter = Object.assign(filter, { merchant_pk: merchant_pk })
      }
      if (terminal_status !== 0) {
        filter = Object.assign(filter, { terminal_status: terminal_status })
      }
      if (reference_no !== null) {
        filter = Object.assign(filter, { reference_no: reference_no })
      }
      if (terminal_sn !== null) {
        filter = Object.assign(filter, { terminal_sn: terminal_sn })
      }
      if (date_period !== null) {
        filter = Object.assign(filter, { date_period: date_period === "Invalid date" ? "" : date_period })
      }
      const data = filter;
      fetch(`${process.env.REACT_APP_API_BASE_URL}/terminals`, {
        method: 'POST', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(response => response.json())
        .then(data => {
          setAllTerminalByProject(data.response_data);
          setPage(data.current_page);
          setPerpage(data.per_page);
          setTotalItem(data.total_item);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  }

  const getMerchants = () => {
    if (allowedRoles.includes(localStorage.getItem("authority"))) {
      let data = {
        role: role
      }
      fetch(`${process.env.REACT_APP_API_BASE_URL}/merchants/list`, {
        method: 'POST', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(response => response.json())
        .then(data => {
          setMerchants(data.response_data);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
    if (allowedProject.includes(localStorage.getItem("authority"))) {
      let data = { role: role, project_id: parseInt(merchantPk) }
      fetch(`${process.env.REACT_APP_API_BASE_URL}/merchants/list`, {
        method: 'POST', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(response => response.json())
        .then(data => {
          setMerchants(data.response_data);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
    if (allowedGroup.includes(localStorage.getItem("authority"))) {
      let data = { role: role, project_id: parseInt(projectId), group_id: parseInt(merchantPk) }
      fetch(`${process.env.REACT_APP_API_BASE_URL}/merchants/list`, {
        method: 'POST', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(response => response.json())
        .then(data => {
          setMerchants(data.response_data);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
    if (allowedMerchant.includes(localStorage.getItem("authority"))) {
      let data = { role: role, merchantPk: parseInt(merchantPk) }
      fetch(`${process.env.REACT_APP_API_BASE_URL}/merchants/list`, {
        method: 'POST', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(response => response.json())
        .then(data => {
          setMerchants(data.response_data);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  }

  const getTypes = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/terminaltype/list`, {
      method: 'GET', // or 'PUT'
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(),
    })
      .then(response => response.json())
      .then(data => {
        setTypes(data.response_data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const getStatus = () => {
    const data = { type: "terminal" };
    fetch(`${process.env.REACT_APP_API_BASE_URL}/status`, {
      method: 'POST', // or 'PUT'
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        setListStatus(data.response_data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const rowEvents = {
    onDoubleClick: (e, row, rowIndex) => {
      setHide(false);
      setSeectTerminalPk(row.terminal_pk);
    }
  };

  const reset = () => {
    setType("");
    setMerchant("");
    setStatus("");
    setReffNo("");
    setSerialNumber("");
    setDatePeriod("");
    getAllTerminal();
    getAllTerminalByProject();
  }

  return (
    <>
      <SimpleHeader name="Terminal" parentName="Admin" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <h3>Terminal</h3>
                  {
                    allowedRoles.includes(localStorage.getItem("authority"))
                      ?
                      <div style={{ textAlign: "right" }}>
                        <Link className="btn btn-info" to="/admin/terminal/create">
                          Add
                        </Link>
                      </div>
                      :
                      <div></div>
                  }
                </div>
              </CardHeader>
              <CardBody>
                <h3 onClick={toggleOpen} >Filter &nbsp;
                  {
                    isOpen === true ? <i className="fa fa-angle-down" aria-hidden="true"></i> : <i className="fa fa-angle-right" aria-hidden="true"></i>
                  }
                </h3>
                <Collapse isOpen={isOpen}>
                  {
                    allowedMerchant.includes(localStorage.getItem("authority"))
                      ?
                      <Form>
                        <Row md="12">
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect3">Tipe</Label>
                              <Input
                                id="exampleFormControlSelect3"
                                name="tipe"
                                type="select"
                                value={type}
                                onChange={e => updateDataTable(page, perPage, currentSort, e.target.value)}
                              >
                                <option value="0">Pilih Tipe</option>
                                {
                                  types.map((type, key) => {
                                    return <option key={key} value={type.terminalTypeId}>{type.terminalTypeName}</option>
                                  })
                                }
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect3">Serial Number</Label>
                              <Input
                                type="text"
                                placeholder="Masukan Serial Number"
                                value={serialNumber}
                                onChange={e => updateDataTable(page, perPage, currentSort, type, merchant, status, reffNo, e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect3">Status</Label>
                              <Input
                                id="exampleFormControlSelect3"
                                name="status"
                                type="select"
                                value={status}
                                onChange={e => updateDataTable(page, perPage, currentSort, type, merchant, e.target.value)}
                              >
                                <option value="0">Pilih Status</option>
                                {
                                  listStatus.map((status, key) => {
                                    return <option key={key} value={status.value}>{status.description}</option>
                                  })
                                }
                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row md="12">
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect3">Date Period</Label>
                              <Input
                                id="example-date-input"
                                type="date"
                                value={datePeriod}
                                onChange={e => updateDataTable(page, perPage, currentSort, type, merchant, status, reffNo, serialNumber, e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect3">Ref No</Label>
                              <Input
                                type="text"
                                placeholder="Masukan Ref No"
                                value={reffNo}
                                onChange={e => updateDataTable(page, perPage, currentSort, type, merchant, status, e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Button type="button" onClick={reset} color="secondary">Reset</Button>
                          </Col>
                        </Row>
                      </Form>
                      :
                      <Form>
                        <Row md="12">
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect3">Tipe</Label>
                              <Input
                                id="exampleFormControlSelect3"
                                name="tipe"
                                type="select"
                                value={type}
                                onChange={e => updateDataTable(page, perPage, currentSort, e.target.value)}
                              >
                                <option value="0">Pilih Tipe</option>
                                {
                                  types.map((type, key) => {
                                    return <option key={key} value={type.terminalTypeId}>{type.terminalTypeName}</option>
                                  })
                                }
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect3">Merchant</Label>
                              <Input
                                id="exampleFormControlSelect3"
                                name="merchant"
                                type="select"
                                value={merchant}
                                onChange={e => updateDataTable(page, perPage, currentSort, type, e.target.value)}
                              >
                                <option value="0">Pilih Merchant</option>
                                {
                                  merchants.map((merchant, key) => {
                                    return <option key={key} value={merchant.merchant_pk}>{merchant.merchant_name}</option>
                                  })
                                }
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect3">Status</Label>
                              <Input
                                id="exampleFormControlSelect3"
                                name="status"
                                type="select"
                                value={status}
                                onChange={e => updateDataTable(page, perPage, currentSort, type, merchant, e.target.value)}
                              >
                                <option value="0">Pilih Status</option>
                                {
                                  listStatus.map((status, key) => {
                                    return <option key={key} value={status.value}>{status.description}</option>
                                  })
                                }
                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row md="12">
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect3">Date Period</Label>
                              <Input
                                id="example-date-input"
                                type="date"
                                value={datePeriod}
                                onChange={(e) => {
                                  updateDataTable(page, perPage, currentSort, type, merchant, status, reffNo, serialNumber, e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect3">Ref No</Label>
                              <Input
                                type="text"
                                placeholder="Masukan Ref No"
                                value={reffNo}
                                onChange={e => updateDataTable(page, perPage, currentSort, type, merchant, status, e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect3">Serial Number</Label>
                              <Input
                                type="text"
                                placeholder="Masukan Serial Number"
                                value={serialNumber}
                                onChange={(e) => {
                                  setSerialNumber(e.target.value);
                                }}
                                onChange={e => updateDataTable(page, perPage, currentSort, type, merchant, status, reffNo, e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Button type="button" onClick={reset} color="secondary">Reset</Button>
                          </Col>
                        </Row>
                      </Form>
                  }
                </Collapse>
                {
                  allowedRoles.includes(localStorage.getItem("authority"))
                    ?
                    <ToolkitProvider
                      rowNumber={rowIndex}
                      data={allTerminal}
                      keyField="terminal_pk"
                      columns={[
                        {
                          dataField: "no",
                          text: "#",
                          sort: true,
                          page: 1,
                          formatter: (cell, row, index) => {
                            let currentRow = ++index;
                            return currentRow + rowIndex;
                          }
                        },
                        {
                          dataField: "serial_number",
                          text: "Serial Number",
                          sort: true,
                        },
                        {
                          dataField: "terminal_code",
                          text: "Terminal Code",
                          sort: true,
                        },
                        {
                          dataField: "terminalTypeName",
                          text: "Tipe",
                          sort: true,
                        },
                        {
                          dataField: "reference_no",
                          text: "Ref No",
                          sort: true,
                        },
                        {
                          dataField: "merchant_name",
                          text: "Nama Merchant",
                          sort: true,
                        },
                        {
                          dataField: "installation_date",
                          text: "Tanggal Pasang",
                          sort: true,
                          formatter: (cell, row, index) => {
                            return row.installation_date ? moment(row.installation_date, "DD/MM/YYYY").format("DD/MM/yyyy") : '(Not set)'
                          }
                        },
                        {
                          dataField: "statusTerminal",
                          text: "Status",
                          sort: true,
                          formatter: (cell, row, index) => {
                            return row.statusTerminal === 1 ? "Active" : "InActive"
                          }
                        },
                        {
                          dataField: "", text: "", formatter: (cell, row, index) => {
                            return (
                              <>
                                <Link
                                  className="btn btn-primary btn-sm"
                                  to={redirectPrefix + row.terminal_pk}
                                  id={"tooltip_" + row.terminal_pk}
                                >
                                  <i className="fas fa-pencil-alt" />
                                </Link>
                                <UncontrolledTooltip delay={0} target={"tooltip_" + row.terminal_pk}>
                                  Edit
                            </UncontrolledTooltip>
                              </>
                            )
                          }
                        },
                      ]}
                    >
                      {(props) => (
                        <div className="py-4 table-responsive">
                          <BootstrapTable
                            remote
                            {...props.baseProps}
                            bootstrap4={true}
                            bordered={false}
                            hover={true}
                            rowEvents={rowEvents}
                            pagination={paginationFactory({ ...paginationOption })}
                            onTableChange={handleTableChange}
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                    :
                    <ToolkitProvider
                      rowNumber={rowIndex}
                      data={allTerminalByProject}
                      keyField="terminal_pk"
                      columns={[
                        {
                          dataField: "no",
                          text: "#",
                          sort: true,
                          page: 1,
                          formatter: (cell, row, index) => {
                            let currentRow = ++index;
                            return currentRow + rowIndex;
                          }
                        },
                        {
                          dataField: "serial_number",
                          text: "Serial Number",
                          sort: true,
                        },
                        {
                          dataField: "terminal_code",
                          text: "Terminal Code",
                          sort: true,
                        },
                        {
                          dataField: "terminalTypeName",
                          text: "Tipe",
                          sort: true,
                        },
                        {
                          dataField: "merchant_name",
                          text: "Nama Merchant",
                          sort: true,
                        },
                        {
                          dataField: "reference_no",
                          text: "Ref No",
                          sort: true,
                        },
                        {
                          dataField: "installation_date",
                          text: "Tanggal Pasang",
                          sort: true,
                          formatter: (cell, row, index) => {
                            return row.installation_date ? moment(row.installation_date, "DD/MM/YYYY").format("DD/MM/yyyy") : '(Not set)'
                          }
                        },
                        {
                          dataField: "statusTerminal",
                          text: "Status",
                          sort: true,
                          formatter: (cell, row, index) => {
                            return row.statusTerminal === 1 ? "Active" : "InActive"
                          }
                        }
                      ]}
                    >
                      {(props) => (
                        <div className="py-4 table-responsive">
                          <BootstrapTable
                            remote
                            {...props.baseProps}
                            bootstrap4={true}
                            bordered={false}
                            hover={true}
                            rowEvents={rowEvents}
                            pagination={paginationFactory({ ...paginationOption })}
                            onTableChange={handleTableChange}
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                }
              </CardBody>
            </Card>
            <Card>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '1' })}
                    onClick={() => { toggle('1'); }}
                  >
                    Parameters
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '2' })}
                    onClick={() => { toggle('2'); }}
                  >
                    Receipt
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <Row>
                    <Col md="12">
                      {hide ? <></> :
                        <TerminalParameter terminalPk={selectTerminalPk} />
                      }
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col md="12">
                      {hide ? <></> :
                        <TerminalReceipt terminalPk={selectTerminalPk} />
                      }
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
