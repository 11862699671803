/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
} from "reactstrap";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

export default function DataMdr() {
  const token = localStorage.token;
  const merchantPk = localStorage.getItem("merchantPk");
  let [rowNumber, setRowNumber] = useState([]);
  const [allMdrs, setAllMdrs] = useState([]);

  let paginationOption = {
    page: 1,
    alwaysShowAllBtns: true,
    override: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => onSizePerPageChange(e.target.value)}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  }

  useEffect(() => {
    getAllMdrs();
  }, []);

  const getAllMdrs = () => {
    const data = { page: 1, per_page: 100, merchant_pk: merchantPk };
    fetch(`${process.env.REACT_APP_API_BASE_URL}/merchantmdrs`, {
      method: 'POST', // or 'PUT'
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        setAllMdrs(data.response_data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }
  return (
    <>
      <Card>
        <CardBody>
          <ToolkitProvider
            rowNumber={rowNumber}
            data={allMdrs}
            keyField="name"
            columns={[
              {
                dataField: "no",
                text: "#",
                sort: true,
                page: 1,
                formatter: (cell, row, index) => {
                  return ++index
                }
              },
              {
                dataField: "transtype_name",
                text: "Transtype Name",
                sort: true,
              },
              {
                dataField: "merchant_mdr_value",
                text: "MDR Value (%)",
                sort: true,
                formatter: (cell, row) => {
                  return row.merchant_mdr_value + "%"
                }
              },
              {
                dataField: "merchant_mdr_fee",
                text: "MDR Fee (%)",
                sort: true,
                formatter: (cell, row) => {
                  return row.merchant_mdr_fee + "%"
                }
              },
              {
                dataField: "merchant_mdr_status",
                text: "Mdr Status",
                sort: true,
                formatter: (cell, row, index) => {
                  console.log(row);
                  return row.merchant_mdr_status === 1 ? "Active" : row.merchant_mdr_status === 2 ? "Draft" : "Deactive"
                }
              },
            ]}
          >
            {(props) => (
              <div className="py-4 table-responsive">
                <BootstrapTable
                  {...props.baseProps}
                  bootstrap4={true}
                  bordered={false}
                  hover={true}
                  pagination={paginationFactory({ ...paginationOption })}
                  onTableChange
                />
              </div>
            )}
          </ToolkitProvider>
        </CardBody>
      </Card>
    </>
  );
}
