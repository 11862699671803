/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Label,
  FormGroup,
  Row,
  Col,
  Input,
  UncontrolledTooltip,
  Container,
  CardHeader,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane, Form, Collapse
} from "reactstrap";
import Select from 'react-select';
import classnames from 'classnames';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

import GroupMdr from "views/pages/customers/group/merchant_mdr/Index.js";
import GroupAcquirer from "views/pages/customers/group/merchant_dokumen/Index.js";

export default function GroupMerchant() {
  const token = localStorage.token;
  const merchantLevel = parseInt(localStorage.getItem("merchantLevel"));
  const role = localStorage.getItem("authority");
  const merchantPk = parseInt(localStorage.getItem("merchantPk"));
  const [unitKerja, setUnitKerja] = useState("");
  const [listUnitKerja, setListUnitKerja] = useState([]);
  const [groupsByProject, setGroupByProject] = useState([]);
  const [groups, setGroups] = useState([]);
  const [alert, setAlert] = React.useState(null);
  const redirectPrefix = `/admin/customers/daftar-group/edit/`;
  const [hide, setHide] = useState(true);
  const [selectMerchantPk, setSelectedMerchantPk] = useState(0);
  const [selectGroupName, setSelectGroupName] = useState("");
  const [activeTab, setActiveTab] = useState('1');
  const [listGroups, setListGroups] = useState([]);
  const [group, setGroup] = useState("");
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("");
  const [listStatus, setListStatus] = useState([]);
  const [status, setStatus] = useState("");
  const [projects, setProjects] = useState([]);
  const [project, setProject] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [rowIndex, setRowIndex] = useState(0);
  const [isCheckedIn] = useState(false);
  const allowedRoles = ["ROLE_OPERATOR_BUSDEV", "ROLE_BOD", "ROLE_OPERATOR_FINANCE", "ROLE_SUPERVISOR_FINANCE"];
  const allowedRolesProject = ["ROLE_OPERATOR_PROJECT", "ROLE_SUPERVISOR_PROJECT", "ROLE_ADMIN_PROJECT"];
  const allowedAdmin = ["ROLE_SUPERADMIN"];
  const [isOpen, setIsOpen] = useState(false);
  const open = () => setIsOpen(!isOpen);

  const [page, setPage] = useState(1);
  const [perPage, setPerpage] = useState(10);
  const [totalItem, setTotalItem] = useState(0);
  const [currentSort, setCurrentSort] = useState("");

  let paginationOption = {
    page: page,
    alwaysShowAllBtns: true,
    override: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPage: perPage,
    totalSize: totalItem,
    onPageChange: (page) => {
      updateDataTable(page, perPage, currentSort);
    },
    sizePerPageRenderer: () => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                updateDataTable(page, e.target.value, currentSort)
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  }

  const updateDataTable = (page, perPage, sort) => {
    getAllMerchantGroup(page, perPage, sort);
    getAllMerchantGroupByProject(page, perPage, sort);
    setPage(page);
    setPerpage(perPage);
    setRowIndex((page - 1) * perPage);
    setCurrentSort(sort);
  }

  const handleTableChange = (type, { sortField, sortOrder }) => {
    if (type === "sort") {
      let sort = `${sortField} ${sortOrder}`
      updateDataTable(page, perPage, sort)
    }
  }

  useEffect(() => {
    if (allowedAdmin.includes(localStorage.getItem("authority"))) {
      getAllMerchantGroup();
    } else {
      getAllMerchantGroupByProject();
    }
    getCategories();
    getProjects();
    getStatus();
    if (allowedRolesProject.includes(localStorage.getItem("authority"))) {
      getGroup();
      getUniKerja();
    }
  }, []);

  const getAllMerchantGroup = (page, perPage, currentSort, merchant_group_category_id = 0, merchant_project_pk = 0, merchant_group_pk = 0, merchant_group_status = 0, workUnitPk = 0) => {
    let filter = { page: page, per_page: perPage }
    if (merchant_group_category_id !== 0) {
      filter = Object.assign(filter, { merchant_group_category_id: merchant_group_category_id })
    }
    if (merchant_group_pk !== 0) {
      filter = Object.assign(filter, { merchant_group_pk: merchant_group_pk })
    }
    if (merchant_project_pk !== 0) {
      filter = Object.assign(filter, { merchant_project_pk: merchant_project_pk })
    }
    if (merchant_group_status !== 0) {
      filter = Object.assign(filter, { merchant_group_status: merchant_group_status })
    }
    if (workUnitPk !== 0) {
      filter = Object.assign(filter, { workUnitPk: workUnitPk })
    }
    const data = filter;
    fetch(`${process.env.REACT_APP_API_BASE_URL}/merchant/groups`, {
      method: 'POST', // or 'PUT'
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        setGroups(data.response_data);
        setPage(data.current_page + 1);
        setPerpage(data.per_page);
        setTotalItem(data.total_item);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const getAllMerchantGroupByProject = (page, perPage, currentSort, merchant_group_category_id = 0, merchant_project_pk = 0, merchant_group_pk = 0, merchant_group_status = 0, workUnitPk = 0) => {
    let filter = { page: page, per_page: perPage, role: role, projectId: merchantPk }
    if (merchant_group_category_id !== 0) {
      filter = Object.assign(filter, { merchant_group_category_id: merchant_group_category_id })
    }
    if (merchant_group_pk !== 0) {
      filter = Object.assign(filter, { merchant_group_pk: merchant_group_pk })
    }
    if (merchant_project_pk !== 0) {
      filter = Object.assign(filter, { merchant_project_pk: merchant_project_pk })
    }
    if (merchant_group_status !== 0) {
      filter = Object.assign(filter, { merchant_group_status: merchant_group_status })
    }
    if (workUnitPk !== 0) {
      filter = Object.assign(filter, { workUnitPk: workUnitPk })
    }
    const data = filter;
    fetch(`${process.env.REACT_APP_API_BASE_URL}/merchant/groups`, {
      method: 'POST', // or 'PUT'
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        setGroupByProject(data.response_data);
        setPage(data.current_page + 1);
        setPerpage(data.per_page);
        setTotalItem(data.total_item);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const getProjects = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/projects/list`, {
        method: 'GET', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(),
      })
      const data = await response.json();
      let options = [];
      data.response_data.map((project) => {
        return options.push({ value: project.merchant_pk, label: project.merchant_name })
      })
      setProjects(options);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const getGroup = async (merchant_pk) => {
    if (allowedAdmin.includes(localStorage.getItem("authority"))) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/merchants/groups/list?project_id=${merchant_pk}`, {
          method: 'GET', // or 'PUT'
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(),
        })
        const data = await response.json();
        let options = [];
        data.response_data.map((group) => {
          return options.push({ value: group.merchant_pk, label: group.merchant_name })
        })
        setListGroups(options);
      } catch (error) {
        console.error('Error:', error);
      }
    }
    if (allowedRolesProject.includes(localStorage.getItem("authority"))) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/merchants/groups/list?project_id=${merchantPk}`, {
          method: 'GET', // or 'PUT'
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(),
        })
        const data = await response.json();
        let options = [];
        data.response_data.map((group) => {
          return options.push({ value: group.merchant_pk, label: group.merchant_name })
        })
        setListGroups(options);
      } catch (error) {
        console.error('Error:', error);
      }
    }
  }

  const getCategories = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/merchantCategories`, {
        method: 'GET', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(),
      })
      const data = await response.json();
      setCategories(data.response_data);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const getUniKerja = async (projectId) => {
    const body = {
      projectId: projectId
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/workunit/list`, {
        method: 'POST', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
      const data = await response.json();
      setListUnitKerja(data.response_data);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const getStatus = () => {
    const data = { type: "merchant" }
    fetch(`${process.env.REACT_APP_API_BASE_URL}/status`, {
      method: 'POST', // or 'PUT'
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        setListStatus(data.response_data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }

  const rowEvents = {
    onDoubleClick: (e, row, rowIndex) => {
      setHide(false);
      setSelectedMerchantPk(row.merchant_pk);
      setSelectGroupName(row.merchant_name);
    }
  };

  const handleSelectedItem = (element) => {
    setProject(element);
    getUniKerja(element.value);
    getGroup(element.value);
  }

  const handleSelectedGroup = (element) => {
    setGroup(element);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    getAllMerchantGroup(page, perPage, currentSort, parseInt(category), parseInt(project.value), parseInt(group.value), parseInt(status), parseInt(unitKerja));
    getAllMerchantGroupByProject(page, perPage, currentSort, parseInt(category), parseInt(project.value), parseInt(group.value), parseInt(status), parseInt(unitKerja));
  }

  const reset = () => {
    setGroup("");
    setProject("");
    setCategory("");
    setStatus("");
    setUnitKerja("");
    getAllMerchantGroup();
    getAllMerchantGroupByProject();
  }

  return (
    <>
      {alert}
      <SimpleHeader name="Group" parentName="Admin" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <h3>Daftar Group</h3>
                  {
                    allowedRoles.includes(localStorage.getItem("authority"))
                      ?
                      <div style={{ textAlign: "right" }}>
                        <Link className="btn btn-info" to="/admin/customers/daftar-group/create">
                          Add
                        </Link>
                      </div>
                      :
                      allowedAdmin.includes(localStorage.getItem("authority"))
                        ?
                        <div style={{ textAlign: "right" }}>
                          <Link className="btn btn-info" to="/admin/customers/daftar-group/create">
                            Add
                        </Link>
                        </div>
                        :
                        <div></div>
                  }
                </div>
              </CardHeader>
              <CardBody>
                <h3 onClick={open} >Filter &nbsp;
                  {
                    isOpen === true ? <i className="fa fa-angle-down" aria-hidden="true"></i> : <i className="fa fa-angle-right" aria-hidden="true"></i>
                  }
                </h3>
                <Collapse isOpen={isOpen}>
                  {
                    allowedRolesProject.includes(localStorage.getItem("authority"))
                      ?
                      <Form onSubmit={handleSubmit}>
                        <Row md="12">
                          <Col md="6">
                            <FormGroup>
                              <Label
                                className="form-control-label"
                                htmlFor="exampleFormControlInput1"
                              >
                                Nama Group
                              </Label>
                              <Select
                                className="group-select"
                                classNamePrefix="select"
                                onChange={e => handleSelectedGroup(e)}
                                value={group}
                                isDisabled={isCheckedIn}
                                options={listGroups}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect3">Unit Kerja</Label>
                              <Input
                                id="exampleFormControlSelect3"
                                name="unitKerja"
                                type="select"
                                value={unitKerja}
                                onChange={e => setUnitKerja(e.target.value)}
                              >
                                <option value="0">Pilih Unit Kerja</option>
                                {
                                  listUnitKerja.map((unit, key) => {
                                    return <option key={key} value={unit.workUnitPk}>{unit.workUnitName}</option>
                                  })
                                }
                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect3">Kategori Group</Label>
                              <Input
                                id="exampleFormControlSelect3"
                                name="category"
                                type="select"
                                value={category}
                                onChange={e => setCategory(e.target.value)}
                              >
                                <option value="0">Pilih Kategori Group</option>
                                {
                                  categories.map((category, key) => {
                                    return <option key={key} value={category.merchantCategoryId}>{category.merchantCategoryName}</option>
                                  })
                                }
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect3">Status Group</Label>
                              <Input
                                id="exampleFormControlSelect3"
                                name="projects"
                                type="select"
                                value={status}
                                onChange={e => setStatus(e.target.value)}
                              >
                                <option value="0">Pilih Status Group</option>
                                {
                                  listStatus.map((stat, key) => {
                                    return <option key={key} value={stat.value}>{stat.description}</option>
                                  })
                                }
                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Button type="submit" color="primary">Search</Button>
                            <Button type="button" onClick={reset} color="secondary">Reset</Button>
                          </Col>
                        </Row>
                      </Form>
                      :
                      <Form onSubmit={handleSubmit}>
                        <Row md="12">
                          <Col md="4">
                            <FormGroup>
                              <Label
                                className="form-control-label"
                                htmlFor="exampleFormControlInput1"
                              >
                                Nama Project
                                    </Label>
                              <Select
                                className="project-select"
                                classNamePrefix="select"
                                onChange={e => handleSelectedItem(e)}
                                value={project}
                                isDisabled={isCheckedIn}
                                options={projects}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label
                                className="form-control-label"
                                htmlFor="exampleFormControlInput1"
                              >
                                Nama Group
                        </Label>
                              <Select
                                className="group-select"
                                classNamePrefix="select"
                                onChange={e => handleSelectedGroup(e)}
                                value={group}
                                isDisabled={isCheckedIn}
                                options={listGroups}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect3">Unit Kerja</Label>
                              <Input
                                id="exampleFormControlSelect3"
                                name="unitKerja"
                                type="select"
                                value={unitKerja}
                                onChange={e => setUnitKerja(e.target.value)}
                              >
                                <option value="0">Pilih Unit Kerja</option>
                                {
                                  listUnitKerja.map((unit, key) => {
                                    return <option key={key} value={unit.workUnitPk}>{unit.workUnitName}</option>
                                  })
                                }
                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect3">Kategori Group</Label>
                              <Input
                                id="exampleFormControlSelect3"
                                name="category"
                                type="select"
                                value={category}
                                onChange={e => setCategory(e.target.value)}
                              >
                                <option value="0">Pilih Kategori Group</option>
                                {
                                  categories.map((category, key) => {
                                    return <option key={key} value={category.merchantCategoryId}>{category.merchantCategoryName}</option>
                                  })
                                }
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect3">Status Group</Label>
                              <Input
                                id="exampleFormControlSelect3"
                                name="projects"
                                type="select"
                                value={status}
                                onChange={e => setStatus(e.target.value)}
                              >
                                <option value="0">Pilih Status Group</option>
                                {
                                  listStatus.map((stat, key) => {
                                    return <option key={key} value={stat.value}>{stat.description}</option>
                                  })
                                }
                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Button type="submit" color="primary">Search</Button>
                            <Button type="button" onClick={reset} color="secondary">Reset</Button>
                          </Col>
                        </Row>
                      </Form>
                  }
                </Collapse>
                {
                  allowedAdmin.includes(localStorage.getItem("authority"))
                    ?
                    <ToolkitProvider
                      rowNumber={rowIndex}
                      data={groups}
                      keyField="merchant_pk"
                      columns={[
                        {
                          dataField: "no",
                          text: "#",
                          sort: true,
                          page: 1,
                          formatter: (cell, row, index) => {
                            let currentRow = ++index;
                            return currentRow + rowIndex;
                          }
                        },
                        {
                          dataField: "merchant_code",
                          text: "Group Code",
                          sort: true,
                        },
                        {
                          dataField: "projectName",
                          text: "Nama Project",
                          sort: true,
                        },
                        {
                          dataField: "merchant_name",
                          text: "Nama Group",
                          sort: true,
                        },
                        {
                          dataField: "merchant_category",
                          text: "Kategori Group",
                          sort: true,
                        },
                        {
                          dataField: "merchant_count",
                          text: "Jumlah Merchant",
                          sort: true,
                        },
                        {
                          dataField: "terminal_count",
                          text: "Jumlah Edc",
                          sort: true,
                        },
                        {
                          dataField: "merchant_status",
                          text: "Status Group",
                          sort: true,
                          formatter: (cell, row, index) => {
                            return row.merchant_status === 1 ? "Active" : row.merchant_status === 2 ? "Draf" : "Deactive"
                          }
                        },
                        {
                          dataField: "", text: "", formatter: (cell, row, index) => {
                            return (
                              <>
                                <Link
                                  className="btn btn-primary btn-sm"
                                  to={redirectPrefix + row.merchant_pk}
                                  id={"tooltip_" + row.merchant_pk}
                                >
                                  <i className="fas fa-pencil-alt" />
                                </Link>
                                <UncontrolledTooltip delay={0} target={"tooltip_" + row.merchant_pk}>
                                  Edit
                            </UncontrolledTooltip>
                              </>
                            )
                          }
                        },
                      ]}
                    >
                      {(props) => (
                        <div className="py-4 table-responsive">
                          <BootstrapTable
                            remote
                            {...props.baseProps}
                            bootstrap4={true}
                            bordered={false}
                            hover={true}
                            rowEvents={rowEvents}
                            pagination={paginationFactory({ ...paginationOption })}
                            onTableChange={handleTableChange}
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                    :
                    <ToolkitProvider
                      rowNumber={rowIndex}
                      data={groupsByProject}
                      keyField="merchant_pk"
                      columns={[
                        {
                          dataField: "no",
                          text: "#",
                          sort: true,
                          page: 1,
                          formatter: (cell, row, index) => {
                            let currentRow = ++index;
                            return currentRow + rowIndex;
                          }
                        },
                        {
                          dataField: "merchant_code",
                          text: "Group Code",
                          sort: true,
                        },
                        {
                          dataField: "merchant_name",
                          text: "Nama Group",
                          sort: true,
                        },
                        {
                          dataField: "merchant_category",
                          text: "Kategori Group",
                          sort: true,
                        },
                        {
                          dataField: "merchant_count",
                          text: "Jumlah Merchant",
                          sort: true,
                        },
                        {
                          dataField: "terminal_count",
                          text: "Jumlah Edc",
                          sort: true,
                        },
                        {
                          dataField: "merchant_status",
                          text: "Status Group",
                          sort: true,
                          formatter: (cell, row, index) => {
                            return row.merchant_status === 1 ? "Active" : row.merchant_status === 2 ? "Draf" : "Deactive"
                          }
                        }
                      ]}
                    >
                      {(props) => (
                        <div className="py-4 table-responsive">
                          <BootstrapTable
                            remote
                            {...props.baseProps}
                            bootstrap4={true}
                            bordered={false}
                            hover={true}
                            rowEvents={rowEvents}
                            pagination={paginationFactory({ ...paginationOption })}
                            onTableChange={handleTableChange}
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                }
              </CardBody>
            </Card>
            <Card>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '1' })}
                    onClick={() => { toggle('1'); }}
                  >
                    Merchant MDR
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '2' })}
                    onClick={() => { toggle('2'); }}
                  >
                    Berkas Dokumen
                  </NavLink>
                </NavItem>
              </Nav>
              {hide ? <></> : <>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <Row>
                      <Col md="12">
                        <GroupMdr merchantPk={selectMerchantPk} groupName={selectGroupName} />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row>
                      <Col md="12">
                        <GroupAcquirer merchantPk={selectMerchantPk} groupName={selectGroupName} />
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>

              </>
              }
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
