/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

export default function SettlementDetail(props) {
  const settleId = props.settleId;
  const [settlementDetail, setSettlementDetail] = useState([]);
  let [rowNumber, setRowNumber] = useState([]);

  let paginationOption = {
    page: 1,
    alwaysShowAllBtns: true,
    override: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => onSizePerPageChange(e.target.value)}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          }{" "}
      entries.
    </label>
      </div>
    ),
  }

  useEffect(() => {
    getAllSettlementDetail();
  }, []);

  const getAllSettlementDetail = () => {
    const data = { page: 1, per_page: 100, settlelot_id: settleId, };
    fetch(`${process.env.REACT_APP_API_BASE_URL}/settlementacqs`, {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        setSettlementDetail(data.response_data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  return (
    <>
      <Card>
        <CardHeader>
          <h3>List Settlement Detail</h3>
        </CardHeader>
        <CardBody>
          <ToolkitProvider
            rowNumber={rowNumber}
            data={settlementDetail}
            keyField="name"
            columns={[
              {
                dataField: "no",
                text: "#",
                sort: true,
                page: 1,
                formatter: (cell, row, index) => {
                  return ++index
                }
              },
              {
                dataField: "transaction_type",
                text: "Transaction Type",
                sort: true
              },
              {
                dataField: "sale_count",
                text: "Sale Count",
                sort: true
              },
              {
                dataField: "sale_amount",
                text: "Total Amount",
                sort: true
              },
              {
                dataField: "earned_fee",
                text: "Earned Fee",
                sort: true
              }
            ]}
          >
            {(props) => (
              <div className="py-4 table-responsive">
                <BootstrapTable
                  {...props.baseProps}
                  bootstrap4={true}
                  bordered={false}
                  pagination={paginationFactory({ ...paginationOption })}
                  onTableChange
                />
              </div>
            )}
          </ToolkitProvider>
        </CardBody>
      </Card>
    </>
  );
}
