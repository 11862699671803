/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Label,
  FormGroup,
  Row,
  Col,
  Input,
  UncontrolledTooltip,
  Container,
  CardHeader,
  Button, Nav, NavItem, NavLink, TabContent, TabPane, Form
} from "reactstrap";
import classnames from 'classnames';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

import ProjectMdr from "views/pages/customers/project/merchant_mdr/Index.js";
import ProjectAcquirer from "views/pages/customers/project/merchant_dokumen/Index.js";
import UnitKerja from "views/pages/customers/project/unit_kerja/Index.js";

export default function ProjectMerchant() {
  const token = localStorage.token;
  const merchantLevel = localStorage.getItem("merchantLevel");
  const [projects, setProjects] = useState([]);
  const [alert, setAlert] = React.useState(null);
  const redirectPrefix = `/admin/customers/daftar-project/edit/`;
  const [hide, setHide] = useState(true);
  const [selectMerchantPk, setSelectedMerchantPk] = useState(0);
  const [selectProjectName, setSelectProjectName] = useState("");
  const [activeTab, setActiveTab] = useState('1');
  const [listProjects, setListProjects] = useState([]);
  const [project, setProject] = useState("");
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("");
  const [status, setStatus] = useState("");
  const [listStatus, setListStatus] = useState([]);
  const [rowIndex, setRowIndex] = useState(0);
  const allowedRoles = ["ROLE_SUPERADMIN", "ROLE_OPERATOR_BUSDEV", "ROLE_BOD", "ROLE_OPERATOR_FINANCE", "ROLE_SUPERVISOR_FINANCE"]

  const [page, setPage] = useState(1);
  const [perPage, setPerpage] = useState(10);
  const [totalItem, setTotalItem] = useState(0);
  const [currentSort, setCurrentSort] = useState("");

  useEffect(() => {
    // component did mount
    getAllMerchant(page, perPage, currentSort, "", "", "");
    getProject();
    getCategories();
    getStatus();
    return () => {
      // cleanup
    }
  }, [])

  let paginationOption = {
    page: page,
    alwaysShowAllBtns: true,
    override: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPage: perPage,
    totalSize: totalItem,
    onPageChange: (page) => {
      updateDataTable(page, perPage, currentSort, category, project, status);
    },
    sizePerPageRenderer: () => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                updateDataTable(page, e.target.value, currentSort, category, project, status)
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  }

  const updateDataTable = (page, perPage, sort, category, project, status) => {
    getAllMerchant(page, perPage, sort, parseInt(category), parseInt(project), parseInt(status));
    setPage(page);
    setPerpage(perPage);
    setRowIndex((page - 1) * perPage);
    setCurrentSort(sort);
    setCategory(category);
    setProject(project);
    setStatus(status);
  }

  const handleTableChange = (type, { sortField, sortOrder }) => {
    if (type === "sort") {
      let sort = `${sortField} ${sortOrder}`
      updateDataTable(page, perPage, sort, category, project, status)
    }
  }

  const getAllMerchant = (page, perPage, currentSort, project_category_id = 0, project_pk = 0, project_status = 0) => {
    let filter = { page: page, per_page: perPage }
    if (project_category_id !== 0) {
      filter = Object.assign(filter, { project_category_id: project_category_id })
    }
    if (project_pk !== 0) {
      filter = Object.assign(filter, { project_pk: project_pk })
    }
    if (project_status !== 0) {
      filter = Object.assign(filter, { project_status: project_status })
    }
    const data = filter;
    fetch(`${process.env.REACT_APP_API_BASE_URL}/projects`, {
      method: 'POST', // or 'PUT'
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        setProjects(data.response_data);
        setPage(data.current_page);
        setPerpage(data.per_page);
        setTotalItem(data.total_item);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const getProject = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/projects/list`, {
      method: 'GET', // or 'PUT'
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(),
    })
      .then(response => response.json())
      .then(data => {
        setListProjects(data.response_data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const getCategories = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/merchantCategories`, {
        method: 'GET', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(),
      })
      const data = await response.json();
      setCategories(data.response_data);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const getStatus = () => {
    const data = { type: "merchant" }
    fetch(`${process.env.REACT_APP_API_BASE_URL}/status`, {
      method: 'POST', // or 'PUT'
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        setListStatus(data.response_data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }

  const rowEvents = {
    onDoubleClick: (e, row, rowIndex) => {
      setHide(false);
      setSelectedMerchantPk(row.merchant_pk);
      setSelectProjectName(row.merchant_name);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    getAllMerchant(parseInt(category), parseInt(project), parseInt(status));
  }

  const reset = () => {
    setProject("");
    setCategory("");
    setStatus("");
    getAllMerchant();
  }


  return (
    <>
      {alert}
      <SimpleHeader name="project" parentName="Admin" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <h3>Daftar Project</h3>
                  {
                    allowedRoles.includes(localStorage.getItem("authority"))
                      ?
                      <div style={{ textAlign: "right" }}>
                        <Link className="btn btn-info" to="/admin/customers/daftar-project/create">
                          Add
                        </Link>
                      </div>
                      :
                      <div></div>
                  }
                </div>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row md="12">
                    <Col md="4">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect3">Nama Project</Label>
                        <Input
                          id="exampleFormControlSelect3"
                          name="projects"
                          type="select"
                          value={project}
                          onChange={e => updateDataTable(page, perPage, currentSort, category, e.target.value)}
                        >
                          <option value="0">Pilih Nama Project</option>
                          {
                            listProjects.map((project, key) => {
                              return <option key={key} value={project.merchant_pk}>{project.merchant_name}</option>
                            })
                          }
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect3">Kategori Project</Label>
                        <Input
                          id="exampleFormControlSelect3"
                          name="category"
                          type="select"
                          value={category}
                          onChange={e => updateDataTable(page, perPage, currentSort, e.target.value)}
                        >
                          <option value="0">Pilih Kategori Project</option>
                          {
                            categories.map((category, key) => {
                              return <option key={key} value={category.merchantCategoryId}>{category.merchantCategoryName}</option>
                            })
                          }
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect3">Status Project</Label>
                        <Input
                          id="exampleFormControlSelect3"
                          name="projects"
                          type="select"
                          value={status}
                          onChange={e => updateDataTable(page, perPage, currentSort, category, project, e.target.value)}
                        >
                          <option value="0">Pilih Status Project</option>
                          {
                            listStatus.map((stat, key) => {
                              return <option key={key} value={stat.value}>{stat.description}</option>
                            })
                          }
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button type="button" onClick={reset} color="secondary">Reset</Button>
                    </Col>
                  </Row>
                </Form>
                <ToolkitProvider
                  rowNumber={rowIndex}
                  data={projects}
                  keyField="merchant_pk"
                  columns={[
                    {
                      dataField: "no",
                      text: "#",
                      sort: true,
                      page: 1,
                      formatter: (cell, row, index) => {
                        let currentRow = ++index;
                        return currentRow + rowIndex;
                      }
                    },
                    {
                      dataField: "merchant_code",
                      text: "Project Code",
                      sort: true,
                    },
                    {
                      dataField: "merchant_name",
                      text: "Nama Project",
                      sort: true,
                    },
                    {
                      dataField: "merchant_category",
                      text: "Kategori Project",
                      sort: true,
                    },
                    {
                      dataField: "group_count",
                      text: "Jumlah Group",
                      sort: true,
                    },
                    {
                      dataField: "merchant_count",
                      text: "Jumlah Merchant",
                      sort: true,
                    },
                    {
                      dataField: "merchant_status",
                      text: "Status Project",
                      sort: true,
                      formatter: (cell, row, index) => {
                        return row.merchant_status === 1 ? "Active" : row.merchant_status === 2 ? "Draft" : "Deactive"
                      }
                    },
                    {
                      dataField: "", text: "", formatter: (cell, row, index) => {
                        return (
                          <>
                            <Link
                              className="btn btn-primary btn-sm"
                              to={redirectPrefix + row.merchant_pk}
                              id={"tooltip_" + row.merchant_pk}
                            >
                              <i className="fas fa-pencil-alt" />
                            </Link>
                            <UncontrolledTooltip delay={0} target={"tooltip_" + row.merchant_pk}>
                              Edit
                            </UncontrolledTooltip>
                          </>
                        )
                      }
                    },
                  ]}
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      <BootstrapTable
                        remote
                        {...props.baseProps}
                        bootstrap4={true}
                        bordered={false}
                        hover={true}
                        rowEvents={rowEvents}
                        pagination={paginationFactory({ ...paginationOption })}
                        onTableChange={handleTableChange}
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </CardBody>
            </Card>
            <Card>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '1' })}
                    onClick={() => { toggle('1'); }}
                  >
                    Merchant MDR
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '2' })}
                    onClick={() => { toggle('2'); }}
                  >
                    Berkas Dokumen
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '3' })}
                    onClick={() => { toggle('3'); }}
                  >
                    Unit Kerja
                  </NavLink>
                </NavItem>
              </Nav>
              {hide ? <></> : <>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <Row>
                      <Col md="12">
                        <ProjectMdr merchantPk={selectMerchantPk} projectName={selectProjectName} />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row>
                      <Col md="12">
                        <ProjectAcquirer merchantPk={selectMerchantPk} projectName={selectProjectName} />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="3">
                    <Row>
                      <Col md="12">
                        <UnitKerja merchantPk={selectMerchantPk} projectName={selectProjectName} />
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>

              </>
              }
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
