/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Label,
  FormGroup,
  Row,
  Input,
  Container,
  CardHeader,
  CardFooter,
  Button,
  Form
} from "reactstrap";
import axios from 'axios';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

export default function CreateBinRange(props) {
  const [formData, setFormData] = React.useState({
    bin_name: "",
    pan_lo: "",
    pan_hi: "",
    card_type: "",
    bank_code: "",
    principal_id: ""
  });
  const [banks, setBanks] = React.useState([]);
  const [principals, setPrincipals] = useState([]);
  let history = useHistory();

  useEffect(() => {
    getBanks();
    getPrincipal();
  }, []);

  const getBanks = () => {
    const data = { page: 1, per_page: 100 };
    fetch(`${process.env.REACT_APP_API_BASE_URL}/banks`, {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        setBanks(data.response_data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const getPrincipal = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/principals`, {
      method: 'GET', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(),
    })
      .then(response => response.json())
      .then(data => {
        setPrincipals(data.response_data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value })
  }

  function CreateData() {
    let data = formData
    data.principal_id = parseInt(data.principal_id)
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/binrange/save`, data)
      .then(function (response) {
        history.push("/admin/bin-range");
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    CreateData();
  }
  return (
    <>
      <SimpleHeader name="Create Bin Range" parentName="Admin" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardBody>
                <Form onSubmit={handleSubmit} >
                  <CardHeader>
                    <h3>Create Bin Range</h3>
                  </CardHeader>
                  <CardBody>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="bin_name"
                      >
                        Bin Name
                            </Label>
                      <Input
                        type="text"
                        name="bin_name"
                        placeholder="Masukan Bin Name"
                        value={formData.bin_name}
                        onChange={handleChange}
                      />
                      <div className="invalid-feedback">
                        Masukan Bin Name
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        PAN LO
                            </Label>
                      <Input
                        type="text"
                        name="pan_lo"
                        placeholder="Masukan PAN LO"
                        value={formData.pan_lo}
                        onChange={handleChange}
                      />
                      <div className="invalid-feedback">
                        Masukan Pan Lo
                            </div>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        PAN HI
                            </Label>
                      <Input
                        type="text"
                        name="pan_hi"
                        placeholder="Masukan PAN HI"
                        value={formData.pan_hi}
                        onChange={handleChange}
                      />
                      <div className="invalid-feedback">
                        Masukan Pan Hi
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlSelect3"
                      >
                        Issuer Name
                      </Label>
                      <Input
                        id="exampleFormControlSelect3"
                        name="bank_code"
                        type="select"
                        value={formData.bank_code}
                        onChange={handleChange}
                      >
                        <option value="0">Pilih Issuer Name</option>
                        {
                          banks.map((bank, key) => {
                            return <option key={key} value={bank.bank_code}>{bank.bank_name}</option>
                          })
                        }
                      </Input>
                      <div className="invalid-feedback">
                        Pilih Issuer Name
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        Card Type
                            </Label>
                      <Input
                        type="text"
                        name="card_type"
                        placeholder="Masukan Card Type"
                        value={formData.card_type}
                        onChange={handleChange}
                      />
                      <div className="invalid-feedback">
                        Masukan Card Type
                            </div>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        Principal
                      </Label>
                      <Input
                        id="exampleFormControlSelect3"
                        name="principal_id"
                        type="select"
                        value={formData.principal_id}
                        onChange={handleChange}
                      >
                        <option value={0}>Pilih Principal</option>
                        {
                          principals.map((principal, key) => {
                            return <option key={key} value={principal.principal_id}>{principal.principal_name}</option>
                          })
                        }
                      </Input>
                    </FormGroup>
                  </CardBody>
                  <CardFooter>
                    <Button color="primary" type="submit">
                      Simpan
                    </Button>
                    <Link class="btn btn-info" to="/admin/bin-range">
                      Kembali
                    </Link>
                  </CardFooter>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
