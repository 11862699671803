/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  Container,
  CardHeader,
  Nav,
  NavItem, 
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";
import classnames from 'classnames';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

import DetailSettlement from "views/pages/settlement/detail/Index.js";

export default function DaftarSettlement() {
  let [rowNumber, setRowNumber] = useState([]);
  const [allSettlement, setAllSettlement] = useState([]);
  const [activeTab, setActiveTab] = useState('1');
  const [hide, setHide] = useState(true);
  const [selectSettleId, setSelectSettleId] = useState(0);

  let paginationOption = {
    page: 1,
    alwaysShowAllBtns: true,
    override: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => onSizePerPageChange(e.target.value)}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  }

  useEffect(() => {
    getAllSettlement();
  }, []);

  const getAllSettlement = () => {
    const data = { page: 1, per_page: 100 };
    fetch(`${process.env.REACT_APP_API_BASE_URL}/settlelots`, {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        setAllSettlement(data.response_data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }

  const rowEvents = {
    onDoubleClick: (e, row, rowIndex) => {
      setHide(false);
      setSelectSettleId(row.settlelot_id);
    }
  };

  return (
    <>
      <SimpleHeader name="Daftar Transaksi" parentName="Admin" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3>List Daftar Settlement</h3>
              </CardHeader>
              <CardBody>
                <ToolkitProvider
                  rowNumber={rowNumber}
                  data={allSettlement}
                  keyField="name"
                  columns={[
                    {
                      dataField: "no",
                      text: "#",
                      sort: true,
                      page: 1,
                      formatter: (cell, row, index) => {
                        return ++index
                      }
                    },
                    {
                      dataField: "merchant_name",
                      text: "Merchant Name",
                      sort: true,
                    },
                    {
                      dataField: "sale_count",
                      text: "Sale Count",
                      sort: true,
                    },
                    {
                      dataField: "void_amount",
                      text: "Total Amount",
                      sort: true,
                    },
                    {
                      dataField: "earned_fee",
                      text: "Earned Fee",
                      sort: true,
                    },
                  ]}
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      <BootstrapTable
                        {...props.baseProps}
                        bootstrap4={true}
                        bordered={false}
                        hover={true}
                        rowEvents={rowEvents}
                        pagination={paginationFactory({ ...paginationOption })}
                        onTableChange
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </CardBody>
            </Card>
            <Card>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '1' })}
                    onClick={() => { toggle('1'); }}
                  >
                    Detail
                                </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <Row>
                    <Col md="12">
                      {hide ? <></> :
                        <DetailSettlement settleId={selectSettleId} />
                      }
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
