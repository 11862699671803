/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Label,
  FormGroup,
  Row,
  Input,
  Container,
  CardHeader,
  CardFooter,
  Button,
  Form, FormFeedback
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import axios from 'axios';
import moment from 'moment';
import SimpleHeader from "components/Headers/SimpleHeader.js";

export default function CreateAcquirer(props) {
  const token = localStorage.getItem("token");
  const [merchant_pk] = useState(props.match.params.id);
  const [acquirerId, setAcquirerId] = useState("");
  const [subbmited, setSubbmited] = useState("");
  const [acquirers, setAcquirers] = useState([]);
  let history = useHistory();

  const [acquirerNameError, setAcquirerNameError] = useState(null);
  const [subbmitedError, setSubmittedError] = useState(null);

  const validateForm = () => {
    let error = false;
    if (acquirerId === "") {
      setAcquirerNameError("invalid");
      error = true;
    }
    if (subbmited === "") {
      setSubmittedError("invalid");
      error = true;
    }
    return error;
  };

  useEffect(() => {
    getAcquirer();
  }, []);

  const getAcquirer = () => {
    const data = { page: 1, per_page: 100 };
    fetch(`${process.env.REACT_APP_API_BASE_URL}/acquirers`, {
      method: 'POST', // or 'PUT'
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        setAcquirers(data.response_data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  function CreateData() {
    let data = {
      merchant_pk: parseInt(merchant_pk),
      acquirer_id: parseInt(acquirerId),
      submitted: moment(subbmited).format("DD/MM/yyyy")
    }
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/mcacquirer/save`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then(function (response) {
        history.push("/admin/customers/daftar-merchant");
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      CreateData();
    }
  }
  return (
    <>
      <SimpleHeader name="Daftar Berkas Dokumen" parentName="Admin" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardBody>
                <Form onSubmit={handleSubmit} >
                  <CardHeader>
                    <h3>Daftar Berkas Dokumen</h3>
                  </CardHeader>
                  <CardBody>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="serial_number"
                      >
                        Acquirer Name
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        id="exampleFormControlSelect3"
                        name="transaction_type"
                        type="select"
                        value={acquirerId}
                        invalid={acquirerNameError === "invalid"}
                        onChange={(e) => {
                          setAcquirerId(e.target.value);
                          if (e.target.value !== "") {
                            setAcquirerNameError("");
                          }
                        }}
                      >
                        <option value="">Pilih Acquirer Name</option>
                        {
                          acquirers.map((acquirer, key) => {
                            return <option key={key} value={acquirer.acquirer_id}>{acquirer.acquirer_name}</option>
                          })
                        }
                      </Input>
                      <FormFeedback>Acquirer name tidak boleh kosong</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        Subbmited
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        id="example-date-input"
                        type="date"
                        value={subbmited}
                        invalid={subbmitedError === "invalid"}
                        onChange={(e) => {
                          setSubbmited(e.target.value);
                          if (e.target.value !== "") {
                            setSubmittedError("");
                          }
                        }}
                      />
                      <FormFeedback>Submited Tidak boleh kosong</FormFeedback>
                    </FormGroup>
                  </CardBody>
                  <CardFooter>
                    <Button color="primary" type="submit">
                      Simpan
                                        </Button>
                    <Link class="btn btn-info" to="/admin/customers/daftar-merchant">
                      Kembali
                                        </Link>
                  </CardFooter>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
