/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { Card, Row, CardBody, CardHeader, Container, UncontrolledTooltip, ButtonGroup } from 'reactstrap';
import { Link } from "react-router-dom";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import moment from 'moment';

const EdcBsi = (props) => {
  const token = localStorage.token;
  const redirectPrefix = `/admin/edc-bsi/edit/`;
  const [alert, setAlert] = React.useState(null);
  const [rowIndex, setRowIndex] = useState(0);
  const [edcBsi, setEdcBsi] = useState([]);

  const [page, setPage] = useState(1);
  const [perPage, setPerpage] = useState(10);
  const [totalItem, setTotalItem] = useState(0);
  const [currentSort, setCurrentSort] = useState("");

  let paginationOption = {
    page: page,
    alwaysShowAllBtns: true,
    override: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPage: perPage,
    totalSize: totalItem,
    onPageChange: (page) => {
      updateDataTable(page, perPage, currentSort);
    },
    sizePerPageRenderer: () => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                updateDataTable(page, e.target.value, currentSort)
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  }

  const updateDataTable = (page, perPage, sort) => {
    getEdcBsi(page, perPage, sort)
    setPage(page);
    setPerpage(perPage);
    setRowIndex((page - 1) * perPage);
    setCurrentSort(sort);
  }

  const handleTableChange = (type, { sortField, sortOrder }) => {
    if (type === "sort") {
      let sort = `${sortField} ${sortOrder}`
      updateDataTable(page, perPage, sort)
    }
  }

  useEffect(() => {
    getEdcBsi();
  }, []);

  const getEdcBsi = async (page, perPage, currentSort) => {
    const body = { page: page, per_page: perPage }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/edc-bsi`, {
        method: 'POST', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
      const data = await response.json();
      setEdcBsi(data.response_data);
      setPage(data.current_page + 1);
      setPerpage(data.per_page);
      setTotalItem(data.total_item);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  return (
    <div>
      {alert}
      <SimpleHeader name="Daftar EDC BSI" parentName="Admin" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <h3>Data EDC BSI</h3>
                </div>
              </CardHeader>
              <CardBody>
                <ToolkitProvider
                  rowNumber={rowIndex}
                  data={edcBsi}
                  keyField="id"
                  columns={[
                    {
                      dataField: "no",
                      text: "#",
                      sort: true,
                      page: 1,
                      formatter: (cell, row, index) => {
                        let currentRow = ++index;
                        return currentRow + rowIndex;
                      }
                    },
                    {
                      dataField: "serial_number",
                      text: "Serial Number",
                      sort: true,
                    },
                    {
                      dataField: "terminal_id",
                      text: "TID",
                      sort: true,
                    },
                    {
                      dataField: "merchant_id",
                      text: "MID",
                      sort: true,
                    },
                    {
                      dataField: "reason",
                      text: "Reason",
                      sort: true,
                    },
                    {
                      dataField: "last_request",
                      text: "Last Update",
                      sort: true,
                      formatter: (cell, row) => {
                        return (
                          <React.Fragment>
                            {row.last_request} <br />
                            {moment(row.updated_at).format("DD/MM/YYYY HH:MM")}
                          </React.Fragment>
                        )
                      }
                    },
                    {
                      dataField: "status",
                      text: "Status",
                      sort: true,
                    },
                    {
                      dataField: "", text: "", formatter: (cell, row, index) => {
                        return (
                          <ButtonGroup>
                            <Link
                              className="btn btn-primary btn-sm"
                              to={redirectPrefix + row.id}
                              id={"tooltip_" + row.id}
                            >
                              <i className="fas fa-pencil-alt" />
                            </Link>
                            <UncontrolledTooltip delay={0} target={"tooltip_" + row.id}>
                              Edit
                            </UncontrolledTooltip>
                          </ButtonGroup>
                        )
                      }
                    },
                  ]}
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      <BootstrapTable
                        remote
                        {...props.baseProps}
                        bootstrap4={true}
                        bordered={false}
                        hover={true}
                        pagination={paginationFactory({ ...paginationOption })}
                        onTableChange={handleTableChange}
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  );
}

export default EdcBsi;
