/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Label,
  FormGroup,
  Input,
} from "reactstrap";

export default function DataBank() {
  const token = localStorage.token;
  let merchantPk = localStorage.getItem("merchantPk");
  const [namaBank, setNamaBank] = useState("");
  const [nomorBank, setNomorBank] = useState("");
  const [pemilikRekening, setPemilikRekening] = useState("");
  const [cabang, setCabang] = useState("");

  useEffect(() => {
    getProfileByMk();
  }, []);

  const getProfileByMk = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/merchant/profile/${merchantPk}`, {
        method: 'GET', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(),
      })
      const data = await response.json();
      setNamaBank(data.response_data.bank_name);
      setNomorBank(data.response_data.account_number);
      setPemilikRekening(data.response_data.account_name);
      setCabang(data.response_data.bank_branch);
    } catch (error) {
      console.error('Error:', error);
    }
  }
  return (
    <>
      <Card>
        <CardBody>
          <FormGroup>
            <Label
              className="form-control-label"
              htmlFor="exampleFormControlInput1"
            >
              Nama Bank
                    </Label>
            <Input
              disabled="true"
              type="text"
              value={namaBank}
            />
          </FormGroup>
          <FormGroup>
            <Label
              className="form-control-label"
              htmlFor="exampleFormControlInput1"
            >
              Nomor Rekening
                    </Label>
            <Input
              disabled="true"
              type="text"
              value={nomorBank}
            />
          </FormGroup>
          <FormGroup>
            <Label
              className="form-control-label"
              htmlFor="exampleFormControlInput1"
            >
              Nama Pemilik Rekening
                    </Label>
            <Input
              disabled="true"
              type="text"
              value={pemilikRekening}
            />
          </FormGroup>
          <FormGroup>
            <Label
              className="form-control-label"
              htmlFor="exampleFormControlInput1"
            >
              Cabang
                    </Label>
            <Input
              disabled="true"
              type="text"
              value={cabang}
            />
          </FormGroup>
        </CardBody>
      </Card>
    </>
  );
}
