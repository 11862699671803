/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Label,
  FormGroup,
  Row,
  Input,
  Container,
  CardHeader,
  CardFooter,
  Button,
  Form, FormFeedback
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import axios from 'axios';
import SimpleHeader from "components/Headers/SimpleHeader.js";

export default function CreateTerminalParameter(props) {
  const [isLoading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  const [terminalPk, setTerminalPk] = useState("");
  const [acquirer, setAcquirer] = useState("");
  const [acquirerError, setAcquirerError] = useState(null);
  const [listAcquirer, setListAcquirer] = useState([])
  const [transtype, setTranstype] = useState("");
  const [transtypeError, setTranstypeError] = useState(null);
  const [listTranstype, setListTranstype] = useState([]);
  const [tid, setTid] = useState("");
  const [tidError, setTidError] = useState(null);
  const [mid, setMid] = useState("");
  const [midError, setMidError] = useState(null);
  const [stan, setStan] = useState("");
  const [stanError, setStanError] = useState(null);
  const [batchNo, setBatchNo] = useState("");
  const [batchNoError, setBatchNoError] = useState(null);
  const [invNo, setInvNo] = useState("");
  const [invNoError, setInvNoError] = useState(null);
  const [status, setStatus] = useState("");
  const [statusError, setStatusError] = useState(null);
  const [listStatus, setListStatus] = useState([]);

  let history = useHistory();

  const validateForm = () => {
    let error = false;
    if (acquirer === "") {
      setAcquirerError("invalid");
      error = true;
    }
    if (transtype === "") {
      setTranstypeError("invalid");
      error = true;
    }
    if (tid === "") {
      setTidError("invalid");
      error = true;
    }
    if (mid === "") {
      setMidError("invalid");
      error = true;
    }
    if (stan === "") {
      setStanError("invalid");
      error = true;
    }
    if (batchNo === "") {
      setBatchNoError("invalid");
      error = true;
    }
    if (invNo === "") {
      setInvNoError("invalid");
      error = true;
    }
    if (status === "") {
      setStatusError("invalid");
      error = true;
    }
    return error;
  };

  useEffect(() => {
    getAcquirer();
    getTranstype();
  }, []);

  useEffect(() => {
    getAllTerminalParameters();
  }, [listAcquirer]);

  const getAllTerminalParameters = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/param/${props.match.params.id}`, {
      method: 'GET', // or 'PUT'
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(),
    })
      .then(response => response.json())
      .then(data => {
        const responseData = data.response_data;
        getHostById(responseData.acquirer_host_id)
        getTranstype(responseData.transtype_name)
        setTerminalPk(responseData.terminal_pk)
        setTranstype(responseData.transtype_name)
        setTid(responseData.tid)
        setMid(responseData.mid)
        setStan(responseData.stan)
        setBatchNo(responseData.batch_no)
        setInvNo(responseData.inv_no)
        getStatus(responseData.term_pars_status)
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const getStatus = (status) => {
    const data = { type: "terminalparam" }
    fetch(`${process.env.REACT_APP_API_BASE_URL}/status`, {
      method: 'POST', // or 'PUT'
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        setListStatus(data.response_data);
        setStatus(status);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const getHostById = async (hostId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/host/${hostId}`, {
        method: 'GET', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(),
      })
      const data = await response.json();
      setAcquirer(data.response_data.acquirer_id)
      setTranstype(data.response_data.acquirer_host_type)
    } catch (error) {
      console.error('Error:', error);
    }
  }


  const getAcquirer = async (acquirerId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/acquirers/list`, {
        method: 'GET', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(),
      })
      const data = await response.json();
      setListAcquirer(data.response_data);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const getTranstype = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/transtypes`, {
      method: 'GET', // or 'PUT'
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(),
    })
      .then(response => response.json())
      .then(data => {
        setListTranstype(data.response_data);
        setTranstype(data.response_data.transtype_name)
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  function UpdateTerminalParameter() {
    setLoading(true);
    let body = {
      terminal_pk: parseInt(terminalPk),
      acquirer_id: parseInt(acquirer),
      transtype_name: transtype,
      tid: tid,
      mid: mid,
      stan: parseInt(stan),
      batch_no: parseInt(batchNo),
      inv_no: parseInt(invNo),
      term_pars_status: parseInt(status)
    }
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/param/update/${props.match.params.id}`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then(function (response) {
        history.push("/admin/terminal");
      })
      .then(json => {
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      UpdateTerminalParameter();
    }
  }
  return (
    <>
      <SimpleHeader name="Edit Terminal Parameter" parentName="Admin" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardBody>
                <Form onSubmit={handleSubmit} >
                  <CardHeader>
                    <h3>Edit Terminal Parameter</h3>
                  </CardHeader>
                  <CardBody>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="acquirer_host_type"
                      >
                        Acquirer
                      </Label>
                      <Input
                        id="exampleFormControlSelect3"
                        name="acquirer_id"
                        type="select"
                        value={acquirer}
                        invalid={acquirerError === "invalid"}
                        onChange={(e) => {
                          setAcquirer(e.target.value);
                          if (e.target.value !== "") {
                            setAcquirerError("");
                          }
                        }}
                      >
                        <option value="">Pilih Acquirer</option>
                        {
                          listAcquirer.map((acq, key) => {
                            return <option key={key} value={acq.acquirer_id}>{acq.acquirer_name}</option>
                          })
                        }
                      </Input>
                      <FormFeedback>Acquirer tidak boleh kosong</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="acquirer_host_type"
                      >
                        Host Type
                      </Label>
                      <Input
                        id="exampleFormControlSelect3"
                        name="transtype_name"
                        type="select"
                        value={transtype}
                        invalid={transtypeError === "invalid"}
                        onChange={(e) => {
                          setTranstype(e.target.value);
                          if (e.target.value !== "") {
                            setTranstypeError("");
                          }
                        }}
                      >
                        <option value="">Pilih Host Type</option>
                        {
                          listTranstype.map((transtype, key) => {
                            return <option key={key} value={transtype.transtype_name}>{transtype.transtype_name}</option>
                          })
                        }
                      </Input>
                      <FormFeedback>Host Type tidak boleh kosong</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="encryption_type"
                      >
                        TID
                      </Label>
                      <Input
                        type="text"
                        name="tid"
                        maxLength={8}
                        placeholder="Masukan TID"
                        value={tid}
                        invalid={tidError === "invalid"}
                        onChange={(e) => {
                          setTid(e.target.value);
                          if (e.target.value !== "") {
                            setTidError("");
                          }
                        }}
                      />
                      <FormFeedback>TID tidak boleh kosong</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        MID
                      </Label>
                      <Input
                        type="text"
                        name="mid"
                        maxLength={15}
                        placeholder="Masukan MID"
                        value={mid}
                        invalid={midError === "invalid"}
                        onChange={(e) => {
                          setMid(e.target.value);
                          if (e.target.value !== "") {
                            setMidError("");
                          }
                        }}
                      />
                      <FormFeedback>MID tidak boleh kosong</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        Stan
                      </Label>
                      <Input
                        type="text"
                        name="stan"
                        maxLength={6}
                        placeholder="Masukan Stan"
                        value={stan}
                        invalid={stanError === "invalid"}
                        onChange={(e) => {
                          setStan(e.target.value);
                          if (e.target.value !== "") {
                            setStanError("");
                          }
                        }}
                      />
                      <FormFeedback>Stan tidak boleh kosong</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        Batch No
                      </Label>
                      <Input
                        type="text"
                        name="batch_no"
                        maxLength={6}
                        placeholder="Masukan Batch No"
                        value={batchNo}
                        invalid={batchNoError === "invalid"}
                        onChange={(e) => {
                          setBatchNo(e.target.value);
                          if (e.target.value !== "") {
                            setBatchNoError("");
                          }
                        }}
                      />
                      <FormFeedback>Batch No tidak boleh kosong</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        Inv No
                      </Label>
                      <Input
                        type="text"
                        name="inv_no"
                        maxLength={6}
                        placeholder="Masukan Inv No"
                        value={invNo}
                        invalid={invNoError === "invalid"}
                        onChange={(e) => {
                          setInvNo(e.target.value);
                          if (e.target.value !== "") {
                            setInvNoError("");
                          }
                        }}
                      />
                      <FormFeedback>Inv No tidak boleh kosong</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        Status
                                            </Label>
                      <Input
                        name="status"
                        type="select"
                        value={status}
                        invalid={statusError === "invalid"}
                        onChange={(e) => {
                          setStatus(e.target.value);
                          if (e.target.value !== "") {
                            setStatusError("");
                          }
                        }}
                      >
                        <option value="">-- Pilih Status --</option>
                        {
                          listStatus.map((stat, key) => {
                            return <option key={key} value={stat.value}>{stat.description}</option>
                          })
                        }
                      </Input>
                      <FormFeedback>Status tidak boleh kosong</FormFeedback>
                    </FormGroup>
                  </CardBody>
                  <CardFooter>
                    {!isLoading && (<Button color="primary" type="submit">
                      Simpan
                    </Button>)}
                    {isLoading && (<Button color="primary" disabled>
                      <i className="fas fa-spinner fa-spin"></i>{""}
                      Loading...
                    </Button>)}
                    <Link className="btn btn-info" to="/admin/terminal">
                      Kembali
                    </Link>
                  </CardFooter>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
