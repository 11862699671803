import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Row,
  Container,
  CardHeader, Form, Col, FormGroup, Label, Input, Button, Collapse
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

export default function DaftarTransaksi() {
  let [rowNumber] = useState([]);
  const [allTranscations, setAllTransactions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [merchants, setMerchants] = useState([]);
  const [merchant, setMerchant] = useState("");
  const [principals, setPrincipals] = useState([]);
  const [principal, setPrincipal] = useState("");
  const [mids, setMids] = useState([]);
  const [mid, setMid] = useState("");
  const [transtypes, setTranstypes] = useState([]);
  const [transtype, setTranstype] = useState("");
  const [binRanges, setBinRanges] = useState([]);
  const [binRange, setBinRange] = useState("");
  const [listStatus, setListStatus] = useState([]);
  const [status, setStatus] = useState("");
  const [tids, setTids] = useState([]);
  const [tid, setTid] = useState("");
  const [datePeriod, setDatePeriod] = useState("");
  const [timePeriod, setTimePeriod] = useState("");
  const [rrn, setRnn] = useState("");
  const [invoice, setInvoice] = useState("");
  const [approval, setApproval] = useState("");
  const [amount, setAmount] = useState("");

  let paginationOption = {
    page: 1,
    alwaysShowAllBtns: true,
    override: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => onSizePerPageChange(e.target.value)}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  }

  useEffect(() => {
    getAllTransactions();
    getMerchants();
    getPrincipals();
    getMids();
    getTranstypes();
    getBinRanges();
    getStatus();
    getTids();
  }, []);

  const getAllTransactions = () => {
    const data = { page: 1, per_page: 100 };
    fetch(`${process.env.REACT_APP_API_BASE_URL}/transactions`, {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        setAllTransactions(data.response_data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const getMerchants = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/merchants/list`, {
      method: 'GET', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(),
    })
      .then(response => response.json())
      .then(data => {
        setMerchants(data.response_data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const getPrincipals = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/principals`, {
      method: 'GET', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(),
    })
      .then(response => response.json())
      .then(data => {
        setPrincipals(data.response_data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const getMids = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/param/list`, {
      method: 'GET', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(),
    })
      .then(response => response.json())
      .then(data => {
        setMids(data.response_data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const getTranstypes = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/transtypes`, {
      method: 'GET', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(),
    })
      .then(response => response.json())
      .then(data => {
        setTranstypes(data.response_data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const getBinRanges = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/binrange/list`, {
      method: 'GET', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(),
    })
      .then(response => response.json())
      .then(data => {
        setBinRanges(data.response_data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const getStatus = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/status/list`, {
      method: 'GET', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(),
    })
      .then(response => response.json())
      .then(data => {
        setListStatus(data.response_data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const getTids = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/param/list`, {
      method: 'GET', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(),
    })
      .then(response => response.json())
      .then(data => {
        setTids(data.response_data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  return (
    <>
      <SimpleHeader name="Daftar Transaksi" parentName="Admin" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3>List Daftar Transaksi</h3>
              </CardHeader>
              <CardBody>
                <h3 onClick={toggle} >Filter &nbsp;
                  {
                    isOpen === true ? <i class="fa fa-angle-down" aria-hidden="true"></i> : <i class="fa fa-angle-right" aria-hidden="true"></i>
                  }
                </h3>
                <Collapse isOpen={isOpen}>

                  <Form>
                    <Row md="12">
                      <Col md="4">
                        <FormGroup>
                          <Label htmlFor="exampleFormControlSelect3">Merchant Name</Label>
                          <Input
                            id="exampleFormControlSelect3"
                            name="merchant_name"
                            type="select"
                            value={merchant}
                            onChange={e => setMerchant(e.target.value)}
                          >
                            <option value="0">Pilih Merchant Name</option>
                            {
                              merchants.map((merchant, key) => {
                                return <option key={key} value={merchant.merchant_pk}>{merchant.merchant_name}</option>
                              })
                            }
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <Label htmlFor="exampleFormControlSelect3">Principal</Label>
                          <Input
                            id="exampleFormControlSelect3"
                            name="principal"
                            type="select"
                            value={principal}
                            onChange={e => setPrincipal(e.target.value)}
                          >
                            <option value="0">Pilih Principal</option>
                            {
                              principals.map((principal, key) => {
                                return <option key={key} value={principal.principal_id}>{principal.principal_name}</option>
                              })
                            }
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <Label htmlFor="exampleFormControlSelect3">MID</Label>
                          <Input
                            id="exampleFormControlSelect3"
                            name="mid"
                            type="select"
                            value={mid}
                            onChange={e => setMid(e.target.value)}
                          >
                            <option value="0">Pilih MID</option>
                            {
                              mids.map((mid, key) => {
                                return <option key={key} value={mid.terminalParamId}>{mid.mid}</option>
                              })
                            }
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row md="12">
                      <Col md="4">
                        <FormGroup>
                          <Label htmlFor="exampleFormControlSelect3">Transtype</Label>
                          <Input
                            id="exampleFormControlSelect3"
                            name="transtype"
                            type="select"
                            value={transtype}
                            onChange={e => setTranstype(e.target.value)}
                          >
                            <option value="0">Pilih Transtype</option>
                            {
                              transtypes.map((transType, key) => {
                                return <option key={key} value={transType.transtype_id}>{transType.transtype_name}</option>
                              })
                            }
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <Label htmlFor="exampleFormControlSelect3">Bin Range</Label>
                          <Input
                            id="exampleFormControlSelect3"
                            name="binrange"
                            type="select"
                            value={binRange}
                            onChange={e => setBinRange(e.target.value)}
                          >
                            <option value="0">Pilih Bin Range</option>
                            {
                              binRanges.map((binrange, key) => {
                                return <option key={key} value={binrange.bin_range_id}>{binrange.bin_name}</option>
                              })
                            }
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <Label htmlFor="exampleFormControlSelect3">Status</Label>
                          <Input
                            id="exampleFormControlSelect3"
                            name="status"
                            type="select"
                            value={status}
                            onChange={e => setStatus(e.target.value)}
                          >
                            <option value="0">Pilih Status</option>
                            {
                              listStatus.map((status, key) => {
                                return <option key={key} value={status.value}>{status.description}</option>
                              })
                            }
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row md="12">
                      <Col md="4">
                        <FormGroup>
                          <Label htmlFor="exampleFormControlSelect3">Date Period</Label>
                          <Input
                            defaultValue={new Date().getFullYear() + "-11-23"}
                            id="example-date-input"
                            type="date"
                            value={datePeriod}
                            onChange={(e) => {
                              setDatePeriod(e.target.value);
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <Label htmlFor="exampleFormControlSelect3">TID</Label>
                          <Input
                            id="exampleFormControlSelect3"
                            name="tid"
                            type="select"
                            value={tid}
                            onChange={e => setTid(e.target.value)}
                          >
                            <option value="0">Pilih TID</option>
                            {
                              tids.map((tid, key) => {
                                return <option key={key} value={tid.terminalParamId}>{tid.tid}</option>
                              })
                            }
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <Label htmlFor="exampleFormControlSelect3">Time Period</Label>
                          <Input
                            defaultValue="00:00:00"
                            id="example-time-input"
                            type="time"
                            placeholder="Time Period"
                            value={timePeriod}
                            onChange={(e) => {
                              setTimePeriod(e.target.value);
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row md="12">
                      <Col md="4">
                        <FormGroup>
                          <Label htmlFor="exampleFormControlSelect3">RRN</Label>
                          <Input
                            type="text"
                            placeholder="Masukan RRN"
                            value={rrn}
                            onChange={(e) => {
                              setRnn(e.target.value);
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <Label htmlFor="exampleFormControlSelect3">Invoice No</Label>
                          <Input
                            type="text"
                            placeholder="Masukan Invoice Number"
                            value={invoice}
                            onChange={(e) => {
                              setInvoice(e.target.value);
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <Label htmlFor="exampleFormControlSelect3">Approval Code</Label>
                          <Input
                            type="text"
                            placeholder="Masukan Approval Code"
                            value={approval}
                            onChange={(e) => {
                              setApproval(e.target.value);
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row md="12">
                      <Col md="4">
                        <FormGroup>
                          <Label htmlFor="exampleFormControlSelect3">Amount</Label>
                          <Input
                            type="text"
                            placeholder="Masukan Amount"
                            value={amount}
                            onChange={(e) => {
                              setAmount(e.target.value);
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button type="submit" color="primary">Search</Button>
                        <Button type="button" color="secondary">Reset</Button>
                      </Col>
                    </Row>
                  </Form>
                </Collapse>
                <ToolkitProvider
                  rowNumber={rowNumber}
                  data={allTranscations}
                  keyField="name"
                  columns={[
                    {
                      dataField: "no",
                      text: "#",
                      sort: true,
                      page: 1,
                      formatter: (cell, row, index) => {
                        return ++index
                      }
                    },
                    {
                      dataField: "merchant_name",
                      text: "Merchant Name",
                      sort: true,
                    },
                    {
                      dataField: "transtype_name",
                      text: "Transtype",
                      sort: true,
                    },
                    {
                      dataField: "amount",
                      text: "Amount",
                      sort: true,
                    },
                    {
                      dataField: "earned_fee",
                      text: "Earned Fee",
                      sort: true,
                    },
                    {
                      dataField: "inv_no",
                      text: "Inv No",
                      sort: true,
                    },
                    {
                      dataField: "date",
                      text: "Date",
                      sort: true,
                    },
                    {
                      dataField: "ref_no",
                      text: "Reff Code",
                      sort: true,
                    },
                  ]}
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      <BootstrapTable
                        {...props.baseProps}
                        bootstrap4={true}
                        bordered={false}
                        pagination={paginationFactory({ ...paginationOption })}
                        onTableChange
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
