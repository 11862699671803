/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Label,
  FormGroup,
  Input,
  Row,
  Col
} from "reactstrap";
import moment from 'moment';

export default function DataAdministrasi() {
  const token = localStorage.token;
  const merchantPk = localStorage.getItem("merchantPk");
  const [isSelected, setSelected] = useState(1);

  const [ktp, setKtp] = useState(false);
  const [npwp, setNpwp] = useState(false);
  const [pbb, setPbb] = useState(false);
  const [fotoUsaha, setFotoUsaha] = useState(false);

  const [aktaPerusahaan, setAktaPerusahaan] = useState(false);
  const [suratKemenkumham, setSuratKemenkumham] = useState(false);
  const [siup, setSiup] = useState(false);
  const [tdp, setTdp] = useState(false);
  const [situ, setSitu] = useState(false);
  const [suratKuasa, setSuratKuasaa] = useState(false);
  const [tanggalDoc, setTanggalDoc] = useState("");

  useEffect(() => {
    getProfileByMk();
  }, []);

  const getProfileByMk = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/merchant/profile/${merchantPk}`, {
        method: 'GET', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(),
      })
      const data = await response.json();
      setSelected(data.response_data.entity_type);
      setKtp(data.response_data.identity_copy);
      setNpwp(data.response_data.tax_copy);
      setPbb(data.response_data.property_tax_copy);
      setFotoUsaha(data.response_data.pob_picture);

      setAktaPerusahaan(data.response_data.company_deed_copy);
      setSuratKemenkumham(data.response_data.gov_letter_copy);
      setSiup(data.response_data.trade_licence_copy);
      setTdp(data.response_data.crc_copy);
      setSitu(data.response_data.pob_permit_copy);
      setSuratKuasaa(data.response_data.power_of_attorney);
      setTanggalDoc(moment(data.response_data.doc_collected_date, "DD/MM/YYYY").format("YYYY-MM-DD"));
    } catch (error) {
      console.error('Error:', error);
    }
  }
  return (
    <>
      <Card>
        <CardBody>
          <FormGroup>
            <Label
              className="form-control-label"
              htmlFor="exampleFormControlSelect3"
            >
              Persyaratan
                            </Label>
            <Row>
              {
                isSelected === 1 ?
                  <Col md="6">
                    <div className="custom-control custom-checkbox mb-3">
                      <Input
                        className="custom-control-input"
                        disabled={true}
                        id="ktp"
                        type="checkbox"
                        value={1}
                        checked={ktp}
                        onChange={(e) => {
                          let checkedValue = 0;
                          if (e.target.checked) {
                            checkedValue = 1;
                          }
                          setKtp(checkedValue);
                        }}

                      />
                      <Label
                        className="custom-control-label"
                        htmlFor="ktp"
                      >
                        Fotokopi KTP / Password (WNA)
                                            </Label>
                    </div>
                    <div className="custom-control custom-checkbox mb-3">
                      <Input
                        className="custom-control-input"
                        disabled={true}
                        id="npwp"
                        type="checkbox"
                        checked={npwp}
                        value={1}
                        onChange={(e) => {
                          let checkedValue = 0;
                          if (e.target.checked) {
                            checkedValue = 1;
                          }
                          setNpwp(checkedValue);
                        }}
                      />
                      <Label
                        className="custom-control-label"
                        htmlFor="npwp"
                      >
                        Fotokopi NPWP (Jika ada)
                                            </Label>
                    </div>
                    <div className="custom-control custom-checkbox mb-3">
                      <Input
                        className="custom-control-input"
                        disabled={true}
                        id="pbb"
                        type="checkbox"
                        checked={pbb}
                        value={1}
                        onChange={(e) => {
                          let checkedValue = 0;
                          if (e.target.checked) {
                            checkedValue = 1;
                          }
                          setPbb(checkedValue);
                        }}
                      />
                      <Label
                        className="custom-control-label"
                        htmlFor="pbb"
                      >
                        Fotokopi PBB/Surat Sewa/Surat Domisili (Jika ada)
                                            </Label>
                    </div>
                    <div className="custom-control custom-checkbox mb-3">
                      <Input
                        className="custom-control-input"
                        disabled={true}
                        id="foto"
                        type="checkbox"
                        checked={fotoUsaha}
                        value={1}
                        onChange={(e) => {
                          let checkedValue = 0;
                          if (e.target.checked) {
                            checkedValue = 1;
                          }
                          setFotoUsaha(checkedValue);
                        }}
                      />
                      <Label
                        className="custom-control-label"
                        htmlFor="foto"
                      >
                        Foto Tempat Usaha
                                            </Label>
                    </div>
                  </Col> :
                  <Col md="6">
                    <div className="custom-control custom-checkbox mb-3">
                      <Input
                        className="custom-control-input"
                        disabled={true}
                        id="ktp1"
                        type="checkbox"
                        value={1}
                        checked={ktp}
                        onChange={(e) => {
                          let checkedValue = 0;
                          if (e.target.checked) {
                            checkedValue = 1;
                          }
                          setKtp(checkedValue);
                        }}
                      />
                      <Label
                        className="custom-control-label"
                        htmlFor="ktp1"
                      >
                        Fotokopi KTP / Password (WNA)
                                            </Label>
                    </div>
                    <div className="custom-control custom-checkbox mb-3">
                      <Input
                        className="custom-control-input"
                        disabled={true}
                        id="npwpBadanHukum"
                        type="checkbox"
                        value={1}
                        checked={npwp}
                        onChange={(e) => {
                          let checkedValue = 0;
                          if (e.target.checked) {
                            checkedValue = 1;
                          }
                          setNpwp(checkedValue);
                        }}
                      />
                      <Label
                        className="custom-control-label"
                        htmlFor="npwpBadanHukum"
                      >
                        Fotokopi NPWP Badan Hukum
                                            </Label>
                    </div>
                    <div className="custom-control custom-checkbox mb-3">
                      <Input
                        className="custom-control-input"
                        disabled={true}
                        id="aktaPerusahaan"
                        type="checkbox"
                        value={1}
                        checked={aktaPerusahaan}
                        onChange={(e) => {
                          let checkedValue = 0;
                          if (e.target.checked) {
                            checkedValue = 1;
                          }
                          setAktaPerusahaan(checkedValue);
                        }}
                      />
                      <Label
                        className="custom-control-label"
                        htmlFor="aktaPerusahaan"
                      >
                        Fotokopi Akta Perusahaan
                                            </Label>
                    </div>
                    <div className="custom-control custom-checkbox mb-3">
                      <Input
                        className="custom-control-input"
                        disabled={true}
                        id="kemenkumham"
                        type="checkbox"
                        value={1}
                        checked={suratKemenkumham}
                        onChange={(e) => {
                          let checkedValue = 0;
                          if (e.target.checked) {
                            checkedValue = 1;
                          }
                          setSuratKemenkumham(checkedValue);
                        }}
                      />
                      <Label
                        className="custom-control-label"
                        htmlFor="kemenkumham"
                      >
                        Fotokopi Surat Kemenkumham
                                            </Label>
                    </div>
                    <div className="custom-control custom-checkbox mb-3">
                      <Input
                        className="custom-control-input"
                        disabled={true}
                        id="siup"
                        type="checkbox"
                        value={1}
                        checked={siup}
                        onChange={(e) => {
                          let checkedValue = 0;
                          if (e.target.checked) {
                            checkedValue = 1;
                          }
                          setSiup(checkedValue);
                        }}
                      />
                      <Label
                        className="custom-control-label"
                        htmlFor="siup"
                      >
                        Fotokopi SIUP
                                            </Label>
                    </div>
                    <div className="custom-control custom-checkbox mb-3">
                      <Input
                        className="custom-control-input"
                        disabled={true}
                        id="tdp"
                        type="checkbox"
                        value={1}
                        checked={tdp}
                        onChange={(e) => {
                          let checkedValue = 0;
                          if (e.target.checked) {
                            checkedValue = 1;
                          }
                          setTdp(checkedValue);
                        }}
                      />
                      <Label
                        className="custom-control-label"
                        htmlFor="tdp"
                      >
                        Fotokopi TDP
                                            </Label>
                    </div>
                    <div className="custom-control custom-checkbox mb-3">
                      <Input
                        className="custom-control-input"
                        disabled={true}
                        id="skdu"
                        type="checkbox"
                        value={1}
                        checked={situ}
                        onChange={(e) => {
                          let checkedValue = 0;
                          if (e.target.checked) {
                            checkedValue = 1;
                          }
                          setSitu(checkedValue);
                        }}
                      />
                      <Label
                        className="custom-control-label"
                        htmlFor="skdu"
                      >
                        Fotokopi SITU/SKDU
                                            </Label>
                    </div>
                    <div className="custom-control custom-checkbox mb-3">
                      <Input
                        className="custom-control-input"
                        disabled={true}
                        id="tempatUsaha"
                        type="checkbox"
                        value={1}
                        checked={fotoUsaha}
                        onChange={(e) => {
                          let checkedValue = 0;
                          if (e.target.checked) {
                            checkedValue = 1;
                          }
                          setFotoUsaha(checkedValue);
                        }}
                      />
                      <Label
                        className="custom-control-label"
                        htmlFor="tempatUsaha"
                      >
                        Foto Tempat Usaha
                                            </Label>
                    </div>
                    <div className="custom-control custom-checkbox mb-3">
                      <Input
                        className="custom-control-input"
                        disabled={true}
                        id="suratKuasa"
                        type="checkbox"
                        value={suratKuasa}
                        value={1}
                        checked={suratKuasa}
                        onChange={(e) => {
                          let checkedValue = 0;
                          if (e.target.checked) {
                            checkedValue = 1;
                          }
                          setSuratKuasaa(checkedValue);
                        }}
                      />
                      <Label
                        className="custom-control-label"
                        htmlFor="suratKuasa"
                      >
                        Surat Kuasa (Jika ada)
                                            </Label>
                    </div>
                  </Col>
              }
            </Row>
          </FormGroup>
          <FormGroup>
            <Label
              className="form-control-label"
              htmlFor="exampleFormControlSelect3"
            >
              Tanggal Berkas Terkumpul
                            </Label>
            <Input
              defaultValue={new Date().getFullYear() + "-11-23"}
              disabled={true}
              id="example-date-input"
              type="date"
              value={tanggalDoc}
              onChange={(e) => {
                setTanggalDoc(e.target.value);
              }}
            />
          </FormGroup>
        </CardBody>
      </Card>
    </>
  );
}
