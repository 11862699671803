/*eslint-disable*/
import React, { useEffect, useState } from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Input,
  InputGroupAddon,
  InputGroupText,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselCaption,
  Modal,
  ModalBody,
  FormFeedback
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { AvForm, AvGroup } from 'availity-reactstrap-validation';
import { useRecaptcha } from "react-hook-recaptcha";
import axios from 'axios';
import md5 from 'js-md5';
import { TripleDes } from "data-crypto";
// core components
import background from "../../../assets/img/theme/backgroud.svg";
import SweetAlert from "react-bootstrap-sweetalert";

const items = [
  {
    src: require("assets/img/theme/das.png").default,
    caption: 'Merchant Dashboard'
  },
  {
    src: require("assets/img/theme/edc.png").default,
    caption: 'Payment Terminal'
  },
  {
    src: require("assets/img/theme/campur.png").default,
    caption: 'Realtime Transaction Status'
  }
];

function ascii_to_hexa(str) {
  var arr1 = [];
  for (var n = 0, l = str.length; n < l; n++) {
    var hex = Number(str.charCodeAt(n)).toString(16);
    arr1.push(hex);
  }
  return arr1.join('');
}

function Login() {
  let history = useHistory();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(null);
  const [wrongEmail, setWrongEmail] = useState(null);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(null);
  const [wrongPassword, setWrongPassword] = useState("");
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [modal, setModal] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [alert, setAlert] = React.useState(null);
  const timestamp = Date.now();
  const [resetEmail, setResetEmail] = useState("");
  const [resetEmailError, setResetEmailError] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const [captchaResponse, setCaptchaResponse] = useState(null);
  const successCallback = (response) => {
    setCaptchaResponse(response);
  };
  const expiredCallback = () => setCaptchaResponse(null);
  const containerId = "test-recaptcha";
  const sitekey = process.env.REACT_APP_GOOGLE_CAPTCHA;

  const [rememberMe, setRememberMe] = useState(false);
  const isEmail = localStorage.getItem("username");
  const isPassword = localStorage.getItem("isPassword");
  const cekRememberMe = localStorage.getItem("rememberMe");

  useRecaptcha({
    containerId,
    successCallback,
    expiredCallback,
    size: "normal",
    sitekey
  });

  const validateForm = () => {
    let error = false;
    if (email === "") {
      setEmailError("invalid");
      error = true;
    }
    if (password === "") {
      setPasswordError("invalid");
      error = true;
    }
    return error;
  };

  const validateFormResetPassword = () => {
    let error = false;
    if (resetEmail === "") {
      setResetEmailError("invalid");
      error = true;
    }
    return error;
  };

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const toggle = () => setModal(!modal);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  }

  const slides = items.map((item) => {
    return (
      <CarouselItem
        className="custom-tag"
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <img src={item.src} style={{ width: "100%", height: "auto" }} />
        <CarouselCaption captionHeader={item.caption} />
      </CarouselItem>
    );
  });

  const hideAlert = () => {
    setAlert(null);
  }

  const setMsgAlert = (str) => {
    setAlert(
      <SweetAlert
        error
        showConfirm
        confirmBtnText="Ok"
        title={str}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    )
  }

  const setSuccessAlert = () => {
    setAlert(
      <SweetAlert
        success
        showConfirm
        confirmBtnText="Ok"
        title="Link reset password sudah terkirim, silahkan cek email anda."
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    )
  }

  const getMyProfil = async () => {
    const token = localStorage.token;
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/info`, {
        method: 'GET', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(),
      })
      const data = await response.json();
      localStorage.setItem('userId', data.userId);
      localStorage.setItem('email', data.email);
      localStorage.setItem('phoneNumber', data.phoneNumber);
      localStorage.setItem('username', data.username);
      localStorage.setItem('name', data.name);
      localStorage.setItem('address', data.address);
      localStorage.setItem('authority', data.authorities[0].authority);
      localStorage.setItem('merchantPk', data.merchantPk);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  function Padding(passwordWithLanght) {
    let strToPad = passwordWithLanght;
    let strLength = strToPad.length;
    let nearestMultipler = Math.ceil(strLength / 8) * 8;
    return strToPad.padEnd(nearestMultipler, "0");
  }

  function Login() {
    const key = email + timestamp;
    const md5Key = md5(key);
    const passwordLenght = password.length;
    let passwordWithLanght;
    if (passwordLenght < 10) {
      passwordWithLanght = "0" + passwordLenght + password;
    } else {
      passwordWithLanght = passwordLenght + password;
    }
    let paddingPassword = Padding(passwordWithLanght);
    let passwordHex = ascii_to_hexa(paddingPassword);
    const tripleDesPassword = TripleDes.encrypt(passwordHex, md5Key);
    let body = {
      usernameOrEmail: email,
      password: tripleDesPassword,
      timestamp: timestamp.toString()
    }
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/login?g-recaptcha-response=${captchaResponse}&remember-me=${rememberMe}`, body)
      .then(function (response) {
        if (response.data.error) {
          showErrors(response.data.error);
          return;
        }
        if (response.data.errors) {
          showErrorsEmail(response.data.errors);
          return;
        }
        setPassword(localStorage.setItem("isPassword", password));
        window.localStorage.setItem("token", response.data.response_data.token);
        window.localStorage.setItem("rememberMe", response.data.response_data.remember_me);
        window.localStorage.setItem("refreshToken", response.data.response_data.refreshToken);
        getMyProfil();
        history.push("/admin/dashboard");
      })
      .catch(function (error) {
        setMsgAlert(error.message)
        console.log(error)
      })
  }

  const showErrorsEmail = (error) => {
    setEmailError("invalid");
    setWrongEmail(error.usernameOrEmail);
  }

  const showErrors = (error) => {
    setEmailError("invalid");
    setWrongEmail(error.usernameOrEmail)
    setPasswordError("invalid");
    setWrongPassword(error.password);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      Login();
    }
  }

  useEffect(() => {
    getRememberMe();
  }, []);

  const getRememberMe = () => {
    if (cekRememberMe === "true") {
      setEmail(isEmail);
      setPassword(isPassword);
      setRememberMe(true);
    } else {
      setEmail("");
      setPassword("");
    }
  }

  function ForgotPassword() {
    setLoading(true);
    let body = {
      email: resetEmail
    }
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/user/forgot-password`, body)
      .then(function (response) {
        setSuccessAlert();
        setResetEmail("");
        history.push("/auth/login");
      })
      .then(json => {
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const handleSubmitForgotPassword = (e) => {
    e.preventDefault();
    if (!validateFormResetPassword()) {
      ForgotPassword();
    }
  }

  return (
    <>
      {alert}
      <Container className="login-container">
        <Row md={12}>
          <Card>
            <CardBody>
              <Row>
                <Col md={6} style={{ backgroundImage: `url(${background})`, textAlign: "center" }}>
                  <Carousel
                    activeIndex="hide"
                    next={next}
                    previous={previous}
                    items={items}
                    activeIndex={activeIndex}
                  >
                    {slides}
                  </Carousel>
                </Col>
                <Col md={6} style={{ marginTop: "160px" }}>
                  <img
                    alt="login"
                    style={{ width: "24%", marginBottom: "15px" }}
                    src={require("assets/img/brand/rpay.png").default}
                  />
                  <p>Selamat datang, silakan masuk ke akun anda.</p>
                  <AvForm onSubmit={handleSubmit}>
                    <AvGroup className="input-group">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Masukan Email / Username"
                        value={email}
                        invalid={emailError === "invalid"}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          if (e.target.value !== "") {
                            setEmailError("");
                          }
                        }}
                      />
                      <FormFeedback>
                        {
                          email === "" ? "Email atau Username tidak boleh kosong" : wrongEmail
                        }
                      </FormFeedback>
                    </AvGroup>
                    <AvGroup className="input-group">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Masukkan Kata Sandi"
                        type={passwordShown ? "text" : "password"}
                        value={password}
                        invalid={passwordError === "invalid"}
                        onChange={(e) => {
                          setPassword(e.target.value);
                          if (e.target.value !== "") {
                            setPasswordError("");
                          }
                        }}
                      />
                      <InputGroupText>
                        {
                          passwordShown !== true ? <i className="fa fa-eye" aria-hidden="true" onClick={togglePasswordVisiblity} /> : <i className="fa fa-eye-slash" aria-hidden="true" onClick={togglePasswordVisiblity} />
                        }
                      </InputGroupText>
                      <FormFeedback>
                        {
                          password === "" ? "Password tidak boleh kosong" : wrongPassword
                        }
                      </FormFeedback>
                    </AvGroup>
                    <Row>
                      <Col md={6}>
                        <div className="custom-control custom-control-alternative custom-checkbox">
                          <input
                            className="custom-control-input"
                            id=" customCheckLogin"
                            type="checkbox"
                            checked={rememberMe}
                            value={true}
                            onChange={(e) => {
                              setRememberMe(e.target.checked);
                            }}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor=" customCheckLogin"
                          >
                            <span className="text-muted">Ingatkan saya</span>
                          </label>
                        </div>
                      </Col>
                      <Col md={6} style={{ textAlign: "right" }}>
                        <a
                          className="text-light"
                          onClick={toggle}
                        >
                          <small>Lupa Kata Sandi?</small>
                        </a>
                      </Col>
                      <Modal isOpen={modal} toggle={toggle} style={{ textAlign: "center" }}>
                        <ModalBody>

                          <img
                            alt="login"
                            style={{ width: "24%", marginBottom: "15px" }}
                            src={require("assets/img/brand/tsm.png").default}
                          />
                          <h3>Lupa Kata Sandi ?</h3>
                          <p>Enter email address and we will send you instructions to reset Password.</p>
                          <AvGroup className="input-group">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-email-83" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Masukan Email / Username"
                              value={resetEmail}
                              invalid={resetEmailError === "invalid"}
                              onChange={(e) => {
                                setResetEmail(e.target.value);
                                if (e.target.value !== "") {
                                  setResetEmailError("");
                                }
                              }}
                            />
                            <FormFeedback>
                              Email atau Username tidak boleh kosong
                            </FormFeedback>
                          </AvGroup>
                          <div classname="text-center">
                            {!isLoading && (
                              <Button onClick={handleSubmitForgotPassword} type="button" color="primary">Send</Button>
                            )}
                            {isLoading && (
                              <Button color="primary" disabled>
                                <i className="fas fa-spinner fa-spin"></i>{""}
                                Loading...
                              </Button>
                            )}
                          </div>
                        </ModalBody>
                      </Modal>
                    </Row>
                    <div id={containerId} className="g-recaptcha" />
                    <div className="text-center">
                      <Button className="my-4" disabled={!captchaResponse} color="info" type="submit">
                        Masuk
                    </Button>
                    </div>
                  </AvForm>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Row>
      </Container>
    </>
  );
}

export default Login;
