/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { Card, Button, CardBody, CardHeader, UncontrolledTooltip, ButtonGroup } from 'reactstrap';
import { Link } from "react-router-dom";
import axios from 'axios';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import SweetAlert from "react-bootstrap-sweetalert";

export default function MerchantGroupMdr(props) {
  const token = localStorage.token;
  const merchantPk = props.merchantPk;
  const groupName = props.groupName;
  const [merchantMdrs, setMerchantMdrs] = useState([]);
  const redirectPrefix = `/admin/customer/daftar-group/mdr/${merchantPk}/edit/`;
  const [alert, setAlert] = React.useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [rowIndex, setRowIndex] = useState(0);
  const allowedRoles = ["ROLE_SUPERADMIN", "ROLE_OPERATOR_BUSDEV", "ROLE_BOD", "ROLE_OPERATOR_FINANCE", "ROLE_SUPERVISOR_FINANCE"]

  let paginationOption = {
    page: 1,
    alwaysShowAllBtns: true,
    override: true,
    showTotal: true,
    withFirstAndLast: false,
    onPageChange: (page) => {
      calculateRowIndex(page, currentPageSize);
    },
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                onSizePerPageChange(e.target.value);
                setCurrentPageSize(e.target.value);
                calculateRowIndex(currentPage, e.target.value);
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          }{" "}
      entries.
    </label>
      </div>
    ),
  }

  const calculateRowIndex = (currentPage, currentPageSize) => {
    setRowIndex((currentPage - 1) * currentPageSize);
  }

  useEffect(() => {
    setMerchantMdrs([]);
    getMerchantMdrs();
  }, [props.merchantPk]);

  const getMerchantMdrs = () => {
    const data = { page: 1, per_page: 100, merchant_pk: merchantPk };
    fetch(`${process.env.REACT_APP_API_BASE_URL}/merchantmdrs`, {
      method: 'POST', // or 'PUT'
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        setMerchantMdrs(data.response_data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const delateMerchantMdr = (merchant_mdr_id) => {
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/merchantmdr/delete/${merchant_mdr_id}`, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then(function (response) {
        setSuccessAlert();
        getMerchantMdrs();
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const setSuccessAlert = () => {
    setAlert(
      <SweetAlert
        success
        showConfirm
        confirmBtnText="Ok"
        title="Merchant Mdr deleted"
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    )
  }

  const setQuestionAlert = (merchant_mdr_id) => {
    setAlert(
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={() => delateMerchantMdr(merchant_mdr_id)}
        onCancel={hideAlert}
        focusCancelBtn
      />
    )
  }

  const hideAlert = () => {
    setAlert(null);
  }

  const formatRupiah = (money) => {
    return new Intl.NumberFormat('id-ID',
      { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
    ).format(money);
  }

  return (
    <>
      {alert}
      <Card>
        <CardHeader>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h3>List Group Merchant MDR ({groupName})</h3>
            {
              allowedRoles.includes(localStorage.getItem("authority"))
                ?
                <div style={{ textAlign: "right" }}>
                  <Link className="btn btn-info" to={`/admin/customer/daftar-group/mdr/${merchantPk}/create`}>
                    Add
              </Link>
                </div>
                :
                <div></div>
            }
          </div>
        </CardHeader>
        <CardBody>
          {
            allowedRoles.includes(localStorage.getItem("authority"))
              ?

              <ToolkitProvider
                rowNumber={rowIndex}
                data={merchantMdrs}
                keyField="merchant_mdr_id"
                columns={[
                  {
                    dataField: "no",
                    text: "#",
                    sort: true,
                    page: 1,
                    formatter: (cell, row, index) => {
                      let currentRow = ++index;
                      return currentRow + rowIndex;
                    }
                  },
                  {
                    dataField: "transtype_name",
                    text: "Host Type",
                    sort: true,
                  },
                  {
                    dataField: "transtype_clsf",
                    text: "Card Type",
                    sort: true,
                  },
                  {
                    dataField: "merchant_mdr_value",
                    text: "MDR Value (%)",
                    sort: true,
                    formatter: (cell, row) => {
                      return row.merchant_mdr_value + "%"
                    }
                  },
                  {
                    dataField: "merchant_mdr_fee",
                    text: "MDR Fee (Rp)",
                    sort: true,
                    formatter: (cell, row) => {
                      return formatRupiah(row.merchant_mdr_fee)
                    }
                  },
                  {
                    dataField: "", text: "", formatter: (cell, row, index) => {
                      return (
                        <ButtonGroup>
                          <Button>
                            <Link
                              to={redirectPrefix + row.merchant_mdr_id}
                              id={"tooltip_" + row.merchant_mdr_id}
                            >
                              <i className="fas fa-user-edit" />
                            </Link>
                          </Button>
                          <UncontrolledTooltip delay={0} target={"tooltip_" + row.merchant_mdr_id}>
                            Edit
                      </UncontrolledTooltip>
                          <Button
                            id="btn-acquirer"
                            onClick={() => setQuestionAlert(row.merchant_mdr_id)}
                          >
                            <i className="fas fa-trash" />
                          </Button>
                          <UncontrolledTooltip
                            delay={0}
                            placement="top"
                            target="btn-acquirer"
                          >
                            Delete Merchant MDR
                      </UncontrolledTooltip>
                        </ButtonGroup>
                      )
                    }
                  },
                ]}
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      pagination={paginationFactory({ ...paginationOption })}
                    />
                  </div>
                )}
              </ToolkitProvider>
              :
              <ToolkitProvider
                rowNumber={rowIndex}
                data={merchantMdrs}
                keyField="merchant_mdr_id"
                columns={[
                  {
                    dataField: "no",
                    text: "#",
                    sort: true,
                    page: 1,
                    formatter: (cell, row, index) => {
                      let currentRow = ++index;
                      return currentRow + rowIndex;
                    }
                  },
                  {
                    dataField: "transtype_name",
                    text: "Host Type",
                    sort: true,
                  },
                  {
                    dataField: "transtype_clsf",
                    text: "Card Type",
                    sort: true,
                  },
                  {
                    dataField: "merchant_mdr_value",
                    text: "MDR Value (%)",
                    sort: true,
                    formatter: (cell, row) => {
                      return row.merchant_mdr_value + "%"
                    }
                  },
                  {
                    dataField: "merchant_mdr_fee",
                    text: "MDR Fee (Rp)",
                    sort: true,
                    formatter: (cell, row) => {
                      return formatRupiah(row.merchant_mdr_fee)
                    }
                  },
                ]}
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      pagination={paginationFactory({ ...paginationOption })}
                    />
                  </div>
                )}
              </ToolkitProvider>
          }
        </CardBody>
      </Card>
    </>
  );
}
