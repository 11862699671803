/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  FormGroup,
  Row,
  Col,
  Input,
  UncontrolledTooltip,
  Container,
  CardHeader,
  ButtonGroup,
  Button, Form, Label
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import axios from 'axios';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

export default function Bank(props) {
  const [allBanks, setAllBanks] = useState([]);
  const [namaBank, setNamaBank] = useState([]);
  const [bank, setBank] = useState("");
  const [alert, setAlert] = React.useState(null);
  const redirectPrefix = `/admin/bank/edit/`;
  let [rowNumber, setRowNumber] = useState([]);

  let paginationOption = {
    page: 1,
    alwaysShowAllBtns: true,
    override: true,
    showTotal: true,
    withFirstAndLast: false,
    onPageChange: () => {
    },
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => onSizePerPageChange(e.target.value)}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  }

  useEffect(() => {
    getAllBanks();
    getNamaBanks();
  }, []);

  const getNamaBanks = () => {
    const data = { page: 1, per_page: 100 };
    fetch(`${process.env.REACT_APP_API_BASE_URL}/banks`, {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        setNamaBank(data.response_data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const delateBank = (bank_id) => {
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/bank/delete/${bank_id}`)
      .then(function (response) {
        setSuccessAlert();
        getAllBanks();
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const setSuccessAlert = () => {
    setAlert(
      <SweetAlert
        success
        showConfirm
        confirmBtnText="Ok"
        title="Bank deleted"
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    )
  }

  const setQuestionAlert = (bank_id) => {
    setAlert(
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={() => delateBank(bank_id)}
        onCancel={hideAlert}
        focusCancelBtn
      />
    )
  }

  const hideAlert = () => {
    setAlert(null);
  }

  const getAllBanks = () => {
    const data = { page: 1, per_page: 100 };
    fetch(`${process.env.REACT_APP_API_BASE_URL}/banks`, {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        setAllBanks(data.response_data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }
  return (
    <>
      {alert}
      <SimpleHeader name="Bank" parentName="Admin" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <h3>List Bank</h3>
                  <div style={{ textAlign: "right" }}>
                    <Link class="btn btn-info" to="/admin/bank/create">
                      Add
                    </Link>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect3">Nama Bank</Label>
                        <Input
                          id="exampleFormControlSelect3"
                          name="bank_code"
                          type="select"
                          value={bank}
                          onChange={e => setBank(e.target.value)}
                        >
                          <option value="0">Pilih Nama Bank</option>
                          {
                            namaBank.map((bank, key) => {
                              return <option key={key} value={bank.bank_code}>{bank.bank_name}</option>
                            })
                          }
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button type="submit" color="primary">Search</Button>
                      <Button type="button" color="secondary">Reset</Button>
                    </Col>
                  </Row>
                </Form>
                <ToolkitProvider
                  rowNumber={rowNumber}
                  data={allBanks}
                  keyField="name"
                  columns={[
                    {
                      dataField: "no",
                      text: "#",
                      sort: true,
                      page: 1,
                      formatter: (cell, row, index) => {
                        return ++index
                      }
                    },
                    {
                      dataField: "bank_code",
                      text: "Bank Code",
                      sort: true,
                    },
                    {
                      dataField: "bank_name",
                      text: "Bank Name",
                      sort: true,
                    },
                    {
                      dataField: "clearing_code",
                      text: "Clearing Code",
                      sort: true,
                    },
                    {
                      dataField: "", text: "", formatter: (cell, row, index) => {
                        return (
                          <ButtonGroup>
                            <Button>
                              <Link
                                to={redirectPrefix + row.bank_id}
                                id={"tooltip_" + row.bank_id}
                              >
                                <i className="fas fa-user-edit" />
                              </Link>
                            </Button>
                            <UncontrolledTooltip delay={0} target={"tooltip_" + row.bank_id}>
                              Edit
                            </UncontrolledTooltip>
                            <Button
                              id="btn-delete-bank"
                              onClick={() => setQuestionAlert(row.bank_id)}
                            >
                              <i className="fas fa-trash" />
                            </Button>
                            <UncontrolledTooltip
                              delay={0}
                              placement="top"
                              target="btn-delete-bank"
                            >
                              Delete Bank
                            </UncontrolledTooltip>
                          </ButtonGroup>
                        )
                      }
                    },
                  ]}
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      <BootstrapTable
                        {...props.baseProps}
                        bootstrap4={true}
                        bordered={false}
                        pagination={paginationFactory({ ...paginationOption })}
                        onTableChange
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
