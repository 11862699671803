/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Table,
  Row,
  Container,
  CardHeader
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";

export default function Promo() {
  return (
    <>
      <SimpleHeader name="Promo" parentName="Admin" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <div style={{textAlign: "right"}}>
                  <Link class="btn btn-info" to="">
                    Add
                  </Link>
                </div>
              </CardHeader>
              <CardBody>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th>
                        No
                      </th>
                      <th>
                        Nama Promo
                      </th>
                      <th>
                        Code Promo
                      </th>
                      <th>
                        Jenis Promo
                      </th>
                      <th>
                        Periode Promo
                      </th>
                      <th>
                        Status
                      </th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody className="list">
                    <tr>
                      <th>
                        1
                      </th>
                      <th>
                        Disc 30% with Visa
                      </th>
                      <td>
                        PR0001
                      </td>
                      <td>
                        Credit Card
                      </td>
                      <td>
                        17/02/2021
                      </td>
                      <td>
                        Active
                      </td>
                    </tr>
                    <tr>
                      <th>
                        2
                      </th>
                      <th>
                        Free upsize
                      </th>
                      <td>
                        PR0002
                      </td>
                      <td>
                        UpSize
                      </td>
                      <td>
                        17/02/2021 19:08
                      </td>
                      <td>
                        Inactive
                      </td>
                    </tr>
                    <tr>
                      <th>
                        3
                      </th>
                      <th>
                        Buy 1 Get 1
                      </th>
                      <td>
                        PR0003
                      </td>
                      <td>
                        Super Deal
                      </td>
                      <td>
                        17/02/2021 20:11
                      </td>
                      <td>
                        Active
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
