/*eslint-disable*/
import React from "react";
import {
  Card,
  Table,
  Container,
  Row,
  CardBody
} from "reactstrap";

import SimpleHeader from "components/Headers/SimpleHeader.js";

export default function PaketLangganan() {
  return (
    <>
      <SimpleHeader name="Paket Langganan" parentName="Admin" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardBody>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th>
                        No
                      </th>
                      <th>
                        Nama Paket
                      </th>
                      <th>
                        Fitur
                      </th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody className="list">
                    <tr>
                      <th>
                        1
                      </th>
                      <th>
                        Basic
                      </th>
                      <td>
                        Laporan Standar, Aplikasi Payment
                      </td>
                    </tr>
                    <tr>
                      <th>
                        2
                      </th>
                      <th>
                        Menengah
                      </th>
                      <td>
                        Credit Scoring, Installment, Loyalty
                      </td>
                    </tr>
                    <tr>
                      <th>
                        3
                      </th>
                      <th>
                        Penuh
                      </th>
                      <td>
                        PPOB, Cash Register, Inventory
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
