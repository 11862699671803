/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Label,
  FormGroup,
  Row,
  Col,
  Input
} from "reactstrap";

export default function DataMerchant() {
  const token = localStorage.token;
  const merchantPk = localStorage.getItem("merchantPk");
  const [bentukMerchant, setBentukMerchant] = useState("");
  const [project, setProject] = useState("");
  const [group, setGroup] = useState("");
  const [brand, setBrand] = useState("");
  const [badanHukum, setBadanHukum] = useState("");
  const [alamat, setAlamat] = useState("");
  const [negara, setNegara] = useState("");
  const [provinsi, setProvinsi] = useState("");
  const [kota, setKota] = useState("");
  const [district, setDistrict] = useState("");
  const [kodePos, setKodePos] = useState("");
  const [lokasiStatus, setLokasiStatus] = useState("");
  const [kategori, setKategori] = useState("");
  const [jamStart, setJamStart] = useState("");
  const [jameEnd, setJamEnd] = useState("");
  const [omset, setOmset] = useState("")
  const [jenisLangganan, setJenisLangganan] = useState("");
  const allowedRolesProject = ["ROLE_OPERATOR_PROJECT", "ROLE_SUPERVISOR_PROJECT", "ROLE_ADMIN_PROJECT"];
  const allowedRolesGroup = ["ROLE_OPERATOR_GROUP", "ROLE_SUPERVISOR_GROUP", "ROLE_ADMIN_GROUP"];

  useEffect(() => {
    getProfileByMk();
  }, []);

  const getProfileByMk = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/merchant/profile/${merchantPk}`, {
        method: 'GET', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(),
      })
      const data = await response.json();
      setBentukMerchant(data.response_data.entity_type);
      setProject(data.response_data.project_name);
      setGroup(data.response_data.group_name);
      setBrand(data.response_data.merchant_name);
      setBadanHukum(data.response_data.corporation_name);
      setAlamat(data.response_data.merchant_address);
      setNegara(data.response_data.country_name);
      setProvinsi(data.response_data.region_name);
      setKota(data.response_data.regency_name);
      setDistrict(data.response_data.district_name);
      setKodePos(data.response_data.zip_code);
      setLokasiStatus(data.response_data.zip_code);
      setKategori(data.response_data.merchant_category_name);
      setJamStart(data.response_data.operational_hour_start);
      setJamEnd(data.response_data.operational_hour_end);
      setOmset(data.response_data.turnover);
      setJenisLangganan(data.response_data.subscribe_type);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  return (
    <>
      <Card>
        <CardBody>
          {
            allowedRolesProject.includes(localStorage.getItem("authority"))
              ?
              <Row>
                <Col md="12">
                  <FormGroup>
                    <Label
                      className="form-control-label"
                      htmlFor="exampleFormControlSelect3"
                    >
                      Bentuk Merchant
                            </Label>
                    <Input
                      disabled="true"
                      type="text"
                      placeholder="Masukan Bentuk Merchant"
                      value={bentukMerchant === 1 ? "Perorangan" : "Badan Hukum"}
                    />
                  </FormGroup>
                </Col>
              </Row>
              :
              allowedRolesGroup.includes(localStorage.getItem("authority"))
                ?
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlSelect3"
                      >
                        Bentuk Merchant
                          </Label>
                      <Input
                        disabled="true"
                        type="text"
                        placeholder="Masukan Bentuk Merchant"
                        value={bentukMerchant === 1 ? "Perorangan" : "Badan Hukum"}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        Project
                          </Label>
                      <Input
                        disabled="true"
                        type="text"
                        placeholder="Masukan Project"
                        value={project}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                :
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlSelect3"
                      >
                        Bentuk Merchant
                        </Label>
                      <Input
                        disabled="true"
                        type="text"
                        placeholder="Masukan Bentuk Merchant"
                        value={bentukMerchant === 1 ? "Perorangan" : "Badan Hukum"}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        Project
                        </Label>
                      <Input
                        disabled="true"
                        type="text"
                        placeholder="Masukan Project"
                        value={project}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        Group Merchant
                        </Label>
                      <Input
                        disabled="true"
                        type="text"
                        placeholder="Masukan Group Merchant"
                        value={group}
                      />
                    </FormGroup>
                  </Col>
                </Row>
          }
          <FormGroup>
            <Label
              className="form-control-label"
              htmlFor="exampleFormControlInput1"
            >
              Nama / Brand
                    </Label>
            <Input
              disabled="true"
              type="text"
              placeholder="Masukan Nama / Brand"
              value={brand}
            />
          </FormGroup>
          <FormGroup>
            <Label
              className="form-control-label"
              htmlFor="exampleFormControlInput1"
            >
              Nama Badan Hukum
                    </Label>
            <Input
              disabled="true"
              type="text"
              placeholder="Masukan Nama Badan Hukum"
              value={badanHukum}
            />
          </FormGroup>
          <FormGroup>
            <Label
              className="form-control-label"
              htmlFor="exampleFormControlInput1"
            >
              Alamat
                    </Label>
            <Input
              disabled="true"
              type="textarea"
              placeholder="Masukan Alamat"
              value={alamat}
            />
          </FormGroup>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label
                  className="form-control-label"
                  htmlFor="exampleFormControlInput1"
                >
                  Negara
                            </Label>
                <Input
                  disabled="true"
                  type="text"
                  value={negara}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label
                  className="form-control-label"
                  htmlFor="exampleFormControlInput1"
                >
                  Provinsi
                            </Label>
                <Input
                  disabled="true"
                  type="text"
                  value={provinsi}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label
                  className="form-control-label"
                  htmlFor="exampleFormControlInput1"
                >
                  Kab./Kota
                            </Label>
                <Input
                  disabled="true"
                  type="text"
                  value={kota}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label
                  className="form-control-label"
                  htmlFor="exampleFormControlInput1"
                >
                  Kecamatan
                            </Label>
                <Input
                  disabled="true"
                  type="text"
                  value={district}
                />
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <Label
              className="form-control-label"
              htmlFor="exampleFormControlInput1"
            >
              Kode Pos
                    </Label>
            <Input
              disabled="true"
              type="text"
              value={kodePos}
            />
          </FormGroup>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label
                  className="form-control-label"
                  htmlFor="exampleFormControlSelect3"
                >
                  Status Lokasi
                            </Label>
                <Input
                  disabled="true"
                  type="text"
                  value={lokasiStatus ? "Milik Sendiri" : "Sewa"}
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label
                  className="form-control-label"
                  htmlFor="exampleFormControlSelect3"
                >
                  Berlaku s/d
                            </Label>
                <Input
                  disabled="true"
                  type="text"
                  value="04/03/2021"
                />
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <Label
              className="form-control-label"
              htmlFor="exampleFormControlSelect3"
            >
              Kategori
                    </Label>
            <Input
              disabled="true"
              type="text"
              value={kategori}
            />
          </FormGroup>
          <FormGroup>
            <Label
              className="form-control-label"
              htmlFor="example-time-input"
            >
              Jam Operasional
                    </Label>
            <Row>
              <Col md="6">
                <Input
                  disabled="true"
                  type="text"
                  value={jamStart}
                />
              </Col>
              <Col md="6">
                <Input
                  disabled="true"
                  type="text"
                  value={jameEnd}
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Label
              className="form-control-label"
              htmlFor="exampleFormControlInput1"
            >
              Omzet
                    </Label>
            <Input
              disabled="true"
              type="text"
              placeholder="Masukan Omzet"
              value={omset}
            />
          </FormGroup>
          <FormGroup>
            <Label
              className="form-control-label"
              htmlFor="jenisLangganan"
            >
              Jenis Langganan
                    </Label>
            <Input
              disabled="true"
              type="text"
              value={jenisLangganan === 1 ? "Basic" : jenisLangganan === 2 ? "Menengah" : "Penuh"}
            />
          </FormGroup>
        </CardBody>
      </Card>
    </>
  );
}
