/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Label,
  FormGroup,
  Row,
  Input,
  Container,
  CardHeader,
  CardFooter,
  Button,
  Form, FormFeedback
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import axios from 'axios';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import moment from 'moment';

export default function CreateTerminal(props) {
  const token = localStorage.token;
  const location = props.location.state;
  const [dataMerchant, setDataMerchant] = React.useState([]);
  const [statusList, setStatusList] = useState([]);
  let history = useHistory();
  const [isLoading, setLoading] = useState(false);

  const [serialNumber, setSerialNumber] = useState("");
  const [serialNumberError, setSerialNumberError] = useState(null);
  const [terminalCode, setTerminalCode] = useState("");
  const [terminalCodeError, setTerminaCodeError] = useState(null);
  const [type, setType] = useState("");
  const [types, setTypes] = useState([]);
  const [typeError, setTypeError] = useState(null);
  const [merchant, setMerchant] = useState("");
  const [merchantError, setMerchantError] = useState(null);
  const [reffNo, setReffNo] = useState("");
  const [reffNoError, setReffNoError] = useState(null);
  const [installationDate, setInstallationDate] = useState("");
  const [installationDataError, setInstallationDateError] = useState(null);
  const [status, setStatus] = useState("");
  const [statusError, setStatusError] = useState(null);
  const [merchantNameEdc, setMerchantNameEdc] = useState("");
  const [address, setAddress] = useState("");
  const [addressTwo, setAddressTwo] = useState("");
  const [addressThree, setAddressThree] = useState("");

  const role = localStorage.getItem("authority");
  const merchantPk = localStorage.getItem("merchantPk");
  const projectId = localStorage.getItem("projectId");
  const allowedRoles = ["ROLE_SUPERADMIN"];
  const allowedProject = ["ROLE_OPERATOR_PROJECT", "ROLE_SUPERVISOR_PROJECT", "ROLE_ADMIN_PROJECT"];
  const allowedGroup = ["ROLE_OPERATOR_GROUP", "ROLE_SUPERVISOR_GROUP", "ROLE_ADMIN_GROUP"];
  const allowedMerchant = ["ROLE_OPERATOR_MERCHANT", "ROLE_SUPERVISOR_MERCHANT", "ROLE_ADMIN_MERCHANT"];

  const [errorSerialNumberMessage, setErrorSerialNumberMessage] = useState("");

  const validateForm = () => {
    let error = false;
    if (serialNumber === "") {
      setSerialNumberError("invalid");
      error = true;
    }
    if (terminalCode === "") {
      setTerminaCodeError("invalid");
      error = true;
    }
    if (type === "") {
      setTypeError("invalid");
      error = true;
    }
    if (merchant === "") {
      setMerchantError("invalid");
      error = true;
    }
    if (reffNo === "") {
      setReffNoError("invalid");
      error = true;
    }
    if (installationDate === "") {
      setInstallationDateError("invalid");
      error = true;
    }
    if (status === "") {
      setStatusError("invalid");
      error = true;
    }
    return error;
  };

  useEffect(() => {
    getMerchant();
    getStatus();
    getType();
  }, []);

  const getType = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/terminaltype/list`, {
      method: 'GET', // or 'PUT'
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(),
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setTypes(data.response_data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const getStatus = () => {
    const data = { type: "terminal" }
    fetch(`${process.env.REACT_APP_API_BASE_URL}/status`, {
      method: 'POST', // or 'PUT'
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        setStatusList(data.response_data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const getMerchant = () => {
    if (allowedRoles.includes(localStorage.getItem("authority"))) {
      let data = {
        role: role
      }
      fetch(`${process.env.REACT_APP_API_BASE_URL}/merchants/list`, {
        method: 'POST', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(response => response.json())
        .then(data => {
          setDataMerchant(data.response_data);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
    if (allowedProject.includes(localStorage.getItem("authority"))) {
      let data = { role: role, project_id: parseInt(merchantPk) }
      fetch(`${process.env.REACT_APP_API_BASE_URL}/merchants/list`, {
        method: 'POST', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(response => response.json())
        .then(data => {
          setDataMerchant(data.response_data);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
    if (allowedGroup.includes(localStorage.getItem("authority"))) {
      let data = { role: role, project_id: parseInt(projectId), group_id: parseInt(merchantPk) }
      fetch(`${process.env.REACT_APP_API_BASE_URL}/merchants/list`, {
        method: 'POST', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(response => response.json())
        .then(data => {
          setDataMerchant(data.response_data);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
    if (allowedMerchant.includes(localStorage.getItem("authority"))) {
      let data = { role: role, merchantPk: parseInt(merchantPk) }
      fetch(`${process.env.REACT_APP_API_BASE_URL}/merchants/list`, {
        method: 'POST', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(response => response.json())
        .then(data => {
          setDataMerchant(data.response_data);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  }

  function CreateData() {
    setLoading(true);
    let data = {
      serial_number: serialNumber,
      terminal_code: terminalCode,
      terminalTypeId: parseInt(type),
      merchant_key: parseInt(merchant),
      merchantName: merchantNameEdc,
      merchantAddress1: address,
      merchantAddress2: addressTwo,
      merchantAddress3: addressThree,
      reference_no: reffNo,
      installation_date: moment(installationDate).format("DD/MM/yyyy"),
      status: parseInt(status)
    }
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/terminal/save`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    })
      .then(function (response) {
        if (response.data.error) {
          showErrors(response.data.error);
          return;
        }
        history.push("/admin/terminal");
      })
      .then(json => {
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const showErrors = (errors) => {
    setSerialNumberError("invalid");
    setErrorSerialNumberMessage(errors.error_message);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      CreateData();
    }
  }
  return (
    <>
      <SimpleHeader name="Daftar Terminal" parentName="Admin" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardBody>
                <Form onSubmit={handleSubmit} invalid>
                  <CardHeader>
                    <h3>Daftar Terminal</h3>
                  </CardHeader>
                  <CardBody>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="serial_number"
                      >
                        Serial Number
                                            </Label>
                      <Input
                        type="text"
                        name="serial_number"
                        placeholder="Masukan Serial Number"
                        value={serialNumber}
                        invalid={serialNumberError === "invalid"}
                        onChange={(e) => {
                          setSerialNumber(e.target.value);
                          if (e.target.value !== "") {
                            setSerialNumberError("");
                          }
                        }}
                      />
                      <FormFeedback>
                        {
                          serialNumber === "" ? "Serial Number Tidak Boleh Kosong" : errorSerialNumberMessage
                        }
                      </FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        Terminal Code
                                            </Label>
                      <Input
                        type="text"
                        name="terminal_code"
                        placeholder="Masukan Terminal Code"
                        value={terminalCode}
                        invalid={terminalCodeError === "invalid"}
                        onChange={(e) => {
                          setTerminalCode(e.target.value);
                          if (e.target.value !== "") {
                            setTerminaCodeError("");
                          }
                        }}
                      />
                      <FormFeedback>Terminal Code tidak boleh kosong</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        Tipe
                                            </Label>
                      <Input
                        name="tipe"
                        type="select"
                        value={parseInt(type)}
                        invalid={typeError === "invalid"}
                        onChange={(e) => {
                          setType(e.target.value);
                          if (e.target.value !== "") {
                            setTypeError("");
                          }
                        }}
                      >
                        <option value={0}>Pilih Tipe</option>
                        {
                          types.map((type, key) => {
                            return <option key={key} value={type.terminalTypeId}>{type.terminalTypeName}</option>
                          })
                        }

                      </Input>
                      <FormFeedback>Tipe tidak boleh kosong</FormFeedback>
                    </FormGroup>
                    {
                      location === undefined ?
                        <FormGroup>
                          <Label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect3"
                          >
                            Merchant
                                            </Label>
                          <Input
                            name="merchant_key"
                            type="select"
                            value={parseInt(merchant)}
                            invalid={merchantError === "invalid"}
                            onChange={(e) => {
                              setMerchant(e.target.value);
                              if (e.target.value !== "") {
                                setMerchantError("");
                              }
                            }}
                          >
                            <option value={0}>Pilih Merchant</option>
                            {
                              dataMerchant.map((merchant, key) => {
                                return <option key={key} value={merchant.merchant_pk}>{merchant.merchant_name}</option>
                              })
                            }

                          </Input>
                          <FormFeedback>Merchant harus di pilih</FormFeedback>
                        </FormGroup>
                        :
                        <FormGroup>
                          <Label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect3"
                          >
                            Merchant
                                            </Label>
                          <Input
                            name="merchant_key"
                            type="select"
                            value={parseInt(merchant)}
                            invalid={merchantError === "invalid"}
                            onChange={(e) => {
                              setMerchant(e.target.value);
                              if (e.target.value !== "") {
                                setMerchantError("");
                              }
                            }}
                          >
                            <option value={0}>Pilih Merchant</option>
                            {
                              dataMerchant.map((merchant, key) => {
                                return <option key={key} value={merchant.merchant_pk}>{merchant.merchant_name}</option>
                              })
                            }

                          </Input>
                          <FormFeedback>Merchant harus dipilih</FormFeedback>
                        </FormGroup>
                    }
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        Nama Merchant Edc
                                            </Label>
                      <Input
                        type="text"
                        name="merchantNameEdc"
                        placeholder="Masukan Nama Merchant Edc"
                        value={merchantNameEdc}
                        onChange={(e) => {
                          setMerchantNameEdc(e.target.value);
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        Alamat 1
                                            </Label>
                      <Input
                        type="text"
                        name="address1"
                        placeholder="Masukan Alamat 1"
                        value={address}
                        onChange={(e) => {
                          setAddress(e.target.value);
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        Alamat 2
                                            </Label>
                      <Input
                        type="text"
                        name="address2"
                        placeholder="Masukan Alamat 2"
                        value={addressTwo}
                        onChange={(e) => {
                          setAddressTwo(e.target.value);
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        Alamat 3
                                            </Label>
                      <Input
                        type="text"
                        name="address3"
                        placeholder="Masukan Alamat 3"
                        value={addressThree}
                        onChange={(e) => {
                          setAddressThree(e.target.value);
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        Ref No
                                            </Label>
                      <Input
                        type="text"
                        name="reference_no"
                        placeholder="Masukan Referance No"
                        value={reffNo}
                        invalid={reffNoError === "invalid"}
                        onChange={(e) => {
                          setReffNo(e.target.value);
                          if (e.target.value !== "") {
                            setReffNoError("");
                          }
                        }}
                      />
                      <FormFeedback>Ref No tidak boleh kosong</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        Tanggal Pasang
                                            </Label>
                      <Input
                        name="installation_date"
                        type="date"
                        value={installationDate}
                        invalid={installationDataError === "invalid"}
                        onChange={(e) => {
                          setInstallationDate(e.target.value);
                          if (e.target.value !== "") {
                            setInstallationDateError("");
                          }
                        }}
                      />
                      <FormFeedback>Tanggal Pasang tidak boleh kosong</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        Status
                                            </Label>
                      <Input
                        name="status"
                        type="select"
                        value={status}
                        invalid={statusError === "invalid"}
                        onChange={(e) => {
                          setStatus(e.target.value);
                          if (e.target.value !== "") {
                            setStatusError("");
                          }
                        }}
                      >
                        <option value={0}>-- Pilih Status --</option>
                        {
                          statusList.map((stat, key) => {
                            return <option key={key} value={stat.value}>{stat.description}</option>
                          })
                        }
                      </Input>
                      <FormFeedback>status harus dipilih</FormFeedback>
                    </FormGroup>
                  </CardBody>
                  <CardFooter>
                    {!isLoading && (<Button color="primary" type="submit">
                      Simpan
                    </Button>)}
                    {isLoading && (<Button color="primary" disabled>
                      <i className="fas fa-spinner fa-spin"></i>{""}
                      Loading...
                    </Button>)}
                    <Link className="btn btn-info" to={
                      {
                        pathname: props.location.state !== undefined ? props.location.state.from : "/admin/terminal",
                        state: {
                          activeTab: "3",
                          merchantPk: props.location.state !== undefined ? props.location.state.merchantPk : ""
                        }
                      }}>
                      Kembali
                                        </Link>
                  </CardFooter>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
