/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Label,
  FormGroup,
  Row,
  Input,
  Container,
  CardHeader,
  CardFooter,
  Button,
  Form, FormFeedback
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import axios from 'axios';
import SimpleHeader from "components/Headers/SimpleHeader.js";

export default function EditEdcBsi(props) {
  const token = localStorage.getItem("token");
  let history = useHistory();
  const [isLoading, setLoading] = useState(false);

  const [serialNumber, setSerialNumber] = useState("");
  const [tid, setTid] = useState("");
  const [mid, setMid] = useState("");
  const [reason, setReason] = useState("");
  const [reasonError, setReasonError] = useState(null);
  const [merchantName, setMerchantName] = useState("");
  const [lastRequest, setLastRequest] = useState("");
  const [status, setStatus] = useState("");

  const validateForm = () => {
    let error = false;
    if (reason === "") {
      setReasonError("invalid");
      error = true;
    }
    return error;
  };

  useEffect(() => {
    getAllEdcBsi();
  }, []);

  const getAllEdcBsi = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/edc-bsi/${props.match.params.id}`, {
      method: 'GET', // or 'PUT'
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(),
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setSerialNumber(data.response_data.serial_number);
        setTid(data.response_data.terminal_id);
        setMid(data.response_data.merchant_id);
        setReason(data.response_data.reason);
        setLastRequest(data.response_data.last_request);
        setMerchantName(data.response_data.merchant_name);
        setStatus(data.response_data.status);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  function UpdateData() {
    setLoading(true);
    let data = {
      serial_number: serialNumber,
      merchant_id: mid,
      terminal_id: tid,
      reason: reason,
      last_request: lastRequest,
      merchant_name: merchantName,
      status: status
    }
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/edc-bsi/update/${props.match.params.id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then(function (response) {
        history.push("/admin/edc-bsi");
      })
      .then(json => {
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      UpdateData();
    }
  }
  return (
    <>
      <SimpleHeader name="Edit EDC BSI" parentName="Admin" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardBody>
                <Form onSubmit={handleSubmit} >
                  <CardHeader>
                    <h3>Edit EDC BSI</h3>
                  </CardHeader>
                  <CardBody>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="type"
                      >
                        Serial Number
                                            </Label>
                      <Input
                        disabled
                        type="text"
                        name="serial_number"
                        placeholder="Masukan Nama Type"
                        value={serialNumber}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="type"
                      >
                        Merchant Name
                                            </Label>
                      <Input
                        disabled
                        type="text"
                        name="merchant_name"
                        placeholder="Masukan Merchant Name"
                        value={merchantName}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="type"
                      >
                        TID
                                            </Label>
                      <Input
                        disabled
                        type="text"
                        name="tid"
                        placeholder="Masukan TID"
                        value={tid}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="type"
                      >
                        MID
                                            </Label>
                      <Input
                        disabled
                        type="text"
                        name="mid"
                        placeholder="Masukan MID"
                        value={mid}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="reason"
                      >
                        Reason
                                            </Label>
                      <Input
                        type="textarea"
                        name="reason"
                        placeholder="Masukan Reason"
                        value={reason}
                        invalid={reasonError === "invalid"}
                        onChange={(e) => {
                          setReason(e.target.value);
                          if (e.target.value !== "") {
                            setReasonError("");
                          }
                        }}
                      />
                      <FormFeedback>Reason tidak boleh kosong</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="type"
                      >
                        Last Update
                                            </Label>
                      <Input
                        disabled
                        type="text"
                        name="last request"
                        placeholder="Masukan Last Request"
                        value={lastRequest}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="type"
                      >
                        Status
                                            </Label>
                      <Input
                        disabled
                        type="text"
                        name="status"
                        placeholder="Masukan status"
                        value={status}
                      />
                    </FormGroup>
                  </CardBody>
                  <CardFooter>
                    {!isLoading && (<Button color="primary" type="submit">
                      Simpan
                    </Button>)}
                    {isLoading && (<Button color="primary" disabled>
                      <i className="fas fa-spinner fa-spin"></i>{""}
                      loading...
                    </Button>)}
                    <Link className="btn btn-info" to="/admin/edc-bsi">
                      Kembali
                    </Link>
                  </CardFooter>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
