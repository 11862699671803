/*eslint-disable*/
import React from "react";
import {
    Card,
    CardBody,
    Label,
    FormGroup,
    Row,
    Input,
    Container,
    CardHeader,
    CardFooter,
    Button,
    Form
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import axios from 'axios';
import SimpleHeader from "components/Headers/SimpleHeader.js";

export default function CreateBank() {
    const [banks, setBanks] = React.useState({
        bank_code: "",
        bank_name: "",
        clearing_code: ""
    });
    let history = useHistory();

    const handleChange = (event) => {
        setBanks({ ...banks, [event.target.name]: event.target.value })
    }

    function CreateBank() {
        let data = banks
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/bank/save`, data)
            .then(function (response) {
                history.push("/admin/bank");
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        CreateBank();
    }
    return (
        <>
            <SimpleHeader name="Daftar Bank" parentName="Admin" />
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardBody>
                                <Form onSubmit={handleSubmit} >
                                    <CardHeader>
                                        <h3>Daftar Bank</h3>
                                    </CardHeader>
                                    <CardBody>
                                        <FormGroup>
                                            <Label
                                                className="form-control-label"
                                                htmlFor="serial_number"
                                            >
                                                Bank Code
                                            </Label>
                                            <Input
                                                required
                                                type="text"
                                                name="bank_code"
                                                placeholder="Masukan Bank Code"
                                                value={banks.bank_code}
                                                onChange={handleChange}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label
                                                className="form-control-label"
                                                htmlFor="exampleFormControlInput1"
                                            >
                                                Bank Name
                                            </Label>
                                            <Input
                                                required
                                                type="text"
                                                name="bank_name"
                                                placeholder="Masukan Bank Name"
                                                value={banks.bank_name}
                                                onChange={handleChange}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label
                                                className="form-control-label"
                                                htmlFor="exampleFormControlInput1"
                                            >
                                                Clearing Code
                                            </Label>
                                            <Input
                                                required
                                                type="text"
                                                name="clearing_code"
                                                placeholder="Masukan Clearing Code"
                                                value={banks.clearing_code}
                                                onChange={handleChange}
                                            />
                                        </FormGroup>
                                    </CardBody>
                                    <CardFooter>
                                        <Button color="primary" type="submit">
                                            Simpan
                                        </Button>
                                        <Link class="btn btn-info" to="/admin/bank">
                                            Kembali
                                        </Link>
                                    </CardFooter>
                                </Form>
                            </CardBody>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
}
