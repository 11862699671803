/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { Card, Button, CardBody, CardHeader, UncontrolledTooltip, ButtonGroup } from 'reactstrap';
import { Link } from "react-router-dom";
import axios from 'axios';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import SweetAlert from "react-bootstrap-sweetalert";

export default function AcquirerMdr(props) {
  const token = localStorage.getItem("token");
  const acquirerId = props.acquirerId;
  const acquirerName = props.acquirerName;
  const [acquirerMdr, setAcquirerMdr] = useState([]);
  const redirectPrefix = `/admin/acquirer/mdr/${acquirerId}/edit/`;
  const [alert, setAlert] = React.useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [rowIndex, setRowIndex] = useState(0);

  let paginationOption = {
    page: 1,
    alwaysShowAllBtns: true,
    override: true,
    showTotal: true,
    withFirstAndLast: false,
    onPageChange: (page) => {
      calculateRowIndex(page, currentPageSize);
    },
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                onSizePerPageChange(e.target.value);
                setCurrentPageSize(e.target.value);
                calculateRowIndex(currentPage, e.target.value);
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          }{" "}
      entries.
    </label>
      </div>
    ),
  }

  const calculateRowIndex = (currentPage, currentPageSize) => {
    setRowIndex((currentPage - 1) * currentPageSize);
  }

  useEffect(() => {
    getAllAquirerMdr();
  }, [props.acquirerId]);

  const getAllAquirerMdr = () => {
    const data = { page: 1, per_page: 100, acquirer_id: acquirerId };
    fetch(`${process.env.REACT_APP_API_BASE_URL}/acqtranstypes`, {
      method: 'POST', // or 'PUT'
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        setAcquirerMdr(data.response_data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const delateAcquirerHost = (acq_transtype_id) => {
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/acqtranstype/delete/${acq_transtype_id}`, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then(function (response) {
        setSuccessAlert();
        getAllAquirerMdr();
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const setSuccessAlert = () => {
    setAlert(
      <SweetAlert
        success
        showConfirm
        confirmBtnText="Ok"
        title="Acquirer deleted"
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    )
  }

  const setQuestionAlert = (acq_transtype_id) => {
    setAlert(
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={() => delateAcquirerHost(acq_transtype_id)}
        onCancel={hideAlert}
        focusCancelBtn
      />
    )
  }

  const hideAlert = () => {
    setAlert(null);
  }
  return (
    <>
      {alert}
      <Card>
        <CardHeader>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h3>List Acquirer Transtype ({acquirerName})</h3>
            <div style={{ textAlign: "right" }}>
              <Link className="btn btn-info" to={`/admin/acquirer/mdr/${acquirerId}/create`}>
                Add
              </Link>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <ToolkitProvider
            rowNumber={rowIndex}
            data={acquirerMdr}
            keyField="acq_transtype_id"
            columns={[
              {
                dataField: "no",
                text: "#",
                sort: true,
                page: 1,
                formatter: (cell, row, index) => {
                  let currentRow = ++index;
                  return currentRow + rowIndex;
                }
              },
              {
                dataField: "transaction_type",
                text: "Host Type",
                sort: true,
              },
              {
                dataField: "transaction_classification",
                text: "Card Type",
                sort: true,
              },
              {
                dataField: "mdr_value",
                text: "MDR Value",
                sort: true,
              },
              {
                dataField: "", text: "", formatter: (cell, row, index) => {
                  return (
                    <ButtonGroup>
                      <Button>
                        <Link
                          to={redirectPrefix + row.acq_transtype_id}
                          id={"tooltip_" + row.acq_transtype_id}
                        >
                          <i className="fas fa-user-edit" />
                        </Link>
                      </Button>
                      <UncontrolledTooltip delay={0} target={"tooltip_" + row.acq_transtype_id}>
                        Edit
                      </UncontrolledTooltip>
                      <Button
                        id="btn-acquirer"
                        onClick={() => setQuestionAlert(row.acq_transtype_id)}
                      >
                        <i className="fas fa-trash" />
                      </Button>
                      <UncontrolledTooltip
                        delay={0}
                        placement="top"
                        target="btn-acquirer"
                      >
                        Delete Acquirer
                      </UncontrolledTooltip>
                    </ButtonGroup>
                  )
                }
              },
            ]}
          >
            {(props) => (
              <div className="py-4 table-responsive">
                <BootstrapTable
                  {...props.baseProps}
                  bootstrap4={true}
                  bordered={false}
                  pagination={paginationFactory({ ...paginationOption })}
                />
              </div>
            )}
          </ToolkitProvider>
        </CardBody>
      </Card>
    </>
  );
}
