/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { Card, Button, CardBody, CardHeader, UncontrolledTooltip, ButtonGroup, Row, Col, Label, Form, FormGroup, Input } from 'reactstrap';
import { Link } from "react-router-dom";
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

export default function MerchantDokumen(props) {
  const token = localStorage.token;
  const merchantPk = props.merchantPk;
  const projectName = props.projectName;
  const [unitKerja, setUnitKerja] = useState([]);
  const [nameUnitKerja, setNameUnitKerja] = useState("");
  const redirectPrefix = `/admin/customer/daftar-project/unit-kerja/${merchantPk}/edit/`;
  const [alert, setAlert] = React.useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [rowIndex, setRowIndex] = useState(0);

  let paginationOption = {
    page: 1,
    alwaysShowAllBtns: true,
    override: true,
    showTotal: true,
    withFirstAndLast: false,
    onPageChange: (page) => {
      calculateRowIndex(page, currentPageSize);
    },
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                onSizePerPageChange(e.target.value);
                setCurrentPageSize(e.target.value);
                calculateRowIndex(currentPage, e.target.value);
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          }{" "}
      entries.
    </label>
      </div>
    ),
  }

  const calculateRowIndex = (currentPage, currentPageSize) => {
    setRowIndex((currentPage - 1) * currentPageSize);
  }

  useEffect(() => {
    getUnitKerja();
  }, [props.merchantPk]);

  const getUnitKerja = (workUnitName = null) => {
    let filter = { page: 1, per_page: 100, merchant_pk: merchantPk }
    if (workUnitName !== null) {
      filter = Object.assign(filter, { workUnitName: workUnitName })
    }
    const data = filter;
    fetch(`${process.env.REACT_APP_API_BASE_URL}/workunit`, {
      method: 'POST', // or 'PUT'
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        setUnitKerja(data.response_data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    getUnitKerja(nameUnitKerja);
  }

  const reset = () => {
    setNameUnitKerja("");
    getUnitKerja();
  }

  return (
    <>
      {alert}
      <Card>
        <CardHeader>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h3>List Unit Kerja ({projectName})</h3>
            <div style={{ textAlign: "right" }}>
              <Link className="btn btn-info" to={`/admin/customer/daftar-project/unit-kerja/${merchantPk}/create`}>
                Add
                  </Link>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <Form onSubmit={handleSubmit}>
            <Row md="12">
              <Col md="4">
                <FormGroup>
                  <Label
                    className="form-control-label"
                    htmlFor="exampleFormControlInput1"
                  >
                    Nama Unit Kerja
                      </Label>
                  <Input
                    id="example-date-input"
                    type="text"
                    placeholder="Masukan Nama Unit Kerja"
                    value={nameUnitKerja}
                    onChange={(e) => {
                      setNameUnitKerja(e.target.value);
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button type="submit" color="primary">Search</Button>
                <Button type="button" onClick={reset} color="secondary">Reset</Button>
              </Col>
            </Row>
          </Form>
          <ToolkitProvider
            rowNumber={rowIndex}
            data={unitKerja}
            keyField="workUnitPk"
            columns={[
              {
                dataField: "no",
                text: "#",
                sort: true,
                page: 1,
                formatter: (cell, row, index) => {
                  let currentRow = ++index;
                  return currentRow + rowIndex;
                }
              },
              {
                dataField: "workUnitName",
                text: "Nama Unit Kerja",
                sort: true,
              },
              {
                dataField: "", text: "", formatter: (cell, row, index) => {
                  return (
                    <ButtonGroup>
                      <Button>
                        <Link
                          to={redirectPrefix + row.workUnitPk}
                          id={"tooltip_" + row.workUnitPk}
                        >
                          <i className="fas fa-user-edit" />
                        </Link>
                      </Button>
                      <UncontrolledTooltip delay={0} target={"tooltip_" + row.workUnitPk}>
                        Edit
                      </UncontrolledTooltip>
                    </ButtonGroup>
                  )
                }
              },
            ]}
          >
            {(props) => (
              <div className="py-4 table-responsive">
                <BootstrapTable
                  {...props.baseProps}
                  bootstrap4={true}
                  bordered={false}
                  pagination={paginationFactory({ ...paginationOption })}
                />
              </div>
            )}
          </ToolkitProvider>
        </CardBody>
      </Card>
    </>
  );
}
