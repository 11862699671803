/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Login from "views/pages/login/Index.js";
import ResetPassword from "views/pages/login/ResetPassword.js";
import Dashboard from "views/pages/dashboards/Dashboard.js";
import MyProfil from "views/pages/myProfil/Index.js";
import ChangePassword from "views/pages/myProfil/ChangePassword.js";
import Profil from "views/pages/Profil.js";
import DaftarProject from "./views/pages/customers/project/Index.js";
import DaftarGroupMerchant from "views/pages/customers/group/Index.js";
import DaftarMerchant from "views/pages/customers/merchant/Index.js";
import PricingPlan from "views/pages/PricingPlan.js";
import DaftarTransaksi from "views/pages/DaftarTransaksi.js";
import DaftarTransaksiQr from "views/pages/DaftarTransaksiQr";
import DaftarSettlement from "views/pages/settlement/Index.js";
import DailyReport from "views/pages/report/DailyReport.js";
import MonthlyReport from "views/pages/report/MonthlyReport.js";
import Terminal from "views/pages/terminal/index.js";
import Promo from "views/pages/Promo.js";
import User from "views/pages/users/Index.js";
import Acquirer from "views/pages/acquirer/Acquirer.js";
import BinRange from "views/pages/bin_range/BinRange.js";
import Bank from "views/pages/bank/Index.js";
import Category from "views/pages/category/Index.js";
import History from "views/pages/history/Index.js";
import Lainnya from "views/pages/history/TransaksiLainnya.js";
import HistorySettlement from "views/pages/history/TransaksiSettlement.js";
import Type from "views/pages/type/Index.js";
import CreateMerchant from "views/pages/customers/merchant/Create.js";
import CreateProject from "views/pages/customers/project/Create.js";
import CreateGroupMerchant from "views/pages/customers/group/Create.js";
import CreateBinRange from "views/pages/bin_range/Create.js";
import EditBinRange from "views/pages/bin_range/Edit.js";
import CreateTerminal from "views/pages/terminal/Create.js";
import CreateBank from "views/pages/bank/Create.js";
import CreateAcquirer from "views/pages/acquirer/Create.js";
import CreateAcquirerHost from "views/pages/acquirer/acquirer_host/Create.js";
import CreateAcquirerMdr from "views/pages/acquirer/acquirer_types/Create.js";
import CreateTerminalParameter from "views/pages/terminal/terminal_params/Create.js";
import CreateUser from "views/pages/users/Create.js";
import CreateMerchantAcquirer from "views/pages/customers/merchant/merchant_dokumen/Create.js";
import MerchantMdr from "views/pages/customers/merchant/merchant_mdr/Create";
import CreateProjectMdr from "views/pages/customers/project/merchant_mdr/Create.js";
import CreateProjectAcquirer from "views/pages/customers/project/merchant_dokumen/Create.js";
import CreateGroupMdr from "views/pages/customers/group/merchant_mdr/Create.js";
import CreateGroupAcquirer from "views/pages/customers/group/merchant_dokumen/Create.js";
import CreateUnitKerja from "views/pages/customers/project/unit_kerja/Create.js";
import CreateCategory from "views/pages/category/Create.js";
import CreateType from "views/pages/type/Create.js";
import PaketLangganan from "views/pages/PaketLangganan.js";
import EditTerminal from "views/pages/terminal/Edit.js";
import EditBank from "views/pages/bank/Edit.js";
import EditAquirer from "views/pages/acquirer/Edit.js";
import EditAquirerHost from "views/pages/acquirer/acquirer_host/Edit";
import EditAcquirerMdr from "views/pages/acquirer/acquirer_types/Edit.js";
import EditTerminalParameter from "views/pages/terminal/terminal_params/Edit.js";
import EditUser from "views/pages/users/Edit.js";
import EditMerchant from "views/pages/customers/merchant/Edit.js";
import EditProject from "views/pages/customers/project/Edit.js";
import EditGroup from "views/pages/customers/group/Edit.js";
import EditMerchantMdr from "views/pages/customers/merchant/merchant_mdr/Edit.js";
import EditMerchantAcquirer from "views/pages/customers/merchant/merchant_dokumen/Edit.js";
import EditProjectMdr from "views/pages/customers/project/merchant_mdr/Edit.js";
import EditProjectAcquirer from "views/pages/customers/project/merchant_dokumen/Edit.js";
import EditGroupMdr from "views/pages/customers/group/merchant_mdr/Edit.js";
import EditGroupAcquirer from "views/pages/customers/group/merchant_dokumen/Edit";
import EditUnitKerja from "views/pages/customers/project/unit_kerja/Edit.js";
import EditCategory from "views/pages/category/Edit.js";
import EditType from "views/pages/type/Edit.js";

import EdcBsi from "views/pages/edc_bsi/Index.js";
import EditEdcBsi from "views/pages/edc_bsi/Edit.js";

const routes = [
  {
    path: "/login",
    name: "Login",
    miniName: "L",
    component: Login,
    layout: "/auth",
    hidden: true,
  },
  {
    path: "/reset-password",
    name: "Reset Password",
    miniName: "RP",
    component: ResetPassword,
    layout: "/auth",
    hidden: true,
  },
  {
    path: "/dashboard",
    icon: "ni ni-chart-pie-35",
    name: "Dashboard",
    miniName: "D",
    component: Dashboard,
    layout: "/admin",
    roles: ["ROLE_SUPERADMIN", "ROLE_OPERATOR_BUSDEV", "ROLE_BOD", "ROLE_OPERATOR_FINANCE", "ROLE_SUPERVISOR_FINANCE", "ROLE_OPERATOR_PROJECT", "ROLE_SUPERVISOR_PROJECT", "ROLE_ADMIN_PROJECT", "ROLE_OPERATOR_GROUP", "ROLE_SUPERVISOR_GROUP", "ROLE_ADMIN_GROUP", "ROLE_OPERATOR_MERCHANT", "ROLE_SUPERVISOR_MERCHANT", "ROLE_ADMIN_MERCHANT"]
  },
  {
    path: "/my-profil",
    name: "My Profil",
    miniName: "MP",
    component: MyProfil,
    layout: "/admin",
    hidden: true,
    roles: ["ROLE_SUPERADMIN", "ROLE_OPERATOR_BUSDEV", "ROLE_BOD", "ROLE_OPERATOR_FINANCE", "ROLE_SUPERVISOR_FINANCE", "ROLE_OPERATOR_PROJECT", "ROLE_SUPERVISOR_PROJECT", "ROLE_ADMIN_PROJECT", "ROLE_OPERATOR_GROUP", "ROLE_SUPERVISOR_GROUP", "ROLE_ADMIN_GROUP", "ROLE_OPERATOR_MERCHANT", "ROLE_SUPERVISOR_MERCHANT", "ROLE_ADMIN_MERCHANT"]
  },
  {
    path: "/change-password",
    name: "Change Password",
    miniName: "CP",
    component: ChangePassword,
    layout: "/admin",
    hidden: true,
    roles: ["ROLE_SUPERADMIN", "ROLE_OPERATOR_BUSDEV", "ROLE_BOD", "ROLE_OPERATOR_FINANCE", "ROLE_SUPERVISOR_FINANCE", "ROLE_OPERATOR_PROJECT", "ROLE_SUPERVISOR_PROJECT", "ROLE_ADMIN_PROJECT", "ROLE_OPERATOR_GROUP", "ROLE_SUPERVISOR_GROUP", "ROLE_ADMIN_GROUP", "ROLE_OPERATOR_MERCHANT", "ROLE_SUPERVISOR_MERCHANT", "ROLE_ADMIN_MERCHANT"]
  },
  {
    path: "/profil",
    icon: "ni ni-circle-08",
    name: "Profil",
    miniName: "P",
    component: Profil,
    layout: "/admin",
    roles: ["ROLE_OPERATOR_BUSDEV", "ROLE_BOD", "ROLE_OPERATOR_FINANCE", "ROLE_SUPERVISOR_FINANCE", "ROLE_OPERATOR_PROJECT", "ROLE_SUPERVISOR_PROJECT", "ROLE_ADMIN_PROJECT", "ROLE_OPERATOR_GROUP", "ROLE_SUPERVISOR_GROUP", "ROLE_ADMIN_GROUP", "ROLE_OPERATOR_MERCHANT", "ROLE_SUPERVISOR_MERCHANT", "ROLE_ADMIN_MERCHANT"]
  },
  {
    collapse: true,
    name: "Customers",
    icon: "ni ni-shop",
    state: "customerCollapse",
    roles: ["ROLE_SUPERADMIN", "ROLE_OPERATOR_BUSDEV", "ROLE_BOD", "ROLE_OPERATOR_FINANCE", "ROLE_SUPERVISOR_FINANCE", "ROLE_OPERATOR_PROJECT", "ROLE_SUPERVISOR_PROJECT", "ROLE_ADMIN_PROJECT", "ROLE_OPERATOR_GROUP", "ROLE_SUPERVISOR_GROUP", "ROLE_ADMIN_GROUP"],
    views: [
      {
        path: "/customer/daftar-project/unit-kerja/:id/create",
        name: "Create Unit Kerja",
        miniName: "CRK",
        component: CreateUnitKerja,
        layout: "/admin",
        hidden: true,
        roles: ["ROLE_SUPERADMIN"]
      },
      {
        path: "/customer/daftar-project/unit-kerja/:id/edit/:id",
        name: "Edit Unit Kerja",
        miniName: "EUK",
        component: EditUnitKerja,
        layout: "/admin",
        hidden: true,
        roles: ["ROLE_SUPERADMIN"]
      },
      {
        path: "/customer/daftar-project/berkas-dokumen/:id/create",
        name: "Create Merchant Project Acquirer",
        miniName: "CMA",
        component: CreateProjectAcquirer,
        layout: "/admin",
        hidden: true,
        roles: ["ROLE_SUPERADMIN"]
      },
      {
        path: "/customer/daftar-project/berkas-dokumen/:id/edit/:id",
        name: "Edit Merchant Project Acquirer",
        miniName: "EMA",
        component: EditProjectAcquirer,
        layout: "/admin",
        hidden: true,
        roles: ["ROLE_OPERATOR_BUSDEV", "ROLE_BOD", "ROLE_OPERATOR_FINANCE", "ROLE_SUPERVISOR_FINANCE", "ROLE_OPERATOR_PROJECT", "ROLE_SUPERVISOR_PROJECT"]
      },
      {
        path: "/customer/daftar-project/mdr/:id/create",
        name: "Create Merchant Mdr",
        miniName: "CMM",
        component: CreateProjectMdr,
        layout: "/admin",
        hidden: true,
        roles: ["ROLE_OPERATOR_BUSDEV", "ROLE_BOD", "ROLE_OPERATOR_FINANCE", "ROLE_SUPERVISOR_FINANCE", "ROLE_OPERATOR_PROJECT", "ROLE_SUPERVISOR_PROJECT"]
      },
      {
        path: "/customer/daftar-project/mdr/:id/edit/:id",
        name: "Edit Merchant Project Mdr",
        miniName: "EMM",
        component: EditProjectMdr,
        layout: "/admin",
        hidden: true,
        roles: ["ROLE_OPERATOR_BUSDEV", "ROLE_BOD", "ROLE_OPERATOR_FINANCE", "ROLE_SUPERVISOR_FINANCE", "ROLE_OPERATOR_PROJECT", "ROLE_SUPERVISOR_PROJECT"]
      },
      {
        path: "/customers/daftar-project/edit/:id",
        name: "Edit Project",
        miniName: "EM",
        component: EditProject,
        layout: "/admin",
        hidden: true,
        roles: ["ROLE_SUPERADMIN", "ROLE_OPERATOR_BUSDEV", "ROLE_BOD", "ROLE_OPERATOR_FINANCE", "ROLE_SUPERVISOR_FINANCE",]
      },
      {
        path: "/customers/daftar-project/create",
        name: "Create Project",
        miniName: "CP",
        component: CreateProject,
        layout: "/admin",
        hidden: true,
        roles: ["ROLE_SUPERADMIN", "ROLE_OPERATOR_BUSDEV", "ROLE_BOD", "ROLE_OPERATOR_FINANCE", "ROLE_SUPERVISOR_FINANCE",]
      },
      {
        path: "/customers/daftar-project",
        name: "Daftar Project",
        miniName: "DP",
        component: DaftarProject,
        layout: "/admin",
        roles: ["ROLE_SUPERADMIN", "ROLE_OPERATOR_BUSDEV", "ROLE_BOD", "ROLE_OPERATOR_FINANCE", "ROLE_SUPERVISOR_FINANCE",]
      },
      {
        path: "/customer/daftar-group/berkas-dokumen/:id/create",
        name: "Create Merchant Project Acquirer",
        miniName: "CMA",
        component: CreateGroupAcquirer,
        layout: "/admin",
        hidden: true,
        roles: ["ROLE_SUPERADMIN", "ROLE_OPERATOR_BUSDEV", "ROLE_BOD", "ROLE_OPERATOR_FINANCE", "ROLE_SUPERVISOR_FINANCE",]
      },
      {
        path: "/customer/daftar-group/berkas-dokumen/:id/edit/:id",
        name: "Edit Merchant Group Acquirer",
        miniName: "EMA",
        component: EditGroupAcquirer,
        layout: "/admin",
        hidden: true,
        roles: ["ROLE_SUPERADMIN", "ROLE_OPERATOR_BUSDEV", "ROLE_BOD", "ROLE_OPERATOR_FINANCE", "ROLE_SUPERVISOR_FINANCE",]
      },
      {
        path: "/customer/daftar-group/mdr/:id/create",
        name: "Create Merchant Mdr",
        miniName: "CMM",
        component: CreateGroupMdr,
        layout: "/admin",
        hidden: true,
        roles: ["ROLE_SUPERADMIN", "ROLE_OPERATOR_BUSDEV", "ROLE_BOD", "ROLE_OPERATOR_FINANCE", "ROLE_SUPERVISOR_FINANCE",]
      },
      {
        path: "/customer/daftar-group/mdr/:id/edit/:id",
        name: "Edit Merchant Group Mdr",
        miniName: "EMM",
        component: EditGroupMdr,
        layout: "/admin",
        hidden: true,
        roles: ["ROLE_SUPERADMIN", "ROLE_OPERATOR_BUSDEV", "ROLE_BOD", "ROLE_OPERATOR_FINANCE", "ROLE_SUPERVISOR_FINANCE",]
      },
      {
        path: "/customers/daftar-group/edit/:id",
        name: "Edit Group",
        miniName: "EG",
        component: EditGroup,
        layout: "/admin",
        hidden: true,
        roles: ["ROLE_SUPERADMIN", "ROLE_OPERATOR_BUSDEV", "ROLE_BOD", "ROLE_OPERATOR_FINANCE", "ROLE_SUPERVISOR_FINANCE",]
      },
      {
        path: "/customers/daftar-group/create",
        name: "Create Group Merchant",
        miniName: "CGM",
        component: CreateGroupMerchant,
        layout: "/admin",
        hidden: true,
        roles: ["ROLE_SUPERADMIN", "ROLE_OPERATOR_BUSDEV", "ROLE_BOD", "ROLE_OPERATOR_FINANCE", "ROLE_SUPERVISOR_FINANCE",]
      },
      {
        path: "/customers/daftar-group",
        name: "Daftar Group Merchant",
        miniName: "DGM",
        component: DaftarGroupMerchant,
        layout: "/admin",
        roles: ["ROLE_SUPERADMIN", "ROLE_OPERATOR_BUSDEV", "ROLE_BOD", "ROLE_OPERATOR_FINANCE", "ROLE_SUPERVISOR_FINANCE", "ROLE_OPERATOR_PROJECT", "ROLE_SUPERVISOR_PROJECT", "ROLE_ADMIN_PROJECT"]
      },
      {
        path: "/customer/merchant/berkas-dokumen/:id/create",
        name: "Create Merchant Acquirer",
        miniName: "CMA",
        component: CreateMerchantAcquirer,
        layout: "/admin",
        hidden: true,
        roles: ["ROLE_SUPERADMIN"]
      },
      {
        path: "/customer/merchant/berkas-dokumen/:id/edit/:id",
        name: "Edit Merchant Acquirer",
        miniName: "EMA",
        component: EditMerchantAcquirer,
        layout: "/admin",
        hidden: true,
        roles: ["ROLE_SUPERADMIN"]
      },
      {
        path: "/customer/merchant/mdr/:id/create",
        name: "Create Merchant Mdr",
        miniName: "CMM",
        component: MerchantMdr,
        layout: "/admin",
        hidden: true,
        roles: ["ROLE_SUPERADMIN", "ROLE_OPERATOR_BUSDEV", "ROLE_BOD", "ROLE_OPERATOR_FINANCE", "ROLE_SUPERVISOR_FINANCE",]
      },
      {
        path: "/customer/merchant/mdr/:id/edit/:id",
        name: "Edit Merchant Mdr",
        miniName: "EMM",
        component: EditMerchantMdr,
        layout: "/admin",
        hidden: true,
        roles: ["ROLE_SUPERADMIN", "ROLE_OPERATOR_BUSDEV", "ROLE_BOD", "ROLE_OPERATOR_FINANCE", "ROLE_SUPERVISOR_FINANCE",]
      },
      {
        path: "/customers/daftar-merchant/edit/:id",
        name: "Edit Merchant",
        miniName: "EM",
        component: EditMerchant,
        layout: "/admin",
        hidden: true,
        roles: ["ROLE_SUPERADMIN", "ROLE_OPERATOR_BUSDEV", "ROLE_BOD", "ROLE_OPERATOR_FINANCE", "ROLE_SUPERVISOR_FINANCE",]
      },
      {
        path: "/customers/daftar-merchant/create",
        name: "Create Merchant",
        miniName: "CM",
        component: CreateMerchant,
        layout: "/admin",
        hidden: true,
        roles: ["ROLE_SUPERADMIN", "ROLE_OPERATOR_BUSDEV", "ROLE_BOD", "ROLE_OPERATOR_FINANCE", "ROLE_SUPERVISOR_FINANCE",]
      },
      {
        path: "/customers/daftar-merchant",
        name: "Daftar Merchant",
        miniName: "DM",
        component: DaftarMerchant,
        layout: "/admin",
        roles: ["ROLE_SUPERADMIN", "ROLE_OPERATOR_BUSDEV", "ROLE_BOD", "ROLE_OPERATOR_FINANCE", "ROLE_SUPERVISOR_FINANCE", "ROLE_OPERATOR_PROJECT", "ROLE_SUPERVISOR_PROJECT", "ROLE_ADMIN_PROJECT", "ROLE_OPERATOR_GROUP", "ROLE_SUPERVISOR_GROUP", "ROLE_ADMIN_GROUP"]
      },
    ],
  },
  {
    path: "/history",
    name: "Daftar Transaksi",
    icon: "ni ni-single-copy-04",
    miniName: "H",
    component: History,
    layout: "/admin",
    roles: ["ROLE_SUPERADMIN", "ROLE_OPERATOR_PROJECT", "ROLE_SUPERVISOR_PROJECT", "ROLE_ADMIN_PROJECT", "ROLE_OPERATOR_GROUP", "ROLE_SUPERVISOR_GROUP", "ROLE_ADMIN_GROUP", "ROLE_OPERATOR_MERCHANT", "ROLE_SUPERVISOR_MERCHANT", "ROLE_ADMIN_MERCHANT"]
  },
  {
    path: "/daftar-settlement",
    icon: "ni ni-money-coins",
    name: "Daftar Settlement",
    miniName: "DS",
    component: HistorySettlement,
    layout: "/admin",
    roles: ["ROLE_SUPERADMIN", "ROLE_OPERATOR_PROJECT", "ROLE_SUPERVISOR_PROJECT", "ROLE_ADMIN_PROJECT", "ROLE_OPERATOR_GROUP", "ROLE_SUPERVISOR_GROUP", "ROLE_ADMIN_GROUP", "ROLE_OPERATOR_MERCHANT", "ROLE_SUPERVISOR_MERCHANT", "ROLE_ADMIN_MERCHANT"]
  },
  {
    path: "/pricing-plan",
    icon: "ni ni-diamond",
    name: "Pricing Plan",
    miniName: "PP",
    component: PricingPlan,
    layout: "/admin",
    roles: []
  },
  {
    path: "/daftar-transaksi-qr",
    icon: "fas fa-qrcode",
    name: "Daftar Transaksi QR",
    miniName: "DTQ",
    component: DaftarTransaksiQr,
    layout: "/admin",
    roles: ["ROLE_SUPERADMIN", "ROLE_OPERATOR_PROJECT", "ROLE_SUPERVISOR_PROJECT", "ROLE_ADMIN_PROJECT", "ROLE_OPERATOR_GROUP", "ROLE_SUPERVISOR_GROUP", "ROLE_ADMIN_GROUP", "ROLE_OPERATOR_MERCHANT", "ROLE_SUPERVISOR_MERCHANT", "ROLE_ADMIN_MERCHANT"]
  },
  {
    path: "/daftar-transaksi",
    icon: "ni ni-single-copy-04",
    name: "Daftar Transaksi",
    miniName: "DT",
    component: DaftarTransaksi,
    layout: "/admin",
    roles: []
  },
  {
    path: "/daftar-settlement",
    icon: "ni ni-money-coins",
    name: "Daftar Settlement",
    miniName: "DS",
    component: DaftarSettlement,
    layout: "/admin",
    roles: []
  },
  {
    collapse: true,
    name: "Report",
    icon: "ni ni-collection",
    state: "reportCollapse",
    roles: [],
    views: [
      {
        path: "/daily-report",
        name: "Daily Report",
        miniName: "DR",
        component: DailyReport,
        layout: "/report",
        roles: []
      },
      {
        path: "/monthly-report",
        name: "Monthly Report",
        miniName: "MR",
        component: MonthlyReport,
        layout: "/report",
        roles: []
      },
    ],
  },
  {
    path: "/terminal/parameter/:id/edit/:id",
    name: "Edit Terminal Parameter",
    miniName: "ETP",
    component: EditTerminalParameter,
    layout: "/admin",
    hidden: true,
    roles: ["ROLE_SUPERADMIN", "ROLE_OPERATOR_BUSDEV", "ROLE_BOD", "ROLE_OPERATOR_FINANCE", "ROLE_SUPERVISOR_FINANCE"]
  },
  {
    path: "/terminal/parameter/:id/create",
    name: "Create Terminal Parameter",
    miniName: "CTP",
    component: CreateTerminalParameter,
    layout: "/admin",
    hidden: true,
    roles: ["ROLE_SUPERADMIN", "ROLE_OPERATOR_BUSDEV", "ROLE_BOD", "ROLE_OPERATOR_FINANCE", "ROLE_SUPERVISOR_FINANCE"]
  },
  {
    path: "/terminal/edit/:id",
    name: "Edit Terminal",
    miniName: "ET",
    component: EditTerminal,
    layout: "/admin",
    hidden: true,
    roles: ["ROLE_SUPERADMIN", "ROLE_OPERATOR_BUSDEV", "ROLE_BOD", "ROLE_OPERATOR_FINANCE", "ROLE_SUPERVISOR_FINANCE"]
  },
  {
    path: "/terminal/create",
    name: "Create Terminal",
    miniName: "CT",
    component: CreateTerminal,
    layout: "/admin",
    hidden: true,
    roles: ["ROLE_SUPERADMIN", "ROLE_OPERATOR_BUSDEV", "ROLE_BOD", "ROLE_OPERATOR_FINANCE", "ROLE_SUPERVISOR_FINANCE"]
  },
  {
    path: "/terminal",
    icon: "ni ni-tv-2",
    name: "Terminal",
    miniName: "T",
    component: Terminal,
    layout: "/admin",
    roles: ["ROLE_SUPERADMIN", "ROLE_OPERATOR_BUSDEV", "ROLE_BOD", "ROLE_OPERATOR_FINANCE", "ROLE_SUPERVISOR_FINANCE", "ROLE_OPERATOR_PROJECT", "ROLE_SUPERVISOR_PROJECT", "ROLE_ADMIN_PROJECT", "ROLE_OPERATOR_GROUP", "ROLE_SUPERVISOR_GROUP", "ROLE_ADMIN_GROUP", "ROLE_OPERATOR_MERCHANT", "ROLE_SUPERVISOR_MERCHANT", "ROLE_ADMIN_MERCHANT"]
  },
  {
    path: "/promo",
    icon: "ni ni-notification-70",
    name: "promo",
    miniName: "P",
    component: Promo,
    layout: "/admin",
    roles: []
  },
  {
    path: "/paket-langganan",
    icon: "ni ni-ui-04",
    name: "Paket Langganan",
    miniName: "PL",
    component: PaketLangganan,
    layout: "/admin",
    roles: []
  },
  {
    path: "/user/create",
    name: "Create User",
    miniName: "CU",
    component: CreateUser,
    layout: "/admin",
    hidden: true,
    roles: ["ROLE_SUPERADMIN", "ROLE_OPERATOR_BUSDEV", "ROLE_BOD", "ROLE_OPERATOR_FINANCE", "ROLE_SUPERVISOR_FINANCE", "ROLE_OPERATOR_PROJECT", "ROLE_SUPERVISOR_PROJECT", "ROLE_ADMIN_PROJECT", "ROLE_OPERATOR_GROUP", "ROLE_SUPERVISOR_GROUP", "ROLE_ADMIN_GROUP", "ROLE_OPERATOR_MERCHANT", "ROLE_SUPERVISOR_MERCHANT", "ROLE_ADMIN_MERCHANT"]
  },
  {
    path: "/user/edit/:id",
    name: "Edit User",
    miniName: "EU",
    component: EditUser,
    layout: "/admin",
    hidden: true,
    roles: ["ROLE_SUPERADMIN", "ROLE_OPERATOR_BUSDEV", "ROLE_BOD", "ROLE_OPERATOR_FINANCE", "ROLE_SUPERVISOR_FINANCE", "ROLE_OPERATOR_PROJECT", "ROLE_SUPERVISOR_PROJECT", "ROLE_ADMIN_PROJECT", "ROLE_OPERATOR_GROUP", "ROLE_SUPERVISOR_GROUP", "ROLE_ADMIN_GROUP", "ROLE_OPERATOR_MERCHANT", "ROLE_SUPERVISOR_MERCHANT", "ROLE_ADMIN_MERCHANT"]
  },
  {
    path: "/user",
    icon: "ni ni-circle-08",
    name: "User",
    miniName: "U",
    component: User,
    layout: "/admin",
    roles: ["ROLE_SUPERADMIN", "ROLE_OPERATOR_BUSDEV", "ROLE_BOD", "ROLE_OPERATOR_FINANCE", "ROLE_SUPERVISOR_FINANCE", "ROLE_OPERATOR_PROJECT", "ROLE_SUPERVISOR_PROJECT", "ROLE_ADMIN_PROJECT", "ROLE_OPERATOR_GROUP", "ROLE_SUPERVISOR_GROUP", "ROLE_ADMIN_GROUP", "ROLE_OPERATOR_MERCHANT", "ROLE_SUPERVISOR_MERCHANT", "ROLE_ADMIN_MERCHANT"]
  },
  {
    collapse: true,
    name: "Administration",
    icon: "ni ni-folder-17",
    state: "administratorCollapse",
    roles: ["ROLE_SUPERADMIN", "ROLE_OPERATOR_BUSDEV", "ROLE_BOD", "ROLE_OPERATOR_FINANCE", "ROLE_SUPERVISOR_FINANCE"],
    views: [
      {
        path: "/bank/edit/:id",
        name: "Edit Bank",
        miniName: "EB",
        component: EditBank,
        layout: "/admin",
        hidden: true,
        roles: ["ROLE_SUPERADMIN", "ROLE_OPERATOR_BUSDEV", "ROLE_BOD", "ROLE_OPERATOR_FINANCE", "ROLE_SUPERVISOR_FINANCE"]
      },
      {
        path: "/bank/create",
        name: "Create Bank",
        miniName: "CB",
        component: CreateBank,
        layout: "/admin",
        hidden: true,
        roles: ["ROLE_SUPERADMIN", "ROLE_OPERATOR_BUSDEV", "ROLE_BOD", "ROLE_OPERATOR_FINANCE", "ROLE_SUPERVISOR_FINANCE"]
      },
      {
        path: "/bank",
        name: "Banks",
        miniName: "B",
        component: Bank,
        layout: "/admin",
        roles: []
      },
      {
        path: "/acquirer/mdr/:id/create",
        name: "Create Acquirer Mdr",
        miniName: "CAM",
        component: CreateAcquirerMdr,
        layout: "/admin",
        hidden: true,
        roles: ["ROLE_SUPERADMIN", "ROLE_OPERATOR_BUSDEV", "ROLE_BOD", "ROLE_OPERATOR_FINANCE", "ROLE_SUPERVISOR_FINANCE"]
      },
      {
        path: "/acquirer/mdr/:id/edit/:id",
        name: "Edit Acquirer Mdr",
        miniName: "EA",
        component: EditAcquirerMdr,
        layout: "/admin",
        hidden: true,
        roles: ["ROLE_SUPERADMIN", "ROLE_OPERATOR_BUSDEV", "ROLE_BOD", "ROLE_OPERATOR_FINANCE", "ROLE_SUPERVISOR_FINANCE"]
      },
      {
        path: "/acquirer/host/:id/create",
        name: "Create Acquirer Host",
        miniName: "CAH",
        component: CreateAcquirerHost,
        layout: "/admin",
        hidden: true,
        roles: ["ROLE_SUPERADMIN", "ROLE_OPERATOR_BUSDEV", "ROLE_BOD", "ROLE_OPERATOR_FINANCE", "ROLE_SUPERVISOR_FINANCE"]
      },
      {
        path: "/acquirer/host/:id/edit/:id",
        name: "Edit Acquirer Host",
        miniName: "EA",
        component: EditAquirerHost,
        layout: "/admin",
        hidden: true,
        roles: ["ROLE_SUPERADMIN", "ROLE_OPERATOR_BUSDEV", "ROLE_BOD", "ROLE_OPERATOR_FINANCE", "ROLE_SUPERVISOR_FINANCE"]
      },
      {
        path: "/acquirer/edit/:id",
        name: "Edit Acquirer",
        miniName: "EA",
        component: EditAquirer,
        layout: "/admin",
        hidden: true,
        roles: ["ROLE_SUPERADMIN", "ROLE_OPERATOR_BUSDEV", "ROLE_BOD", "ROLE_OPERATOR_FINANCE", "ROLE_SUPERVISOR_FINANCE"]
      },
      {
        path: "/acquirer/create",
        name: "Create Acquirer",
        miniName: "CA",
        component: CreateAcquirer,
        layout: "/admin",
        hidden: true,
        roles: ["ROLE_SUPERADMIN", "ROLE_OPERATOR_BUSDEV", "ROLE_BOD", "ROLE_OPERATOR_FINANCE", "ROLE_SUPERVISOR_FINANCE"]
      },
      {
        path: "/acquirer",
        name: "Acquirers",
        miniName: "A",
        component: Acquirer,
        layout: "/admin",
        roles: ["ROLE_SUPERADMIN", "ROLE_OPERATOR_BUSDEV", "ROLE_BOD", "ROLE_OPERATOR_FINANCE", "ROLE_SUPERVISOR_FINANCE"]
      },
      {
        path: "/bin-range/edit/:id",
        name: "Edit Bin Range",
        miniName: "EBR",
        component: EditBinRange,
        layout: "/admin",
        hidden: true,
        roles: ["ROLE_SUPERADMIN", "ROLE_OPERATOR_BUSDEV", "ROLE_BOD", "ROLE_OPERATOR_FINANCE", "ROLE_SUPERVISOR_FINANCE"]
      },
      {
        path: "/bin-range/create",
        name: "Create Bin Range",
        miniName: "CBR",
        component: CreateBinRange,
        layout: "/admin",
        hidden: true,
        roles: ["ROLE_SUPERADMIN", "ROLE_OPERATOR_BUSDEV", "ROLE_BOD", "ROLE_OPERATOR_FINANCE", "ROLE_SUPERVISOR_FINANCE"]
      },
      {
        path: "/bin-range",
        name: "Bin Ranges",
        miniName: "BR",
        component: BinRange,
        layout: "/admin",
        roles: []
      },
      {
        path: "/category/edit/:id",
        name: "Edit Category",
        miniName: "EC",
        component: EditCategory,
        layout: "/admin",
        hidden: true,
        roles: ["ROLE_SUPERADMIN"]
      },
      {
        path: "/category/create",
        name: "Create Category",
        miniName: "CC",
        component: CreateCategory,
        layout: "/admin",
        hidden: true,
        roles: ["ROLE_SUPERADMIN"]
      },
      {
        path: "/category",
        name: "Category",
        miniName: "C",
        component: Category,
        layout: "/admin",
        roles: ["ROLE_SUPERADMIN"]
      },
      {
        path: "/type/edit/:id",
        name: "Edit Type",
        miniName: "ET",
        component: EditType,
        layout: "/admin",
        hidden: true,
        roles: ["ROLE_SUPERADMIN"]
      },
      {
        path: "/type/create",
        name: "Create Type",
        miniName: "CT",
        component: CreateType,
        layout: "/admin",
        hidden: true,
        roles: ["ROLE_SUPERADMIN"]
      },
      {
        path: "/type",
        name: "Terminal Type",
        miniName: "t",
        component: Type,
        layout: "/admin",
        roles: ["ROLE_SUPERADMIN"]
      },
      {
        path: "/edc-bsi/edit/:id",
        name: "Edit EDC BSI",
        miniName: "EEB",
        component: EditEdcBsi,
        layout: "/admin",
        hidden: true,
        roles: ["ROLE_SUPERADMIN"]
      },
      {
        path: "/edc-bsi",
        name: "EDC BSI",
        miniName: "EB",
        component: EdcBsi,
        layout: "/admin",
        roles: ["ROLE_SUPERADMIN"]
      },
    ],
  },
  {
    collapse: true,
    name: "Riwayat",
    icon: "fas fa-history",
    state: "historyCollapse",
    roles: ["ROLE_SUPERADMIN"],
    views: [
      {
        path: "/transaksi-lainnya",
        name: "Lainnya",
        miniName: "L",
        component: Lainnya,
        layout: "/admin",
        roles: ["ROLE_SUPERADMIN", "ROLE_OPERATOR_PROJECT", "ROLE_SUPERVISOR_PROJECT", "ROLE_ADMIN_PROJECT", "ROLE_OPERATOR_GROUP", "ROLE_SUPERVISOR_GROUP", "ROLE_ADMIN_GROUP", "ROLE_OPERATOR_MERCHANT", "ROLE_SUPERVISOR_MERCHANT", "ROLE_ADMIN_MERCHANT"]
      },
    ],
  },
];

export default routes;
