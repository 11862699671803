/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Row,
  Container,
  CardHeader, Form, Col, FormGroup, Label, Input, Button, Collapse
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

export default function DaftarTransaksiQr() {
  const token = localStorage.token;
  const projectId = localStorage.getItem("projectId");
  const merchantPk = localStorage.getItem("merchantPk");
  const role = localStorage.getItem("authority");
  const merchantId = localStorage.getItem("merchantPk");
  const [allTranscations, setAllTransactions] = useState([]);
  const [allTranscationsByProject, setAllTransactionsByProject] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [merchants, setMerchants] = useState([]);
  const [merchant, setMerchant] = useState("");
  const [listStatus, setListStatus] = useState([]);
  const [status, setStatus] = useState("");
  const [rrn, setRnn] = useState("");
  const [amount, setAmount] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [rowIndex, setRowIndex] = useState(0);
  const allowedAdmin = ["ROLE_SUPERADMIN"];
  const allowedProject = ["ROLE_OPERATOR_PROJECT", "ROLE_SUPERVISOR_PROJECT", "ROLE_ADMIN_PROJECT"];
  const allowedGroupMerchant = ["ROLE_OPERATOR_GROUP", "ROLE_SUPERVISOR_GROUP", "ROLE_ADMIN_GROUP"];
  const allowedMerchant = ["ROLE_OPERATOR_MERCHANT", "ROLE_SUPERVISOR_MERCHANT", "ROLE_ADMIN_MERCHANT"];

  const [page, setPage] = useState(1);
  const [perPage, setPerpage] = useState(10);
  const [totalItem, setTotalItem] = useState(0);
  const [currentSort, setCurrentSort] = useState("");

  let paginationOption = {
    page: page,
    alwaysShowAllBtns: true,
    override: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPage: perPage,
    totalSize: totalItem,
    onPageChange: (page) => {
      updateDataTable(page, perPage, currentSort, merchant, amount, rrn, transactionId, status, startTime, endTime, startDate, endDate);
    },
    sizePerPageRenderer: () => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                updateDataTable(page, e.target.value, currentSort, merchant, amount, rrn, transactionId, status, startTime, endTime, startDate, endDate)
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  }

  const updateDataTable = (page, perPage, sort, merchant, amount, rrn, transactionId, status, startTime, endTime, startDate, endDate) => {
    getAllTransactionsQr(page, perPage, sort, parseInt(merchant), amount, rrn, parseInt(transactionId), parseInt(status), startTime, endTime, startDate, endDate);
    getAllTransactionsQrByProject(page, perPage, sort, parseInt(merchant), amount, rrn, parseInt(transactionId), parseInt(status), startTime, endTime, startDate, endDate)
    setPage(page);
    setPerpage(perPage);
    setRowIndex((page - 1) * perPage);
    setCurrentSort(sort);
    setMerchant(parseInt(merchant));
    setAmount(amount);
    setRnn(rrn);
    setTransactionId(transactionId);
    setStatus(status);
    setStartTime(startTime);
    setEndTime(endTime);
    setStartDate(startDate);
    setEndDate(endDate);
  }

  const formatRupiah = (money) => {
    return new Intl.NumberFormat('id-ID',
      { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
    ).format(money);
  }

  useEffect(() => {
    getAllTransactionsQr(page, perPage, currentSort, "", "", "", "", "", "", "", "", "");
    getAllTransactionsQrByProject(page, perPage, "", "", "", "", "", "", "", "", "");
    getMerchants();
    getStatus();
  }, []);

  const getAllTransactionsQr = (page, perPage, currentSort, merchant_pk = 0, amount = null, retRefNo = null, qrTransactionId = 0, qrStatus = 0, operationalHourStart = null, operationalHourEnd = null, startDate = null, endDate = null) => {
    let filter = { page: page, per_page: perPage }
    if (merchant_pk !== 0) {
      filter = Object.assign(filter, { merchant_pk: merchant_pk })
    }
    if (operationalHourStart !== null) {
      filter = Object.assign(filter, { operationalHourStart: operationalHourStart })
    }
    if (amount !== null) {
      filter = Object.assign(filter, { amount: amount })
    }
    if (startDate !== null) {
      filter = Object.assign(filter, { startDate: startDate })
    }
    if (operationalHourEnd !== null) {
      filter = Object.assign(filter, { operationalHourEnd: operationalHourEnd })
    }
    if (qrStatus !== 0) {
      filter = Object.assign(filter, { qrStatus: qrStatus })
    }
    if (endDate !== null) {
      filter = Object.assign(filter, { endDate: endDate })
    }
    if (retRefNo !== null) {
      filter = Object.assign(filter, { retRefNo: retRefNo })
    }
    if (qrTransactionId !== 0) {
      filter = Object.assign(filter, { qrTransactionId: qrTransactionId })
    }
    const data = filter;
    fetch(`${process.env.REACT_APP_API_BASE_URL}/qrtransactions`, {
      method: 'POST', // or 'PUT'
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        setAllTransactions(data.response_data);
        setPage(data.current_page + 1);
        setPerpage(data.per_page);
        setTotalItem(data.total_item);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const getAllTransactionsQrByProject = (page, perPage, currentSort, merchant_pk = 0, amount = null, retRefNo = null, qrTransactionId = 0, qrStatus = 0, operationalHourStart = null, operationalHourEnd = null, startDate = null, endDate = null) => {
    if (allowedProject.includes(localStorage.getItem("authority"))) {
      let filter = { page: page, per_page: perPage, role: role, projectId: parseInt(merchantId), merchantPk: parseInt(merchantPk) }
      if (merchant_pk !== 0) {
        filter = Object.assign(filter, { merchant_pk: merchant_pk })
      }
      if (operationalHourStart !== null) {
        filter = Object.assign(filter, { operationalHourStart: operationalHourStart })
      }
      if (amount !== null) {
        filter = Object.assign(filter, { amount: amount })
      }
      if (startDate !== null) {
        filter = Object.assign(filter, { startDate: startDate })
      }
      if (operationalHourEnd !== null) {
        filter = Object.assign(filter, { operationalHourEnd: operationalHourEnd })
      }
      if (qrStatus !== 0) {
        filter = Object.assign(filter, { qrStatus: qrStatus })
      }
      if (endDate !== null) {
        filter = Object.assign(filter, { endDate: endDate })
      }
      if (retRefNo !== null) {
        filter = Object.assign(filter, { retRefNo: retRefNo })
      }
      if (qrTransactionId !== 0) {
        filter = Object.assign(filter, { qrTransactionId: qrTransactionId })
      }
      const data = filter;
      fetch(`${process.env.REACT_APP_API_BASE_URL}/qrtransactions`, {
        method: 'POST', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(response => response.json())
        .then(data => {
          setAllTransactionsByProject(data.response_data);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
    if (allowedGroupMerchant.includes(localStorage.getItem("authority"))) {
      let filter = { page: page, per_page: perPage, role: role, projectId: parseInt(projectId), groupId: parseInt(merchantId), merchantPk: parseInt(merchantId) }
      if (merchant_pk !== 0) {
        filter = Object.assign(filter, { merchant_pk: merchant_pk })
      }
      if (operationalHourStart !== null) {
        filter = Object.assign(filter, { operationalHourStart: operationalHourStart })
      }
      if (amount !== null) {
        filter = Object.assign(filter, { amount: amount })
      }
      if (startDate !== null) {
        filter = Object.assign(filter, { startDate: startDate })
      }
      if (operationalHourEnd !== null) {
        filter = Object.assign(filter, { operationalHourEnd: operationalHourEnd })
      }
      if (qrStatus !== 0) {
        filter = Object.assign(filter, { qrStatus: qrStatus })
      }
      if (endDate !== null) {
        filter = Object.assign(filter, { endDate: endDate })
      }
      if (retRefNo !== null) {
        filter = Object.assign(filter, { retRefNo: retRefNo })
      }
      if (qrTransactionId !== 0) {
        filter = Object.assign(filter, { qrTransactionId: qrTransactionId })
      }
      const data = filter;
      fetch(`${process.env.REACT_APP_API_BASE_URL}/qrtransactions`, {
        method: 'POST', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(response => response.json())
        .then(data => {
          setAllTransactionsByProject(data.response_data);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
    if (allowedMerchant.includes(localStorage.getItem("authority"))) {
      let filter = { page: page, per_page: perPage, role: role, merchantPk: parseInt(merchantId) }
      if (merchant_pk !== 0) {
        filter = Object.assign(filter, { merchant_pk: merchant_pk })
      }
      if (operationalHourStart !== null) {
        filter = Object.assign(filter, { operationalHourStart: operationalHourStart })
      }
      if (amount !== null) {
        filter = Object.assign(filter, { amount: amount })
      }
      if (startDate !== null) {
        filter = Object.assign(filter, { startDate: startDate })
      }
      if (operationalHourEnd !== null) {
        filter = Object.assign(filter, { operationalHourEnd: operationalHourEnd })
      }
      if (qrStatus !== 0) {
        filter = Object.assign(filter, { qrStatus: qrStatus })
      }
      if (endDate !== null) {
        filter = Object.assign(filter, { endDate: endDate })
      }
      if (retRefNo !== null) {
        filter = Object.assign(filter, { retRefNo: retRefNo })
      }
      if (qrTransactionId !== 0) {
        filter = Object.assign(filter, { qrTransactionId: qrTransactionId })
      }
      const data = filter;
      fetch(`${process.env.REACT_APP_API_BASE_URL}/qrtransactions`, {
        method: 'POST', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(response => response.json())
        .then(data => {
          setAllTransactionsByProject(data.response_data);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  }

  const getMerchants = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/qrtransactions/name`, {
      method: 'GET', // or 'PUT'
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(),
    })
      .then(response => response.json())
      .then(data => {
        setMerchants(data.response_data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const getStatus = () => {
    const data = { type: "qrtransaction" }
    fetch(`${process.env.REACT_APP_API_BASE_URL}/status`, {
      method: 'POST', // or 'PUT'
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        setListStatus(data.response_data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const reset = () => {
    setMerchant("");
    setStartTime("");
    setAmount("");
    setStartDate("");
    setEndTime("");
    setEndDate("");
    setStatus("");
    setRnn("");
    setTransactionId("");
    getAllTransactionsQr();
    getAllTransactionsQrByProject();
  }

  const handleTableChange = (type, { sortField, sortOrder }) => {
    if (type === "sort") {
      let sort = `${sortField} ${sortOrder}`
      updateDataTable(page, perPage, sort, merchant, amount, rrn, transactionId, status, startTime, endTime, startDate, endDate)
    }
  }

  return (
    <>
      <SimpleHeader name="Daftar Transaksi QR" parentName="Admin" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3>List Daftar Transaksi QR</h3>
              </CardHeader>
              <CardBody>
                <h3 onClick={toggle} >Filter &nbsp;
                  {
                    isOpen === true ? <i className="fa fa-angle-down" aria-hidden="true"></i> : <i className="fa fa-angle-right" aria-hidden="true"></i>
                  }
                </h3>
                <Collapse isOpen={isOpen}>
                  {
                    allowedMerchant.includes(localStorage.getItem("authority"))
                      ?
                      <Form>
                        <Row md="12">
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect3">Start Date</Label>
                              <Input
                                id="example-date-input"
                                type="date"
                                value={startDate}
                                onChange={(e) => {
                                  updateDataTable(page, perPage, currentSort, merchant, amount, rrn, transactionId, status, startTime, endTime, e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect3">Start Time</Label>
                              <Input
                                id="example-time-input"
                                type="time"
                                value={startTime}
                                onChange={(e) => {
                                  updateDataTable(page, perPage, currentSort, merchant, amount, rrn, transactionId, status, e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect3">Amount</Label>
                              <Input
                                type="text"
                                placeholder="Masukan Amount"
                                value={amount}
                                onChange={e => updateDataTable(page, perPage, currentSort, merchant, e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row md="12">
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect3">End Date</Label>
                              <Input
                                id="example-date-input"
                                type="date"
                                value={endDate}
                                onChange={(e) => {
                                  updateDataTable(page, perPage, currentSort, merchant, amount, rrn, transactionId, status, startTime, endTime, startDate, e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect3">End Time</Label>
                              <Input
                                id="example-time-input"
                                type="time"
                                value={endTime}
                                onChange={(e) => {
                                  updateDataTable(page, perPage, currentSort, merchant, amount, rrn, transactionId, status, startTime, e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect3">Status</Label>
                              <Input
                                id="exampleFormControlSelect3"
                                name="status"
                                type="select"
                                value={status}
                                onChange={(e) => {
                                  updateDataTable(page, perPage, currentSort, merchant, amount, rrn, transactionId, e.target.value);
                                }}
                              >
                                <option value="0">Pilih Status</option>
                                {
                                  listStatus.map((status, key) => {
                                    return <option key={key} value={status.value}>{status.description}</option>
                                  })
                                }
                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row md="12">
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect3">Transaction Id</Label>
                              <Input
                                type="text"
                                placeholder="Masukan Transaction Id"
                                value={transactionId}
                                onChange={(e) => {
                                  updateDataTable(page, perPage, currentSort, merchant, amount, rrn, e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect3">RRN</Label>
                              <Input
                                type="text"
                                placeholder="Masukan RRN"
                                value={rrn}
                                onChange={(e) => {
                                  updateDataTable(page, perPage, currentSort, merchant, amount, e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Button type="button" color="secondary" onClick={reset}>Reset</Button>
                          </Col>
                        </Row>
                      </Form>
                      :
                      <Form>
                        <Row md="12">
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect3">Merchant Name</Label>
                              <Input
                                id="exampleFormControlSelect3"
                                name="merchant_name"
                                type="select"
                                value={merchant}
                                onChange={e => updateDataTable(page, perPage, currentSort, e.target.value)}
                              >
                                <option value="0">Pilih Merchant Name</option>
                                {
                                  merchants.map((merchant, key) => {
                                    return <option key={key} value={merchant.merchantPk}>{merchant.merchantName}</option>
                                  })
                                }
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect3">Start Time</Label>
                              <Input
                                id="example-time-input"
                                type="time"
                                value={startTime}
                                onChange={(e) => {
                                  updateDataTable(page, perPage, currentSort, merchant, amount, rrn, transactionId, status, e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect3">Amount</Label>
                              <Input
                                type="text"
                                placeholder="Masukan Amount"
                                value={amount}
                                onChange={(e) => {
                                  updateDataTable(page, perPage, currentSort, merchant, e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row md="12">
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect3">Start Date</Label>
                              <Input
                                id="example-date-input"
                                type="date"
                                value={startDate}
                                onChange={(e) => {
                                  updateDataTable(page, perPage, currentSort, merchant, amount, rrn, transactionId, status, startTime, endTime, e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect3">End Time</Label>
                              <Input
                                id="example-time-input"
                                type="time"
                                value={endTime}
                                onChange={(e) => {
                                  updateDataTable(page, perPage, currentSort, merchant, amount, rrn, transactionId, status, startTime, e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect3">Status</Label>
                              <Input
                                id="exampleFormControlSelect3"
                                name="status"
                                type="select"
                                value={status}
                                onChange={(e) => {
                                  updateDataTable(page, perPage, currentSort, merchant, amount, rrn, transactionId, e.target.value);
                                }}
                              >
                                <option value="0">Pilih Status</option>
                                {
                                  listStatus.map((status, key) => {
                                    return <option key={key} value={status.value}>{status.description}</option>
                                  })
                                }
                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row md="12">
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect3">End Date</Label>
                              <Input
                                id="example-date-input"
                                type="date"
                                value={endDate}
                                onChange={(e) => {
                                  updateDataTable(page, perPage, currentSort, merchant, amount, rrn, transactionId, status, startTime, endTime, startDate, e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect3">RRN</Label>
                              <Input
                                type="text"
                                placeholder="Masukan RRN"
                                value={rrn}
                                onChange={(e) => {
                                  updateDataTable(page, perPage, currentSort, merchant, amount, e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect3">Transaction Id</Label>
                              <Input
                                type="text"
                                placeholder="Masukan Transaction Id"
                                value={transactionId}
                                onChange={(e) => {
                                  updateDataTable(page, perPage, currentSort, merchant, amount, rrn, e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Button type="button" color="secondary" onClick={reset}>Reset</Button>
                          </Col>
                        </Row>
                      </Form>
                  }
                </Collapse>
                {
                  allowedAdmin.includes(localStorage.getItem("authority"))
                    ?
                    <ToolkitProvider
                      rowNumber={rowIndex}
                      data={allTranscations}
                      keyField="qrTransactionId"
                      columns={[
                        {
                          dataField: "no",
                          text: "#",
                          sort: true,
                          page: 1,
                          formatter: (cell, row, index) => {
                            let currentRow = ++index;
                            return currentRow + rowIndex;
                          }
                        },
                        {
                          dataField: "merchantName",
                          text: "Merchant Name",
                          sort: true,
                        },
                        {
                          dataField: "transtypeName",
                          text: "Transtype",
                          sort: true,
                        },
                        {
                          dataField: "amount",
                          text: "Amount",
                          sort: true,
                          formatter: (cell, row, index) => {
                            return formatRupiah(row.amount)
                          }
                        },
                        {
                          dataField: "earnedFee",
                          text: "Earned Fee",
                          sort: true,
                        },
                        {
                          dataField: "invNo",
                          text: "Inv No",
                          sort: true,
                        },
                        {
                          dataField: "qrStatus",
                          text: "Status",
                          sort: true,
                          style: (cell, row) => {
                            if (row.qrStatus === 1) {
                              return ({ color: "#0000FF" })
                            }
                            if (row.qrStatus === 2) {
                              return ({ color: "#006400" })
                            }
                            if (row.qrStatus === 3 || row.qrStatus === 4) {
                              return ({ color: "#DC143C" })
                            }
                          },
                          formatter: (cell, row, index) => {
                            return row.qrStatus === 1 ? "Processing" : row.qrStatus === 2 ? "Successful" : row.qrStatus === 3 ? "Expired" : row.qrStatus === 4 ? "TimeOut" : row.qrStatus === 5 ? "Refunded" : "Procces Refund"
                          }
                        },
                        {
                          dataField: "date",
                          text: "Date",
                          sort: true,
                        },
                        {
                          dataField: "retRefNo",
                          text: "Reff Code",
                          sort: true,
                        },
                      ]}
                    >
                      {(props) => (
                        <div className="py-4 table-responsive">
                          <BootstrapTable
                            remote
                            {...props.baseProps}
                            bootstrap4={true}
                            bordered={false}
                            pagination={paginationFactory({ ...paginationOption })}
                            onTableChange={handleTableChange}
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                    :
                    <ToolkitProvider
                      rowNumber={rowIndex}
                      data={allTranscationsByProject}
                      keyField="qrTransactionId"
                      columns={[
                        {
                          dataField: "no",
                          text: "#",
                          sort: true,
                          page: 1,
                          formatter: (cell, row, index) => {
                            let currentRow = ++index;
                            return currentRow + rowIndex;
                          }
                        },
                        {
                          dataField: "merchantName",
                          text: "Merchant Name",
                          sort: true,
                        },
                        {
                          dataField: "transtypeName",
                          text: "Transtype",
                          sort: true,
                        },
                        {
                          dataField: "amount",
                          text: "Amount",
                          sort: true,
                          formatter: (cell, row, index) => {
                            return formatRupiah(row.amount)
                          }
                        },
                        {
                          dataField: "invNo",
                          text: "Inv No",
                          sort: true,
                        },
                        {
                          dataField: "qrStatus",
                          text: "Status",
                          sort: true,
                          style: (cell, row) => {
                            if (row.qrStatus === 1) {
                              return ({ color: "#0000FF" })
                            }
                            if (row.qrStatus === 2) {
                              return ({ color: "#006400" })
                            }
                            if (row.qrStatus === 3 || row.qrStatus === 4) {
                              return ({ color: "#DC143C" })
                            }
                          },
                          formatter: (cell, row, index) => {
                            return row.qrStatus === 1 ? "Processing" : row.qrStatus === 2 ? "Successful" : row.qrStatus === 3 ? "Expired" : row.qrStatus === 4 ? "TimeOut" : row.qrStatus === 5 ? "Refunded" : "Procces Refund"
                          }
                        },
                        {
                          dataField: "date",
                          text: "Date",
                          sort: true,
                        },
                        {
                          dataField: "retRefNo",
                          text: "Reff Code",
                          sort: true,
                        },
                      ]}
                    >
                      {(props) => (
                        <div className="py-4 table-responsive">
                          <BootstrapTable
                            remote
                            {...props.baseProps}
                            bootstrap4={true}
                            bordered={false}
                            pagination={paginationFactory({ ...paginationOption })}
                            onTableChange={handleTableChange}
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                }
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
