/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Label,
  FormGroup,
  Row,
  Input,
  Container,
  CardHeader,
  CardFooter,
  Button,
  Form, Col, FormFeedback
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import axios from 'axios';
import SimpleHeader from "components/Headers/SimpleHeader.js";

export default function EditUser(props) {
  const token = localStorage.token;
  const [name, setName] = useState("");
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPasswowrd] = useState("");
  const [address, setAddress] = useState("");
  const [merchant, setMerchant] = useState("");
  const [gender, setGender] = useState(1);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [listMerchant, setListMerchant] = useState([]);
  let history = useHistory();
  const allowedRolesMerchant = ["ROLE_OPERATOR_MERCHANT", "ROLE_SUPERVISOR_MERCHANT", "ROLE_ADMIN_MERCHANT"];
  const [isLoading, setLoading] = useState(false);

  const [isSelectUnitKerja, setSelecUnitKerja] = useState(2);
  const [unitKerja, setUnitKerja] = useState("");
  const [listUnitKerja, setListUnitKerja] = useState([]);

  const userRole = localStorage.getItem("authority");
  const [roleId, setRoleId] = useState("");
  const [emailError, setEmailError] = useState(null);

  const validateForm = () => {
    let error = false;
    if (email === "") {
      setEmailError("invalid");
      error = true;
    }
  };

  useEffect(() => {
    getUserById();
  }, []);

  const getUserById = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/user/${props.match.params.id}`, {
      method: 'GET', // or 'PUT'
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(),
    })
      .then(response => response.json())
      .then(data => {
        const responseData = data.response_data;
        getMerchant(responseData.merchant_pk)
        setName(responseData.name)
        setUserName(responseData.user_name)
        setEmail(responseData.email)
        setPasswowrd(responseData.password)
        setMerchant(responseData.merchant_pk)
        setAddress(responseData.address)
        setGender(responseData.gender)
        setPhoneNumber(responseData.phone_number)
        getUniKerja(responseData.projectId, responseData.workUnitPk);
        setSelecUnitKerja(responseData.isWorkUnit);
        data.response_data.roles.map((roleId) => {
          return setRoleId(roleId.role_id)
        })
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const getMerchant = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/user/list`, {
      method: 'GET', // or 'PUT'
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(),
    })
      .then(response => response.json())
      .then(data => {
        setListMerchant(data.response_data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const getUniKerja = async (merchantPk, workUnitPk) => {
    const body = {
      merchantPk: parseInt(merchantPk)
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/workunit/list`, {
        method: 'POST', // or 'PUT'
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
      const data = await response.json();
      setListUnitKerja(data.response_data);
      setUnitKerja(workUnitPk);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  function UpdateUser() {
    setLoading(true);
    let body = {
      name: name,
      username: userName,
      email: email,
      password: password,
      address: address,
      merchant_pk: parseInt(merchant),
      user_status: 1,
      phone_number: phoneNumber,
      gender: gender,
      isWorkUnit: parseInt(isSelectUnitKerja),
      workUnitPk: parseInt(unitKerja)
    }
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/user/update/${props.match.params.id}`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then(function (response) {
        history.push("/admin/user");
      })
      .then(json => {
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      UpdateUser();
    }
  }
  return (
    <>
      <SimpleHeader name="Edit User" parentName="Admin" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardBody>
                <Form onSubmit={handleSubmit} >
                  <CardHeader>
                    <h3>Edit User</h3>
                  </CardHeader>
                  <CardBody>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="serial_number"
                      >
                        Nama
                      </Label>
                      <Input
                        type="text"
                        name="name"
                        placeholder="Masukan Name"
                        value={name}
                        onChange={e => setName(e.target.value)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        User Name
                      </Label>
                      <Input
                        disabled
                        type="text"
                        name="user_name"
                        placeholder="Masukan User Name"
                        value={userName}
                        onChange={e => setUserName(e.target.value)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        Email
                      </Label>
                      <Input
                        type="email"
                        name="email"
                        placeholder="Masukan Email"
                        value={email}
                        invalid={emailError === "invalid"}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          if (e.target.value !== "") {
                            setEmailError("");
                          }
                        }}
                      />
                      <FormFeedback>Email tidak boleh kosong</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        Address
                      </Label>
                      <Input
                        type="text"
                        name="address"
                        placeholder="Masukan Address"
                        value={address}
                        onChange={e => setAddress(e.target.value)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlSelect3"
                      >
                        Jenis Kelamin
                                    </Label>
                      <Row>
                        <Col>
                          <div style={{ display: "flex" }}>
                            <div className="custom-control custom-radio mb-3">
                              <Input
                                className="custom-control-input"
                                defaultChecked
                                id="customRadio5"
                                name="custom-radio-2"
                                type="radio"
                                value={1}
                                checked={gender === 1}
                                onClick={() => setGender(1)}
                              />
                              <Label
                                className="custom-control-label"
                                htmlFor="customRadio5"
                              >
                                Pria
                                </Label>
                            </div>
                            <div className="custom-control custom-radio mb-3" style={{ marginLeft: "20px" }}>
                              <Input
                                className="custom-control-input"
                                id="customRadio6"
                                name="custom-radio-2"
                                type="radio"
                                value={2}
                                checked={gender === 2}
                                onClick={() => setGender(2)}
                              />
                              <Label
                                className="custom-control-label"
                                htmlFor="customRadio6"
                              >
                                Wanita
                                </Label>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        Nomor Telp/ Handphone
                      </Label>
                      <Input
                        type="number"
                        name="No Telphone"
                        placeholder="Masukan Nomor Telp"
                        value={phoneNumber}
                        onChange={e => setPhoneNumber(e.target.value)}
                      />
                    </FormGroup>
                    {
                      allowedRolesMerchant.includes(localStorage.getItem("authority"))
                        ?
                        <div></div>
                        :
                        <FormGroup>
                          <Label
                            className="form-control-label"
                            htmlFor="exampleFormControlInput1"
                          >
                            Nama Merchant
                          </Label>
                          <Input
                            name="merchant_pk"
                            type="select"

                            value={merchant}
                            disabled
                            onChange={e =>
                              setMerchant(e.target.value)
                            }
                          >
                            <option value={0}>Pilih Merchant</option>
                            {
                              listMerchant.map((merchant, key) => {
                                return <option key={key} value={merchant.merchantPk}>{merchant.merchantName}</option>
                              })
                            }
                          </Input>
                        </FormGroup>
                    }
                    {
                      roleId > 1 && roleId < 5
                        ?
                        <FormGroup>
                          <Label
                            className="form-control-label"
                            htmlFor="exampleFormControlInput1"
                          >
                            Unit Kerja
                          </Label>
                          <Input
                            name="unit Kerja"
                            type="select"
                            value={unitKerja}
                            disabled
                            onChange={e =>
                              setUnitKerja(e.target.value)
                            }
                          >
                            <option value={0}>Pilih Unit Kerja</option>
                            {
                              listUnitKerja.map((workUnit, key) => {
                                return <option key={key} value={workUnit.workUnitPk}>{workUnit.workUnitName}</option>
                              })
                            }
                          </Input>
                        </FormGroup>
                        :
                        <div></div>
                    }
                  </CardBody>
                  <CardFooter>
                    {!isLoading && (<Button color="primary" type="submit">
                      Ubah
                    </Button>)}
                    {isLoading && (<Button color="primary" disabled>
                      <i className="fas fa-spinner fa-spin"></i>{""}
                      Loading...
                    </Button>)}
                    <Link class="btn btn-info" to="/admin/user">
                      Kembali
                    </Link>
                  </CardFooter>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
